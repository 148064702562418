/*
 * Logan Baer
 * 2021-10-20
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import {
  exportOutcomeResults,
} from '../../actions';

const ExportOutcomes = (props) => {
  const [courseId, setCourseId] = React.useState('');

  return (
    <div>
      <h3>Export Outcomes</h3>
      <p>Download speeds may be slow, please wait until your file has been successfully downloaded.</p>
      <TextField
        label= 'Canvas Course Id'
        value={courseId}
        onChange={(e) => setCourseId(e.target.value)}
        margin='dense'
      />
      <div>
        <Button
          disabled={!courseId}
          variant='contained'
          color='secondary'
          onClick={() => {
            props.exportOutcomeResults(courseId);
            setCourseId('');
          }}
        >
          Export Outcomes
        </Button>
      </div>
    </div>
  );
};

ExportOutcomes.propTypes = {
  exportOutcomeResults: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    exportOutcomeResults,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportOutcomes);
