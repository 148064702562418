/*
 * Ally Zernick
 * 2024-07-30
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */

import Globals from "../../Globals";

const styles = {
  termContainer: {
    display: 'flex',
    gap: '10px',
  },
  terms: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '10px',
    padding: '10px',
  },
  quarterWidth: {
    width: '25%',
  },
  fullWidth: {
    width: '100%',
  },
  lineSeparator: {
    margin: '25px 0',
  },
  flexBox: {
    display: 'flex',
  },
  error: {
    color: Globals.colors.error,
  },
  success: {
    color: 'green',
  },
  editButton: {
    marginLeft: 'auto',
    marginRight: 0,
  },
};

export default styles;
