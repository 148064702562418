/*
 * Nick Lewyn
 * 2021-02-05
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';
const styles = {
  errorText: {
    color: Globals.colors.error,
  },
};

export default styles;
