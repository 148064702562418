/*
 * Ryan O'Dowd
 * 2019-03-28
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
  },
  textField: {
    marginRight: 8,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    width: '100%',
  },
  tableToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: {
    marginBottom: 16,
  },
  leftItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errors: {
    color: Globals.colors.error,
  },
};

export default styles;
