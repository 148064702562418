/*
 * Ryan O'Dowd
 * 2019-01-22
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';
// @TODO: clean this up

const DIALOG_CONTENT_WIDTH = 600;

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
    color: Globals.colors.light,
  },
  selectedStudentRow: {
    backgroundColor: Globals.colors.lightGray,
  },
  detailsRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 12,
    paddingBottom: 16,
    borderBottomColor: `${Globals.colors.accent}22`,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  studentRow: {
    cursor: 'pointer',
  },
  instructorSelectField: {
    width: 120,
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionOptions: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 10,
  },
  dialogContent: {
    width: DIALOG_CONTENT_WIDTH,
  },
  expand: {
    flexGrow: 1,
  },
  tableToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabledStudent: {
    backgroundColor: '#aaa',
  },
  innerDiaglogContent: {
    width: DIALOG_CONTENT_WIDTH - 50,
  },
  formFieldWrapper: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  formFieldLabel: {
    color: Globals.colors.accent,
    marginTop: 0,
    marginLeft: 0,
    marginBottom: 4,
    marginRight: 0,
    fontSize: '0.95rem',
    fontWeight: '200',
  },
  formFieldContent: {
    margin: 0,
    fontSize: '1.1rem',
    display: 'flex',
  },
  previousRecordsIconWrapper: {
    padding: 0,
    marginLeft: 8,
  },
  highlightedText: {
    color: Globals.colors.light,
    backgroundColor: Globals.colors.accent,
  },
  textField: {
    marginRight: 8,
    // @TODO: change placeholder text color
  },
  tooltipTrigger: {
    textDecorationLine: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationSkipInk: 'none',
  },
  stateSelectField: {
    width: 80,
    marginRight: 8,
    marginTop: 21, // @TODO: magic so the bottom lines up with neighboring inputs
  },
  previousRecords: {
    backgroundColor: `${Globals.colors.primary}22`,
  },
  expansionPanelSummaryInner: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  ngOverlay: {
    opacity: 0.5,

    /* @TODO: for some reason, this throws a duplicate key error on the span that has a span with key = c.id that uses this style; seems like a bug in radium, but it's easy to just comment this out for now instead
    ':hover': {
      opacity: 1,
    },
    */
  },
  statusWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 4,
    height: '100%',
    color: Globals.colors.lightGray,
  },
  coursesWrapper: {
  },
  courseHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  courseName: {
    color: Globals.colors.accent,
  },
  courseInstructor: {
    color: Globals.colors.primary,
    fontSize: 12,
  },
  panelDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  dataWrapper: {
  },
  dataItemWrapper: {
    display: 'flex',
    marginBottom: 32,
  },
  dataItemInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  dataItemLabel: {
    color: Globals.colors.accent,
    fontSize: '0.8rem',
  },
  dataItemValue: {
    fontSize: '0.8rem',
  },
  addCourseWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
    marginBottom: 16,
  },
  addCourseSelectField: {
    width: '65%', // @TODO: hardcoded...
  },
  datePicker: {
    display: 'none',
  },
  withdrawGradeInputField: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    marginRight: 8,
  },
  alertContainer: {
    display: 'flex',
  },
  loadingWrapper: {
    display: 'flex',
  },
  settingsRow: {
    display: 'flex',
  },
  muiFormControlLabelCustom: {
    marginRight: 8,
  },
  duplicateEmailDetails: {
    display: 'flex',
  },
  duplicateEmailDialogSearchButton: {
    width: 80,
    height: 35,
    marginTop: 20,
    marginLeft: 20,
  },
  duplicateTextSearch: {
    width: 300,
  },
};

export default styles;
