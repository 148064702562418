/*
 * Ryan O'Dowd
 * 2021-03-24
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */

const styles = {
  container: {
    flex: 1,
  },
  graphContainer: {
    width: '100%',
    height: 450,
    display: 'flex',
    flexDirection: 'column',
  },
  totals: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 32,
  },
};

export default styles;
