/*
 * Ryan O'Dowd
 * 2019-01-08
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Grid,
} from '@material-ui/core';
import HomeCard from './HomeCard';
import PropTypes from 'prop-types';
import React from 'react';
import {
  connect,
} from 'react-redux';
import homeOptions from './homeOptions';
import styles from './styles';
import {
  withRouter,
} from 'react-router-dom';

const Home = (props) => {
  const optionsBySection = {};
  homeOptions(props.admin.perms).filter((homeOption) => !homeOption.hideOnHomeScreen).forEach((homeOption) => {
    if (!optionsBySection[homeOption.section]) {
      optionsBySection[homeOption.section] = [];
    }
    optionsBySection[homeOption.section].push(homeOption);
  });

  return (
    <div style={styles.container}>
      {Object.entries(optionsBySection).map(([section, options]) => {
        return (
          <React.Fragment key={section}>
            <h2 style={styles.sectionHeader}>{section.replace('_', ' ')}</h2>
            <Grid
              container={true}
              spacing={2}
            >
              {options.map((homeOption) => {
                return (
                  <Grid key={homeOption.name} item xs={12} sm={6} md={4} lg={3}>
                    <HomeCard {...homeOption} />
                  </Grid>
                );
              })}
            </Grid>
          </React.Fragment>
        );
      })}
    </div>
  );
};

Home.propTypes = {
  admin: PropTypes.shape({perms: PropTypes.object.isRequired}).isRequired,
};

const mapStateToProps = (state) => {
  return {
    admin: state.admin,
  };
};

export default withRouter(connect(mapStateToProps)(Home));
