/*
 * Ryan O'Dowd
 * 2019-04-10
 * © Copyright 2020 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  container: {
    width: '100%',
  },
  gradeDoesntCountCell: {
    opacity: 0.4,
  },
  totalRow: {
    borderTop: `2px solid ${Globals.colors.dark}`,
  },
  totalRowCell: {
    fontWeight: '800',
  },
};

export default styles;
