/**
 * Ryan O'Dowd
 * 2018-12-17
 * © Copyright 2018 NursingABC, Inc. All Rights Reserved.
 */
import {
  RSAA,
  getJSON,
} from 'redux-api-middleware';
import {
  Auth,
} from 'aws-amplify';
import Globals from './Globals';
import {
  v4 as uuidv4,
} from 'uuid';

let au = 'http://localhost:5003';
let wu = 'http://localhost:5000';
if (window.location.hostname === 'dev.admin.nursingabc.com') {
  au = 'https://dev.api.admin.nursingabc.com';
  wu = 'https://dev.api.nursingabc.com';
} else if (window.location.hostname === 'beta.admin.nursingabc.com') {
  au = 'https://beta.api.admin.nursingabc.com';
  wu = 'https://api.nursingabc.com';
} else if (window.location.hostname === 'admin.nursingabc.com') {
  au = 'https://api.admin.nursingabc.com';
  wu = 'https://api.nursingabc.com';
}

export const API_URL = au;
export const WEBSITE_API_URL = wu;

// action types
export const ADD_REGISTRATION_DATA = 'ADD_REGISTRATION_DATA';
export const SET_CURRENT_ADMIN = 'SET_CURRENT_ADMIN';
export const MASQUERADE_ADMIN = 'MASQUERADE_ADMIN';
export const SET_INSTRUCTOR_ASSIGNMENT_RULES = 'SET_INSTRUCTOR_ASSIGNMENT_RULES';
export const SET_ASSIGNABLE_COURSE_INSTRUCTORS = 'SET_ASSIGNABLE_COURSE_INSTRUCTORS';
export const UPDATE_INSTRUCTOR_ASSIGNMENT_RULES = 'UPDATE_INSTRUCTOR_ASSIGNMENT_RULES';
export const ADD_STUDENTS = 'ADD_STUDENTS';
export const CAMPUSES_WITH_PROGRESS_REPORT_STUDENTS = 'CAMPUSES_WITH_PROGRESS_REPORT_STUDENTS';
export const ADD_UNASSIGNED_STUDENT_IDS = 'ADD_UNASSIGNED_STUDENT_IDS';
export const SET_REFUNDS = 'SET_REFUNDS';
export const SET_REGISTRATIONS = 'SET_REGISTRATIONS';
export const SET_REGISTRATION_COUNTS = 'SET_REGISTRATION_COUNTS';
export const SET_PREVIOUS_RECORDS_FOR_STUDENT = 'SET_PREVIOUS_RECORDS_FOR_STUDENT';
export const REMOVE_FETCH = 'REMOVE_FETCH';
export const UPDATE_FETCH = 'UPDATE_FETCH';
export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_ERROR = 'FETCH_ERROR';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const REMOVE_STUDENT = 'REMOVE_STUDENT';
export const SET_ADMINS = 'SET_ADMINS';
export const SET_ADMIN_PERMS = 'SET_ADMIN_PERMS';
export const LOGOUT = 'LOGOUT';
export const SET_AUTHORIZATION_CODES = 'SET_AUTHORIZATION_CODES';
export const SET_AUTHORIZATION_CODE = 'SET_AUTHORIZATION_CODE';
export const SET_COURSES = 'SET_COURSES';
export const SET_GRADES_FOR_STUDENT = 'SET_GRADES_FOR_STUDENT';
export const SET_KNOWN_SCHOOLS = 'SET_KNOWN_SCHOOLS';
export const SET_UNKNOWN_SCHOOLS = 'SET_UNKNOWN_SCHOOLS';
export const REMOVE_UNKNOWN_SCHOOL = 'REMOVE_UNKNOWN_SCHOOL';
export const SET_API_DOCS = 'SET_API_DOCS';
export const SET_COURSES_FOR_STUDENT = 'SET_COURSES_FOR_STUDENT';
export const EXTEND_STUDENT_QUIZ_TIME = 'EXTEND_STUDENT_QUIZ_TIME';
export const MERGE_STUDENT = 'MERGE_STUDENT';
export const CLEAR_MERGE_STUDENT_FLAG = 'CLEAR_MERGE_STUDENT_FLAG';
export const CLEAR_CANVAS_STUDENT_ENROLLMENT_UPDATE_FLAG = 'CLEAR_CANVAS_STUDENT_ENROLLMENT_UPDATE_FLAG';
export const FETCH_TRANSCRIPT_REQUESTS = 'FETCH_TRANSCRIPT_REQUESTS';
export const CLEAR_TRANSCRIPT_REQUESTS = 'CLEAR_TRANSCRIPT_REQUESTS';
export const UPDATE_STUDENT_TRANSCRIPT_REQUEST = 'UPDATE_STUDENT_TRANSCRIPT_REQUEST';
export const SET_SYLLABI_LINKS = 'SET_SYLLABI_LINKS';
export const LOADING = 'LOADING';
export const NOT_LOADING = 'NOT_LOADING';
export const SET_TERMS = 'SET_TERMS';
export const ELECTRONIC_STUDENT_TRANSCRIPTS = 'ELECTRONIC_STUDENT_TRANSCRIPTS';
export const SET_MULTIMEDIA_FILES = 'SET_MULTIMEDIA_FILES';
export const SET_VIDEO_CAPTIONS = 'SET_VIDEO_CAPTIONS';
export const SET_VIDEO_CAPTION_LINKS = 'SET_VIDEO_CAPTION_LINKS';
export const FINALIZE_VIDEO_CAPTIONS = 'FINALIZE_VIDEO_CAPTIONS';
export const SET_ELECTRONIC_TRANSCRIPTS = 'SET_ELECTRONIC_TRANSCRIPTS';
export const SET_ELECTRONIC_TRANSCRIPT_SCHOOLS = 'SET_ELECTRONIC_TRANSCRIPT_SCHOOLS';
export const SET_SECURE_FILES = 'SET_SECURE_FILES';
export const S3_PRE_AUTH_DOWNLOAD = 'S3_PRE_AUTH_DOWNLOAD';
export const S3_FILE_DOWNLOAD = 'S3_FILE_DOWNLOAD';
export const FETCH_STUDENT_TRANSCRIPT = 'FETCH_STUDENT_TRANSCRIPT';
export const SET_COGNITO_STUDENT_INFO = 'SET_COGNITO_STUDENT_INFO';
export const SET_RESET_PASSWORD = 'SET_RESET_PASSWORD';
export const FETCH_NABC_CANVAS_COURSE_POINTS = 'FETCH_NABC_CANVAS_COURSE_POINTS';
export const CLEAR_FORCE_CONCLUDE_STUDENT_COURSE_STATE = 'CLEAR_FORCE_CONCLUDE_STUDENT_COURSE_STATE';
export const FORCE_CONCLUDE_STUDENT_COURSE = 'FORCE_CONCLUDE_STUDENT_COURSE';
export const SET_FORCE_CONCLUDE_STUDENT_COURSE_COMPLETED_FALSE = 'SET_FORCE_CONCLUDE_STUDENT_COURSE_COMPLETED_FALSE';
export const SET_PUT_S3_PRE_AUTH_LINK = 'SET_PUT_S3_PRE_AUTH_LINK';
export const SET_GET_S3_PRE_AUTH_LINK = 'SET_GET_S3_PRE_AUTH_LINK';
export const CLEAR_S3_SHARE_LINK = 'CLEAR_S3_SHARE_LINK';
export const SET_UPLOADED_S3_SECURE_FILE = 'SET_UPLOADED_S3_SECURE_FILE';
export const SET_UPLOADED_S3_ELECTRONIC_TRANSCRIPT = 'SET_UPLOADED_S3_ELECTRONIC_TRANSCRIPT';
export const FETCH_TRANSCRIPT_REQUEST_HISTORY = 'FETCH_TRANSCRIPT_REQUEST_HISTORY';
export const SET_ENROLLMENT_REFUND_ELIGIBILITY = 'SET_ENROLLMENT_REFUND_ELIGIBILITY';
export const UPDATE_REFUNDS = 'UPDATE_REFUNDS';
export const ADD_KNOWN_SCHOOL = 'ADD_KNOWN_SCHOOL';
export const REMOVE_KNOWN_SCHOOL = 'REMOVE_KNOWN_SCHOOL';
export const REMOVE_ETTS_TRANSCRIPT = 'REMOVE_ETTS_TRANSCRIPT';
export const UPDATE_MULTIMEDIA_CONTRACT_EDITOR_DIRECTORIES = 'UPDATE_MULTIMEDIA_CONTRACT_EDITOR_DIRECTORIES';
export const CLEAR_TRANSCRIPT_HISTORY = 'CLEAR_TRANSCRIPT_HISTORY';
export const CREATE_ELECTRONIC_TRANSCRIPT_TRANSFER_S3_COGNITO_ACCOUNT = 'CREATE_ELECTRONIC_TRANSCRIPT_TRANSFER_S3_COGNITO_ACCOUNT';
export const SEND_ELECTRONIC_TRANSCRIPT_TRANSFER_FILE_NOTIFICATION = 'SEND_ELECTRONIC_TRANSCRIPT_TRANSFER_FILE_NOTIFICATION';
export const FETCH_SCHOOL_RELATIONS_TRANSCRIPT_REQUESTS = 'FETCH_SCHOOL_RELATIONS_TRANSCRIPT_REQUESTS';
export const S3_FILE_DOWNLOAD_FAILED = 'S3_FILE_DOWNLOAD_FAILED';
export const SET_ACTIVE_CAMPUSES_ROSTER = 'SET_ACTIVE_CAMPUSES_ROSTER';
export const UPDATE_STUDENT_PROGRESS_DATA = 'UPDATE_STUDENT_PROGRESS_DATA';
export const GET_PROGRESS_REPORT_STUDENTS_PER_CAMPUS = 'GET_PROGRESS_REPORT_STUDENTS_PER_CAMPUS';
export const REMOVE_STUDENT_PROGRESS_DATA = 'REMOVE_STUDENT_PROGRESS_DATA';
export const SET_LOW_GRADE_WARNINGS = 'SET_LOW_GRADE_WARNINGS';
export const SET_STUDENT_PROGRESS_DATA = 'SET_STUDENT_PROGRESS_DATA';
export const FETCH_PARTNER_SCHOOLS = 'FETCH_PARTNER_SCHOOLS';
export const CREATE_PARTNER_SCHOOL = 'CREATE_PARTNER_SCHOOL';
export const PATCH_PARTNER_SCHOOL = 'PATCH_PARTNER_SCHOOL';
export const SET_COURSE_ENROLLMENT_REPORT = 'SET_COURSE_ENROLLMENT_REPORT';
export const SET_COLUMN_TABLE_NAMES = 'SET_COLUMN_TABLE_NAMES';
export const SET_VIOLATIONS = 'SET_VIOLATIONS';
export const SET_VIOLATION_TYPES = 'SET_VIOLATION_TYPES';
export const SET_INSTRUCTORS = 'SET_INSTRUCTORS';
export const GET_PART_TIME_INSTRUCTOR_PAYROLL = 'GET_PART_TIME_INSTRUCTOR_PAYROLL';
export const GET_PART_TIME_INSTRUCTOR_PAYROLL_EXPORT = 'GET_PART_TIME_INSTRUCTOR_PAYROLL_EXPORT';
export const SET_PARTNER_STUDENT_IMPORT = 'SET_PARTNER_STUDENT_IMPORT';
export const SET_OBSERVERS = 'SET_OBSERVERS';
export const SET_INSTITUTION_PARTNER_AGREEMENTS = 'SET_INSTITUTION_PARTNER_AGREEMENTS';
export const SET_OBSERVER_INSTITUTION_PARTNER_AGREEMENTS = 'SET_OBSERVER_INSTITUTION_PARTNER_AGREEMENTS';
export const CREATE_INSTITUTION_AGREEMENT_OBSERVER = 'CREATE_INSTITUTION_AGREEMENT_OBSERVER';
export const FETCH_WITHDRAW_REASONS = 'FETCH_WITHDRAW_REASONS';
export const SET_PARTNER_AGREEMENT_TYPES = 'SET_PARTNER_AGREEMENT_TYPES';
export const SET_INSTITUTIONS = 'SET_INSTITUTIONS';
export const FETCH_BLUEPRINT_COURSES = 'FETCH_BLUEPRINT_COURSES';
export const FETCH_BLUEPRINT_COURSES_BY_COURSE_CODE = 'FETCH_BLUEPRINT_COURSES_BY_COURSE_CODE';
export const FETCH_ACTIVE_ENROLLMENTS_FOR_INSTRUCTOR = 'FETCH_ACTIVE_ENROLLMENTS_FOR_INSTRUCTOR';
export const FETCH_VACATION_COVERAGE = 'FETCH_VACATION_COVERAGE';
export const FETCH_ACADEMIC_SCHOLARSHIP_REPORT = 'FETCH_ACADEMIC_SCHOLARSHIP_REPORT';
export const UPDATE_ACADEMIC_SCHOLARSHIP_ENTRY = 'UPDATE_ACADEMIC_SCHOLARSHIP_ENTRY';
export const FETCH_COURSES_FOR_DEPT_CHAIR = 'FETCH_COURSES_FOR_DEPT_CHAIR';
export const FETCH_TRANSCRIPT_REQUEST_TYPES = 'FETCH_TRANSCRIPT_REQUEST_TYPES';

// fetch status
export const FETCH_REQUEST_OBJECT = {
  status: 'IN_FLIGHT',
};
export const FETCH_SUCCESS_OBJECT = {
  status: 'SUCCESS',
};
// @TODO: handle fetch errors...if 401, log admin out automatically
export const FETCH_ERROR_OBJECT = {
  status: 'ERROR',
  message: '<no errror provided...check reducer for FETCH_ERROR>',
};

// methods
const GET = 'GET';
const POST = 'POST';
const PATCH = 'PATCH';
const PUT = 'PUT';
const DELETE = 'DELETE';

// types
export function removeFetch(requestId) {
  return {
    type: REMOVE_FETCH,
    requestId,
  };
}

export function updateFetch(fetchRequest) {
  return {
    type: UPDATE_FETCH,
    fetchRequest,
  };
}

export function masqueradeAdmin(admin) {
  return {
    type: MASQUERADE_ADMIN,
    admin,
  };
}

export function logout() {
  Auth.signOut();
  localStorage.clear();

  return {
    type: LOGOUT,
  };
}

function loadingState(resourceLoading) {
  return {
    type: LOADING,
    resourceLoading,
  };
}

function notLoadingState(resourceNotLoading) {
  return {
    type: NOT_LOADING,
    resourceNotLoading,
  };
}

export function removeStudent(studentId) {
  return {
    type: REMOVE_STUDENT,
    studentId,
  };
}

export function removeKnownSchool(schoolId) {
  return {
    type: REMOVE_KNOWN_SCHOOL,
    schoolId,
  };
}

// @MARK: api calls
const _getAuthHeader = () => {
  return {Authorization: localStorage.getItem('jwt')};
};

const _getFetchTypes = (endpoint, successType, skipMessage) => {
  const requestId = uuidv4();
  return [
    {
      type: FETCH_REQUEST,
      payload: {endpoint},
      meta: {requestId, endpoint},
    },
    {
      type: successType,
      payload: (action, state, res) => {
        const allowedContentTypesForFileDownload = [
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/octet-stream',
          'application/pdf',
          'text/csv; charset=utf-8',
        ];
        const contentType = res.headers.get('Content-Type');
        if (contentType === 'application/json') {
          return res.json().then((data) => data);
        } else if (allowedContentTypesForFileDownload.includes(contentType)) {
          const contentDisposition = res.headers.get('Content-Disposition');
          res.blob().then((blob) => {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.setAttribute('download', contentDisposition.split('; ')[1].replace('filename=', '').replaceAll('"', ''));
            a.click();
          });
        }
      },
      meta: (action, state, res) => {
        delete action['@@redux-api-middleware/RSAA'];
        if (res) {
          return {
            responseStatus: res.status,
            responseStatusText: res.statusText,
            requestId,
            endpoint,
            ...action,
          };
        } else {
          return {
            status: 'Network request failed',
          };
        }
      },
    },
    {
      type: FETCH_ERROR,
      payload: (action, state, res) => {
        const defaultMessage = `${res.status} - ${res.statusText}`;
        if (res) {
          return getJSON(res).then((json) => {
            if (json.message) {
              return json;
            }
            return {
              message: defaultMessage,
              ...json,
            };
          }).catch(() => {
            return {
              message: defaultMessage,
            };
          });
        } else {
          return {
            message: '',
          };
        }
      },
      meta: {
        requestId,
        endpoint,
        skipMessage,
      },
    },
  ];
};

export function requestRefund(enrollmentId, refundAmount, reason, notes, callback) {
  const ACTION_CREATOR_NAME = 'requestRefund';
  const endpoint = `${API_URL}/refunds`;
  const formData = new FormData();
  formData.append('enrollment_id', enrollmentId);
  formData.append('reason', reason);
  formData.append('refund_amount', refundAmount);
  if (notes) {
    formData.append('notes', notes);
  }
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_REFUNDS),
      },
    });
    callback && callback();
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function apiRequestLogError(component, errorMessage) {
  const formData = new FormData();
  formData.append('message', errorMessage);
  formData.append('source', 'APCE');
  formData.append('component', component);
  const endpoint = `${WEBSITE_API_URL}/critical_error`;
  return {
    [RSAA]: {
      endpoint,
      method: POST,
      body: formData,
      headers: _getAuthHeader(),
      types: _getFetchTypes(endpoint, '', ''),
    },
  };
}

export function fetchMasterStudentSheet(registrationStartDate, registrationEndDate) {
  const ACTION_CREATOR_NAME = 'fetchMasterStudentSheet';
  const endpoint = `${API_URL}/reports/student_master_list?enrollment_start_date=${registrationStartDate}&enrollment_end_date=${registrationEndDate}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchRegistrationData() {
  const ACTION_CREATOR_NAME = 'fetchRegistrationData';
  const endpoint = `${API_URL}/reports/registration_report`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ADD_REGISTRATION_DATA),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchMasterInstructorSheet(enrollmentDateStarted, enrollmentDateEnded, enrollmentCompletionStartDate, enrollmentCompletionEndDate, students) {
  const ACTION_CREATOR_NAME = 'fetchMasterInstructorSheet';
  const endpoint = `${API_URL}/reports/master_instructor_sheet?${enrollmentDateStarted ? `enrollment_date_started=${enrollmentDateStarted}` : ''}${enrollmentDateEnded ? `&enrollment_date_ended=${enrollmentDateEnded}` : ''}${enrollmentCompletionStartDate ? `&enrollment_completion_start_date=${enrollmentCompletionStartDate}` : ''}${enrollmentCompletionEndDate ? `&enrollment_completion_end_date=${enrollmentCompletionEndDate}` : ''}&students=${students}`.replace('?&', '?');
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function updateDatabaseRecord(tableName, columnName, columnValue, recordId, isNull = false) {
  const ACTION_CREATOR_NAME = 'updateDatabaseRecord';
  const endpoint = `${API_URL}/crud/update`;
  const formData = new FormData();
  formData.append('table_name', tableName);
  formData.append('column_name', columnName);
  formData.append('column_value', columnValue);
  formData.append('record_id', recordId);
  if (isNull) {
    formData.append('null_value', isNull);
  }
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function insertDatabaseRecord(tableName, insertValues) {
  const ACTION_CREATOR_NAME = 'insertDatabaseRecord';
  const endpoint = `${API_URL}/crud/insert`;
  const formData = new FormData();
  formData.append('table_name', tableName);
  formData.append('list_of_values', insertValues);
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchPurgeFdlq(queue) {
  const ACTION_CREATOR_NAME = 'fetchPurgeFdlq';
  const endpoint = `${API_URL}/aws/sqs/purge`;
  const formdata = new FormData();
  formdata.append('queue_to_purge_dlq', queue);
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formdata,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchGradeSyncing(queue) {
  const ACTION_CREATOR_NAME = 'fetchGradeSyncing';
  const endpoint = `${API_URL}/grade_syncing${queue ? `?queue_name=${queue}` : ''}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchExamLocking(queue) {
  const ACTION_CREATOR_NAME = 'fetchExamLocking';
  const endpoint = `${API_URL}/quiz_submitted_test${queue ? `?queue_name=${queue}` : ''}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchInstructorAssignmentRules(startMonth, startYear) {
  const ACTION_CREATOR_NAME = 'fetchInstructorAssignmentRules';
  const endpoint = `${API_URL}/instructor_assignment_rules?start_month=${startMonth}&start_year=${startYear}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_INSTRUCTOR_ASSIGNMENT_RULES),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchRefunds() {
  const ACTION_CREATOR_NAME = 'fetchRefunds';
  const endpoint = `${API_URL}/refunds`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_REFUNDS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function updateRefund(refundId, refundAmount, rejectDialog, rejectNotes) {
  const ACTION_CREATOR_NAME = 'updateRefund';
  const endpoint = `${API_URL}/refund/${refundId}`;

  const formData = new FormData();
  if (refundAmount) {
    formData.append('refund_amount', refundAmount);
  }
  if (rejectDialog) {
    formData.append('status', 'rejected');
    formData.append('notes', rejectNotes);
  }

  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, UPDATE_REFUNDS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function approveRefund(refundId) {
  const ACTION_CREATOR_NAME = 'approveRefund';
  const formData = new FormData();
  formData.append('refund_id', refundId);
  const endpoint = `${API_URL}/refunds/approve`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_REFUNDS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchAssignableCourseInstructors(courseId) {
  const ACTION_CREATOR_NAME = 'fetchAssignableCourseInstructors';
  const endpoint = `${API_URL}/courses/${courseId}/instructors`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_ASSIGNABLE_COURSE_INSTRUCTORS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function simulateRegistrations(departmentId, totalRegistrationCount, startMonth, startYear) {
  const ACTION_CREATOR_NAME = 'simulateRegistrations';
  const formData = new FormData();
  formData.append('department_id', departmentId);
  formData.append('total_registration_count', totalRegistrationCount);
  const endpoint = `${API_URL}/registration_simulation?start_month=${startMonth}&start_year=${startYear}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_INSTRUCTOR_ASSIGNMENT_RULES),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function setTemporaryOverrideForInstuctor(departmentId, instructorId, tempMax) {
  const ACTION_CREATOR_NAME = 'setTemporaryOverrideForInstructor';
  const formData = new FormData();
  formData.append('department_id', departmentId);
  formData.append('instructor_id', instructorId);
  formData.append('tmp_max', tempMax);
  const endpoint = `${API_URL}/instructor_assignment_rules/tmp_overrides`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, UPDATE_INSTRUCTOR_ASSIGNMENT_RULES),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchWithdrawReasons() {
  const ACTION_CREATOR_NAME = 'fetchWithdrawReasons';
  const endpoint = `${API_URL}/withdraw_reasons`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, FETCH_WITHDRAW_REASONS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function sendElectronicTranscriptTransferFileNotification(email) {
  const ACTION_CREATOR_NAME = 'sendElectronicTranscriptTransferFileNotification';
  const formData = new FormData();
  formData.append('email', email);
  const endpoint = `${API_URL}/electronic_transcripts/send_file_notification`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SEND_ELECTRONIC_TRANSCRIPT_TRANSFER_FILE_NOTIFICATION),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchCourses() {
  const ACTION_CREATOR_NAME = 'fetchCourses';
  const endpoint = `${API_URL}/courses?courses_disabled=true`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_COURSES),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchAuthorizationCodes() {
  const ACTION_CREATOR_NAME = 'fetchAuthorizationCodes';
  const endpoint = `${API_URL}/discount_codes`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_AUTHORIZATION_CODES),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchAdmins() {
  const ACTION_CREATOR_NAME = 'fetchAdmins';
  const endpoint = `${API_URL}/admins`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_ADMINS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function setPerms(adminId, perms) {
  const ACTION_CREATOR_NAME = 'setPerms';
  const formData = new FormData();
  formData.append('perms', JSON.stringify(perms));
  const endpoint = `${API_URL}/admins/${adminId}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_ADMIN_PERMS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchApiDocs() {
  const ACTION_CREATOR_NAME = 'fetchApiDocs';
  const endpoint = `${API_URL}/api_docs`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_API_DOCS),
      },

    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

// @TODO: rename all api calls with apiRequest prefix like in websites?
export function fetchCurrentAdmin() {
  const ACTION_CREATOR_NAME = 'fetchCurrentAdmin';
  const endpoint = `${API_URL}/admin`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_CURRENT_ADMIN),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchStudent(studentIdOrEmail) {
  const ACTION_CREATOR_NAME = 'fetchStudent';
  const endpoint = `${API_URL}/students/${studentIdOrEmail}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, UPDATE_STUDENT),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchGrades(studentId) {
  const ACTION_CREATOR_NAME = 'fetchGrades';
  const endpoint = `${API_URL}/students/${studentId}/grades`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_GRADES_FOR_STUDENT),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchStudentCourses(studentId, courseIds = null) {
  const ACTION_CREATOR_NAME = 'fetchStudentCourses';
  let endpoint = `${API_URL}/students/${studentId}/courses`;
  if (courseIds?.length) {
    let courseIdParams = `?course_id=${courseIds[0]}`;
    for (let i = 1; i < courseIds.length; i++) {
      courseIdParams = `${courseIdParams}&course_id=${courseIds[i]}`;
    }
    endpoint = `${endpoint}${courseIdParams}`;
  }
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_COURSES_FOR_STUDENT),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchCoursesForDepartmentChair(deptChairUserName) {
  const ACTION_CREATOR_NAME = 'fetchCoursesForDepartmentChair';
  const endpoint = `${API_URL}/department_chair/${deptChairUserName}/courses`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, FETCH_COURSES_FOR_DEPT_CHAIR),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchStudents(activeOnly, endpoint, callback, courseIds = null) {
  endpoint = endpoint || `${API_URL}/students?active_only=${activeOnly ? 1 : 0}`;
  if (courseIds?.length) {
    let courseIdParams = '';
    courseIds.forEach((courseId) => {
      courseIdParams = `${courseIdParams}&course_id=${courseId}`;
    });
    endpoint = `${endpoint}${courseIdParams}`;
  }
  return async (dispatch, getState) => {
    dispatch(loadingState('fetchStudents'));
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ADD_STUDENTS),
      },
    });

    if (actionResponse.payload.next_page) {
      dispatch(fetchStudents(activeOnly, actionResponse.payload.next_page, callback));
    } else {
      callback && callback();
      dispatch(notLoadingState('fetchStudents'));
    }
  };
}

export function fetchUnassignedStudentIds(endpoint) {
  endpoint = endpoint || `${API_URL}/unassigned_student_ids`;
  return async (dispatch, getState) => {
    dispatch(loadingState('fetchUnassignedStudentIds'));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ADD_UNASSIGNED_STUDENT_IDS),
      },
    });
    dispatch(notLoadingState('fetchUnassignedStudentIds'));
  };
}

export function fetchRegistrationCounts(endpoint) {
  const ACTION_CREATOR_NAME = 'fetchRegistrationCounts';
  endpoint = endpoint || `${API_URL}/registration_counts`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_REGISTRATION_COUNTS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchRegistrations(endpoint) {
  const ACTION_CREATOR_NAME = 'fetchRegistrations';
  endpoint = endpoint || `${API_URL}/registrations`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_REGISTRATIONS),
      },
    });

    if (actionResponse.payload.next_page) {
      dispatch(fetchRegistrations(actionResponse.payload.next_page));
    } else {
      dispatch(notLoadingState(ACTION_CREATOR_NAME));
    }
  };
}

export function fetchPreviousInfoForStudent(studentId) {
  const ACTION_CREATOR_NAME = 'fetchPreviousInfoForStudent';
  const endpoint = `${API_URL}/students/${studentId}/previous_records`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_PREVIOUS_RECORDS_FOR_STUDENT),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchKnownSchools() {
  const ACTION_CREATOR_NAME = 'fetchKnownSchools';
  const endpoint = `${API_URL}/schools/known`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_KNOWN_SCHOOLS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchUnknownSchools(maxStudentId) {
  const ACTION_CREATOR_NAME = 'fetchUnknownSchools';
  const endpoint = `${API_URL}/schools/unknown?max_student_id=${maxStudentId}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_UNKNOWN_SCHOOLS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function updateEnrollments(enrollmentId, enrollmentFields) {
  const ACTION_CREATOR_NAME = 'updateEnrollments';
  const formData = new FormData();
  for (const attr in enrollmentFields) {
    if (Array.isArray(enrollmentFields[attr])) {
      formData.append(attr, JSON.stringify(enrollmentFields[attr]));
    } else {
      formData.append(attr, enrollmentFields[attr]);
    }
  }
  const endpoint = `${API_URL}/enrollments/${enrollmentId}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_COURSES_FOR_STUDENT),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function updateTransactionEnrollment(enrollmentId, transactionEnrollmentFields) {
  const ACTION_CREATOR_NAME = 'updateEnrollments';
  const formData = new FormData();
  for (const attr in transactionEnrollmentFields) {
    formData.append(attr, transactionEnrollmentFields[attr]);
  }
  const endpoint = `${API_URL}/enrollments/${enrollmentId}/transactions_enrollments`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_COURSES_FOR_STUDENT),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function applyDiscountCodeToEnrollment(enrollmentId, discountCode) {
  const ACTION_CREATOR_NAME = 'applyDiscountCodeToEnrollment';
  const formData = new FormData();
  formData.append('discount_code', discountCode);
  const endpoint = `${API_URL}/enrollments/${enrollmentId}/apply_discount_code`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_COURSES_FOR_STUDENT),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function updateStudent(student, callback) {
  const ACTION_CREATOR_NAME = 'updateStudent';
  const formData = new FormData();
  for (const attr in student) {
    formData.append(attr, student[attr]);
  }
  const endpoint = `${API_URL}/students/${student.id}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, UPDATE_STUDENT),
      },
    });
    callback && callback();
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function mergeStudents(sourceStudentId, destinationStudentId, sourceFieldsToKeep) {
  const ACTION_CREATOR_NAME = 'mergeStudents';
  const formData = new FormData();
  formData.append('source_student_id', sourceStudentId);
  formData.append('destination_student_id', destinationStudentId);
  sourceFieldsToKeep.forEach((field) => formData.append('source_fields_to_keep', field));
  const endpoint = `${API_URL}/students/${sourceStudentId}/merge_into/${destinationStudentId}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, MERGE_STUDENT),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
    // remove source student from redux store because they should no longer be visible in searches, etc.
    dispatch(removeStudent(sourceStudentId));
  };
}

// @MARK: authorization codes
export function updateCode(codeId, disabled) {
  const ACTION_CREATOR_NAME = 'updateCode';
  const formData = new FormData();
  formData.append('code_id', codeId);
  formData.append('disabled', disabled);
  const endpoint = `${API_URL}/discount_codes/${codeId}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_AUTHORIZATION_CODE),
      },
    });
    dispatch(fetchAuthorizationCodes());
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function createNewCode(amount, discountType, courseId, code, notes, category, callback) {
  const ACTION_CREATOR_NAME = 'createNewCode';
  const formData = new FormData();
  formData.append('amount', amount);
  formData.append('discount_type', discountType);
  if (courseId) {
    formData.append('course_id', courseId);
  }
  formData.append('code', code);
  formData.append('notes', notes);
  formData.append('category', category);
  const endpoint = `${API_URL}/discount_codes`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_AUTHORIZATION_CODE),
      },
    });
    callback && callback();
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function transcriptRequestExcel(type) {
  const ACTION_CREATOR_NAME = 'transcriptRequestExcel';
  const formData = new FormData();
  formData.append('type', type);
  const endpoint = `${API_URL}/transcript_request_excel`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function manuallyEnrollStudent(studentId, courseId, suppressWelcomeEmail, courseNotPaid, courseNotes, assignInstructorManually) {
  const ACTION_CREATOR_NAME = 'manuallyEnrollStudent';
  const formData = new FormData();
  formData.append('course_id', courseId);
  formData.append('suppress_welcome_email', +suppressWelcomeEmail);
  formData.append('course_paid_in_full', courseNotPaid ? 0 : 1);
  formData.append('manual_enrollment', 1);
  formData.append('course_notes', courseNotes);
  formData.append('manually_assign_instructor', +assignInstructorManually);
  const endpoint = `${API_URL}/students/${studentId}/courses`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_COURSES_FOR_STUDENT),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function extendStudentQuizTime(canvasId, extendQuizTime) {
  const ACTION_CREATOR_NAME = 'extendStudentQuizTime';
  const formData = new FormData();
  formData.append('extend_quiz_time', extendQuizTime);
  const endpoint = `${API_URL}/canvas/students/${canvasId}/courses/quizzes/moderate`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, EXTEND_STUDENT_QUIZ_TIME),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function clearApiMergeStudentFlag() {
  return {
    type: CLEAR_MERGE_STUDENT_FLAG,
  };
}

export function clearCanvasStudentEnrollmentUpdateFlag() {
  return {
    type: CLEAR_CANVAS_STUDENT_ENROLLMENT_UPDATE_FLAG,
  };
}

export function clearTranscriptRequests() {
  return {
    type: CLEAR_TRANSCRIPT_REQUESTS,
  };
}

export function manuallyAssignInstructor(enrollmentId, instructorId, callback) {
  const ACTION_CREATOR_NAME = 'manuallyAssignInstructor';
  const formData = new FormData();
  formData.append('instructor_id', instructorId);

  const endpoint = `${API_URL}/enrollments/${enrollmentId}/instructor`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_COURSES_FOR_STUDENT),
      },
    });
    callback();
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchTranscriptRequests(completedOnly, invalidOnly, endpoint, searchBy, searchByValue, transcriptType) {
  let url = `${API_URL}/transcripts/student_transcript_requests?completedOnly=${completedOnly ? 1 : 0}&invalidOnly=${invalidOnly ? 1 : 0}`;
  if (searchBy) {
    if (searchBy === 'dateCompletedRange') {
      searchBy = 'date_completed_range';
    }
    url = `${url}&${searchBy}=${searchByValue}`;
  }
  if (transcriptType) {
    url = `${url}&transcriptType=${transcriptType}`;
  }
  endpoint = endpoint || url;
  return async (dispatch, getState) => {
    dispatch(loadingState('fetchTranscriptRequests'));
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, FETCH_TRANSCRIPT_REQUESTS),
      },
    });

    if (actionResponse.payload.next_page) {
      dispatch(fetchTranscriptRequests(completedOnly, invalidOnly, actionResponse.payload.next_page));
    } else {
      dispatch(notLoadingState('fetchTranscriptRequests'));
    }
  };
}

export function updateStudentTranscriptRequest(transcriptId, transcript) {
  const endpoint = `${API_URL}/student_transcript_request/${transcriptId}`;
  const formData = new FormData();
  for (const attr in transcript) {
    formData.append(attr, transcript[attr]);
  }
  return {
    [RSAA]: {
      endpoint,
      method: PATCH,
      body: formData,
      headers: _getAuthHeader(),
      types: _getFetchTypes(endpoint, UPDATE_STUDENT_TRANSCRIPT_REQUEST),
    },
  };
}

export function fetchSyllabiLinks() {
  const ACTION_CREATOR_NAME = 'fetchSyllabiLinks';
  const endpoint = `${API_URL}/syllabi_links`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_SYLLABI_LINKS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function uploadSyllabus(courseCode, pdf, callback) {
  const ACTION_CREATOR_NAME = 'uploadSyllabus';
  const formData = new FormData();
  formData.append('course_code', courseCode);
  formData.append('pdf', pdf);
  const endpoint = `${API_URL}/syllabi`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, 'TODO'),
      },
    });
    callback && callback();
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function processElectronicStudentTranscripts(adminsEmail) {
  const ACTION_CREATOR_NAME = 'processElectronicStudentTranscripts';
  const formData = new FormData();
  formData.append('admin_email', adminsEmail);
  const endpoint = `${API_URL}/process_electronic_student_transcript`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ELECTRONIC_STUDENT_TRANSCRIPTS, true),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchMultimediaFiles() {
  const ACTION_CREATOR_NAME = 'fetchMultimediaFiles';
  const endpoint = `${API_URL}/multimedia_files`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_MULTIMEDIA_FILES),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchVideoCaptions() {
  const ACTION_CREATOR_NAME = 'fetchVideoCaptions';
  const endpoint = `${API_URL}/video_captions`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_VIDEO_CAPTIONS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function generateLinksWithTimes(courseCode) {
  const ACTION_CREATOR_NAME = 'generateLinksWithTimes';
  const endpoint = `${API_URL}/video_captions/${courseCode}/links`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_VIDEO_CAPTION_LINKS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function finalizeVideoCaptions(courseCode) {
  const ACTION_CREATOR_NAME = 'finalizeVideoCaptions';
  const endpoint = `${API_URL}/video_captions/${courseCode}/finalize`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, FINALIZE_VIDEO_CAPTIONS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchSecureFiles() {
  const ACTION_CREATOR_NAME = 'fetchSecureFiles';
  const endpoint = `${API_URL}/secure_files?bucket=${Globals.s3Buckets.privateAssets}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_SECURE_FILES),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchElectronicTranscriptSchools() {
  const ACTION_CREATOR_NAME = 'fetchElectronicTranscriptSchools';
  const endpoint = `${API_URL}/electronic_transcripts`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_ELECTRONIC_TRANSCRIPT_SCHOOLS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchElectronicTranscriptsBySchool(schoolEmail) {
  const ACTION_CREATOR_NAME = 'fetchElectronicTranscriptsBySchool';
  const endpoint = `${API_URL}/electronic_transcripts/${schoolEmail}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_ELECTRONIC_TRANSCRIPTS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchSecureFileS3PreAuthLink(bucket, key, contentType, uuid, bucketName, callback) {
  const ACTION_CREATOR_NAME = 'fetchSecureFileS3PreAuthLink';
  const endpoint = `${API_URL}/secure_files/${bucket}/${key}/pre_auth_link?content_type=${contentType}&bucket=${bucketName}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_PUT_S3_PRE_AUTH_LINK),
      },
      uuid,
    });
    callback();
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function setETTSSharedFiles(bucket, key, placesId) {
  const ACTION_CREATOR_NAME = 'setETTSSharedFiles';
  const endpoint = `${API_URL}/electronic_transcripts/${bucket}/shared_files`;
  const formData = new FormData();
  formData.append('s3_object_key', `${bucket}/${key}`);
  formData.append('places_id', placesId);
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function generateShareS3PreAuthLink(key, duration) {
  const ACTION_CREATOR_NAME = 'generateShareS3PreAuthLink';
  const endpoint = `${API_URL}/share_link/${key}?duration=${duration}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_GET_S3_PRE_AUTH_LINK),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function clearS3ShareLink() {
  return {
    type: CLEAR_S3_SHARE_LINK,
  };
}

export function setUploadedS3SecureFile(category, secureFileInfo) {
  return {
    type: SET_UPLOADED_S3_SECURE_FILE,
    category,
    secureFileInfo,
  };
}

export function setUploadedS3ElectronicTranscript(email, electronicTranscriptInfo) {
  return {
    type: SET_UPLOADED_S3_ELECTRONIC_TRANSCRIPT,
    email,
    electronicTranscriptInfo,
  };
}

export function saveFileFromDownloadedS3Bucket(file, filename, contentType) {
  return {
    type: S3_FILE_DOWNLOAD,
    file,
    filename,
    contentType,
  };
}

export function fileFailedDownloadFromS3Bucket() {
  return {
    type: S3_FILE_DOWNLOAD_FAILED,
  };
}

export function uploadSecureFile(fileObject, s3PreAuthURL, contentType, callback) {
  const endpoint = s3PreAuthURL;
  const ACTION_CREATOR_NAME = 'uploadSecureFile';
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PUT,
        body: fileObject,
        headers: {'Content-Type': contentType},
        types: _getFetchTypes(endpoint, '', true),
      },
    });
    callback();
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function uploadElectronicTranscript(fileObject, s3PreAuthURL, contentType, callback) {
  const endpoint = s3PreAuthURL;
  const ACTION_CREATOR_NAME = 'uploadElectronicTranscript';
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    const result = await dispatch({
      [RSAA]: {
        endpoint,
        method: PUT,
        body: fileObject,
        headers: {'Content-Type': contentType},
        types: _getFetchTypes(endpoint, ''),
      },
    });
    if (result.meta.responseStatus === 200) {
      callback();
    }
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function createElectronicTranscriptTransferS3CognitoAccount(email) {
  const ACTION_CREATOR_NAME = 'createElectronicTranscriptTransferS3CognitoAccount';
  const endpoint = `${API_URL}/electronic_transcripts/create_account`;
  const formData = new FormData();
  formData.append('email', email);
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, CREATE_ELECTRONIC_TRANSCRIPT_TRANSFER_S3_COGNITO_ACCOUNT),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function downloadSecureFile(bucket, bucketKey) {
  const endpoint = `${API_URL}/secure_files/${bucket}/${bucketKey}`;
  return {
    [RSAA]: {
      endpoint,
      method: GET,
      headers: _getAuthHeader(),
      types: _getFetchTypes(endpoint, S3_PRE_AUTH_DOWNLOAD),
    },
  };
}

export function fetchStudentTranscript(studentId, school, transcriptType) {
  const endpoint = `${API_URL}/transcripts/student_transcript_request/${studentId}/${school}/${transcriptType}`;
  return {
    [RSAA]: {
      endpoint,
      method: GET,
      headers: _getAuthHeader(),
      types: _getFetchTypes(endpoint, FETCH_STUDENT_TRANSCRIPT),
    },
  };
}

export function fetchResetUserPassword(userId, requestType = '') {
  const ACTION_CREATOR_NAME = 'resetUserPassword';
  const endpoint = `${API_URL}/user/${userId}/reset_password?request_type=${requestType}`;

  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchCognitoStudentData(studentId) {
  const ACTION_CREATOR_NAME = 'cognitoStudentData';
  const endpoint = `${API_URL}/student/${studentId}/cognito_info`;

  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_COGNITO_STUDENT_INFO),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchCognitoDuplicateStudent(email, callback) {
  const ACTION_CREATOR_NAME = 'fetchCognitoDuplicateStudent';
  const endpoint = `${API_URL}/students/${email}/cognito/duplicate_account`;

  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    const result = await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    if (result.meta.responseStatus === 200) {
      callback(result.payload);
    }
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function removeCognitoStudentData(studentEmail) {
  const ACTION_CREATOR_NAME = 'removeCognitoStudentData';
  const endpoint = `${API_URL}/students/${studentEmail}/cognito/duplicate_account`;

  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: DELETE,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchCompletedStudentElectronicTranscript(studentId, key) {
  const endpoint = `${API_URL}/transcripts/processed_student_electronic_transcript/${studentId}/portage/${key}`;
  return {
    [RSAA]: {
      endpoint,
      method: GET,
      headers: _getAuthHeader(),
      types: _getFetchTypes(endpoint, FETCH_STUDENT_TRANSCRIPT),
    },
  };
}

export function fetchNABCDivisionElectronicTranscript(transcriptRequestId) {
  const endpoint = `${API_URL}/transcripts/electronic_transcripts/official/nabc/${transcriptRequestId}`;
  return {
    [RSAA]: {
      endpoint,
      method: GET,
      headers: _getAuthHeader(),
      types: _getFetchTypes(endpoint, FETCH_STUDENT_TRANSCRIPT),
    },
  };
}

export function fetchNABCCanvasCoursePoints(studentId, courseId) {
  const endpoint = `${API_URL}/students/${studentId}/courses/${courseId}/final_points`;
  const ACTION_CREATOR_NAME = 'fetchNABCCanvasCoursePoints';
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, FETCH_NABC_CANVAS_COURSE_POINTS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function clearNABCCanvasCoursePoints() {
  return {
    type: CLEAR_FORCE_CONCLUDE_STUDENT_COURSE_STATE,
  };
}

export function forceConcludeStudentCourse(courseId, finalLetterGrade) {
  const endpoint = `${API_URL}/courses/${courseId}/force_conclude`;
  const formData = new FormData();
  formData.append('final_letter_grade', finalLetterGrade);
  return {
    [RSAA]: {
      endpoint,
      method: POST,
      body: formData,
      headers: _getAuthHeader(),
      types: _getFetchTypes(endpoint, FORCE_CONCLUDE_STUDENT_COURSE),
    },
  };
}

export function fetchCourseSyllabus(dateRegistered, courseCode) {
  const endpoint = `${WEBSITE_API_URL}/course_syllabi/${courseCode}/${dateRegistered}`;
  return {
    [RSAA]: {
      endpoint,
      method: GET,
      headers: _getAuthHeader(),
      types: _getFetchTypes(endpoint, ''),
    },
  };
}

export function closeForceConcludeDialog() {
  return {
    type: SET_FORCE_CONCLUDE_STUDENT_COURSE_COMPLETED_FALSE,
  };
}

export function fetchTranscriptRequestHistory(studentId) {
  const endpoint = `${API_URL}/transcripts/${studentId}/request_history`;
  return {
    [RSAA]: {
      endpoint,
      method: GET,
      headers: _getAuthHeader(),
      types: _getFetchTypes(endpoint, FETCH_TRANSCRIPT_REQUEST_HISTORY),
    },
  };
}

export function fetchMultimediaCloudDirectories(key) {
  const endpoint = `${API_URL}/multimedia/directories/${key}`;
  return {
    [RSAA]: {
      endpoint,
      method: GET,
      headers: _getAuthHeader(),
      types: _getFetchTypes(endpoint, UPDATE_MULTIMEDIA_CONTRACT_EDITOR_DIRECTORIES),
    },
  };
}

export function addPerm(permNameToCreate, callback) {
  const ACTION_CREATOR_NAME = 'addPerm';
  const endpoint = `${API_URL}/admins/add_perm`;
  const formData = new FormData();
  formData.append('new_perm_name', permNameToCreate);

  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_ADMINS),
      },
    });
    callback();
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function removePerm(permNameToDelete, callback) {
  const ACTION_CREATOR_NAME = 'removePerm';
  const endpoint = `${API_URL}/admins/remove_perm`;
  const formData = new FormData();
  formData.append('perm_name_to_delete', permNameToDelete);

  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_ADMINS),
      },
    });
    callback();
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function disableAdmin(admin) {
  const ACTION_CREATOR_NAME = 'disableAdmin';
  const endpoint = `${API_URL}/admins/${admin.id}/enable_disable`;

  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_ADMINS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function clearTranscriptHistory() {
  return {
    type: CLEAR_TRANSCRIPT_HISTORY,
  };
}

export function resendElectronicTranscriptTransferInvite(email) {
  const ACTION_CREATOR_NAME = 'resendElectronicTranscriptTransferInvite';
  const endpoint = `${API_URL}/electronic_transcripts/resend_invite`;

  const formData = new FormData();
  formData.append('email', email);
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, 'TODO', ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function addNewSection(canvasCourseId) {
  const ACTION_CREATOR_NAME = 'addNewSection';
  const formData = new FormData();
  formData.append('canvas_course_id', canvasCourseId);
  const endpoint = `${API_URL}/crud/sections`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchRefundEligibility(enrollmentId, queryParams) {
  const ACTION_CREATOR_NAME = 'fetchRefundEligibility';
  let endpoint = `${API_URL}/refunds/${enrollmentId}/eligibility`;

  if (Object.keys(queryParams).length > 0) {
    endpoint = `${endpoint}?`;
    for (const [key, value] of Object.entries(queryParams)) {
      if (!value) {
        continue;
      }
      if (endpoint.endsWith('?')) {
        endpoint = `${endpoint}${key}=${value}`;
        continue;
      }
      endpoint = `${endpoint}&${key}=${value}`;
    }
  }
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_ENROLLMENT_REFUND_ELIGIBILITY, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchSchoolRelationsTranscriptRequests() {
  const ACTION_CREATOR_NAME = 'fetchSchoolRelationsTranscriptRequests';
  const endpoint = `${API_URL}/reports/school_relations/transcript_requests`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, FETCH_SCHOOL_RELATIONS_TRANSCRIPT_REQUESTS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fixUnknownSchool(originalSchoolId, newSchoolId, alwaysFix = false) {
  const ACTION_CREATOR_NAME = 'fixUnknownSchool';
  const endpoint = `${API_URL}/schools/fix`;
  const formData = new FormData();
  formData.append('original_school_id', originalSchoolId);
  formData.append('new_school_id', newSchoolId);
  if (alwaysFix) {
    formData.append('always_fix', 1);
  }
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, REMOVE_UNKNOWN_SCHOOL),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function updateSchool(schoolId, school) {
  const ACTION_CREATOR_NAME = 'updateSchool';
  const endpoint = `${API_URL}/schools/${schoolId}`;
  const formData = new FormData();
  for (const attr in school) {
    formData.append(attr, school[attr]);
  }
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, REMOVE_KNOWN_SCHOOL, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function createNewConfirmedSchool(schoolName, schoolCity, schoolState, makeAllUnknownSchoolNewSchool, unknownSchoolId, ignoreStudentAudit, callback) {
  const ACTION_CREATOR_NAME = 'createNewConfirmedSchool';
  const endpoint = `${API_URL}/schools/new_school`;
  const formData = new FormData();
  formData.append('school_name', schoolName);
  formData.append('school_city', schoolCity);
  formData.append('school_state', schoolState);
  formData.append('unknown_school_id', unknownSchoolId);
  formData.append('make_all_unknown_schools_new_school', makeAllUnknownSchoolNewSchool ? 1 : 0);
  formData.append('ignore_student_audit', ignoreStudentAudit ? 1 : 0);

  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ADD_KNOWN_SCHOOL, ''),
      },
    });
    dispatch(removeUnknownSchool(unknownSchoolId));
    callback && callback();
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function removeUnknownSchool(schoolId) {
  return {
    type: REMOVE_UNKNOWN_SCHOOL,
    payload: {
      original_school_id: schoolId,
    },
  };
}

export function removeTranscriptFromBucket(key, schoolId) {
  const ACTION_CREATOR_NAME = 'removeTranscriptFromBucket';
  const endpoint = `${API_URL}/electronic_transcripts/${schoolId}/etts/${key}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: DELETE,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, REMOVE_ETTS_TRANSCRIPT, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function exportOutcomeResults(courseId) {
  const ACTION_CREATOR_NAME = 'exportOutcomesResults';
  const endpoint = `${API_URL}/export_outcomes/${courseId}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function exportStudentProgressReportPerCampus(schoolId) {
  const ACTION_CREATOR_NAME = 'exportStudentProgressReportPerCampus';
  const endpoint = `${API_URL}/export_student_progress_report_per_campus/${schoolId}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, '',),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function getTerms() {
  const ACTION_CREATOR_NAME = 'getTerms';
  const endpoint = `${API_URL}/terms`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_TERMS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function getAllCampuses() {
  const ACTION_CREATOR_NAME = 'getActiveCampuses';
  const endpoint = `${API_URL}/institution_campuses/get_all_campuses`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_ACTIVE_CAMPUSES_ROSTER,),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function getAllCampusesWithProgressReportStudents() {
  const endpoint = `${API_URL}/reports/student_progress_reports/campuses_with_progress_report_students`;
  return async (dispatch) => {
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, CAMPUSES_WITH_PROGRESS_REPORT_STUDENTS, ''),
      },
    });
  };
}

export function updateStudentProgressData(studentId, selectedCourses, campusId, selectedTerm, schoolDeadlineDate) {
  const ACTION_CREATOR_NAME = 'updateStudentProgressData';
  const endpoint = `${API_URL}/student_progress/update_student_progress_data`;
  const formData = new FormData();
  formData.append('student_id', studentId);
  formData.append('course_enrollment', selectedCourses);
  formData.append('school_id', campusId);
  formData.append('selected_term', selectedTerm);
  formData.append('school_deadline_date', schoolDeadlineDate);

  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function displayStudentProgressData(schoolId) {
  const ACTION_CREATOR_NAME = 'getProgressReportStudentsPerCampus';
  const endpoint = `${API_URL}/student_progress/${schoolId}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_STUDENT_PROGRESS_DATA,),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function removeStudentProgressData(progressReportid, school) {
  const ACTION_CREATOR_NAME = 'removeStudentProgressData';
  const endpoint = `${API_URL}/student_progress`;
  const formData = new FormData();
  formData.append('student_progress_id', progressReportid);
  formData.append('school', school);
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: DELETE,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_STUDENT_PROGRESS_DATA, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function patchStudentProgressData(id, editTerm, editDeadline, school) {
  const ACTION_CREATOR_NAME = 'patchStudentProgressData';
  const endpoint = `${API_URL}/student_progress/${id}`;
  const formData = new FormData();
  formData.append('edit_term', editTerm);
  formData.append('edit_deadline', editDeadline);
  formData.append('school', school);
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_STUDENT_PROGRESS_DATA, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function backfillGrades(enrollmentId, studentId) {
  const ACTION_CREATOR_NAME = 'backfillGrades';
  const endpoint = `${API_URL}/backfill/${enrollmentId}?conclude_course=1`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    const response = await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    if (response.meta.responseStatus === 200) {
      dispatch(fetchGrades(studentId));
      dispatch(fetchStudentCourses(studentId));
    }
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchLowGradeWarnings(startId = 0) {
  const ACTION_CREATOR_NAME = 'fetchLowGradeWarnings';
  const endpoint = `${API_URL}/low_grade_warnings?start_id=${startId}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_LOW_GRADE_WARNINGS),
      },
    });

    if (actionResponse.payload.next_page) {
      dispatch(fetchLowGradeWarnings(actionResponse.payload.next_page));
    } else {
      dispatch(notLoadingState(ACTION_CREATOR_NAME));
    }
  };
}

export function fetchPartnerSchools() {
  const ACTION_CREATOR_NAME = 'fetchPartnerSchools';
  const endpoint = `${API_URL}/schools/partners`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        body: null,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, FETCH_PARTNER_SCHOOLS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function createPartnerSchool(institutionId, schoolURL, schoolName, schoolNameLong, backgroundColor,
  extraInformation, imageURL, imageToUpload, program, preReq, callback) {
  const ACTION_CREATOR_NAME = 'createPartnerSchool';
  const endpoint = `${API_URL}/schools/partners/${institutionId}`;
  const formData = new FormData();
  formData.append('school_url', schoolURL);
  formData.append('school_name', schoolName);
  formData.append('school_name_long', schoolNameLong);
  formData.append('background_color', backgroundColor);
  if (extraInformation) {
    formData.append('extra_information', extraInformation);
  }
  formData.append('img_url', imageURL);
  formData.append('image_to_upload', imageToUpload);
  if (program) {
    formData.append('program', program);
  }
  if (preReq) {
    formData.append('pre_req_verbiage', preReq);
  }
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, CREATE_PARTNER_SCHOOL, ''),
      },
    });
    callback?.();
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function patchPartnerSchool(id, schoolName, schoolNameLong, schoolURL, backgroundColor,
  imageURL, imageToUpload, extraInformation, program, preReq, callback) {
  const ACTION_CREATOR_NAME = 'patchPartnerSchool';
  const endpoint = `${API_URL}/partners/${id}`;
  const formData = new FormData();
  formData.append('school_name', schoolName);
  formData.append('school_name_large', schoolNameLong);
  formData.append('route_url', schoolURL);
  formData.append('background_color', backgroundColor);
  formData.append('img_url', imageURL);
  formData.append('image_to_upload', imageToUpload);
  formData.append('special_school_information', extraInformation);
  formData.append('program', program);
  formData.append('pre_req_verbiage', preReq);
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, PATCH_PARTNER_SCHOOL, ''),
      },
    });
    callback?.();
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchCourseEnrollmentData(year) {
  const ACTION_CREATOR_NAME = 'fetchCourseEnrollmentData';
  const endpoint = `${API_URL}/reports/course_enrollments/${year}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_COURSE_ENROLLMENT_REPORT),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function patchLowGradeWarning(id, fieldsToUpdate) {
  const ACTION_CREATOR_NAME = 'patchLowGradeWarning';
  const endpoint = `${API_URL}/low_grade_warnings/${id}`;
  const formData = new FormData();
  for (const attr in fieldsToUpdate) {
    formData.append(attr, fieldsToUpdate[attr]);
  }
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_LOW_GRADE_WARNINGS, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function getTableColumnNames(tableName) {
  const ACTION_CREATOR_NAME = 'getTableColumnNames';
  const endpoint = `${API_URL}/table_names/${tableName}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_COLUMN_TABLE_NAMES),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchViolations(startId = 0) {
  const ACTION_CREATOR_NAME = 'fetchViolations';
  const endpoint = `${API_URL}/integrity_violations?start_id=${startId}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_VIOLATIONS),
      },
    });
    if (actionResponse.payload.next_page) {
      dispatch(fetchViolations(actionResponse.payload.next_page));
    } else {
      dispatch(notLoadingState(ACTION_CREATOR_NAME));
    }
  };
}

export function getPartTimeInstructorPayroll(payrollEnd) {
  const ACTION_CREATOR_NAME = 'getPartTimeInstructorPayroll';
  const endpoint = `${API_URL}/reports/get_part_time_instructor_payroll/${payrollEnd}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, GET_PART_TIME_INSTRUCTOR_PAYROLL),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchViolationTypes() {
  const ACTION_CREATOR_NAME = 'fetchViolationTypes';
  const endpoint = `${API_URL}/integrity_violations/types`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_VIOLATION_TYPES),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function getPartTimeInstructorPayrollExport(payrollEnd) {
  const ACTION_CREATOR_NAME = 'getPartTimeInstructorPayrollExport';
  const endpoint = `${API_URL}/reports/part_time_instructor_payroll_export/${payrollEnd}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, GET_PART_TIME_INSTRUCTOR_PAYROLL_EXPORT),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function importPartnerStudents(importFile) {
  const ACTION_CREATOR_NAME = 'importPartnerStudents';
  const endpoint = `${API_URL}/partner_student_import`;
  const formData = new FormData();
  formData.append('import_file', importFile);
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_PARTNER_STUDENT_IMPORT),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchObservers(institutionCampusPartnerAgreementId) {
  const ACTION_CREATOR_NAME = 'fetchObservers';
  const endpoint = `${API_URL}/observers/${institutionCampusPartnerAgreementId}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_OBSERVERS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchTermsByPartnerAgreement(institutionPartnerAgreementId) {
  const ACTION_CREATOR_NAME = 'fetchTermsInstitutionCampusId';
  const endpoint = `${API_URL}/terms/${institutionPartnerAgreementId}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_TERMS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchPartnerAgreements() {
  const ACTION_CREATOR_NAME = 'fetchPartnerAgreements';
  const endpoint = `${API_URL}/institution_partner_agreements`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_INSTITUTION_PARTNER_AGREEMENTS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchObserverPartnerAgreements() {
  const ACTION_CREATOR_NAME = 'fetchObserverPartnerAgreements';
  const endpoint = `${API_URL}/observers/institution_partner_agreements`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_OBSERVER_INSTITUTION_PARTNER_AGREEMENTS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function createPartnerStudents(creationFields) {
  const ACTION_CREATOR_NAME = 'createPartnerStudents';
  const endpoint = `${API_URL}/partner_student_create`;
  const formData = new FormData();

  creationFields.forEach((field) => formData.append('student_partner_records', JSON.stringify(field)));
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function createPartnerAgreementObserver(observerPartnerAgreementId, observer) {
  const ACTION_CREATOR_NAME = 'createPartnerAgreementObserver';
  const formData = new FormData();
  Object.keys(observer).forEach((key) => {
    formData.append(key, observer[key]);
  });
  const endpoint = `${API_URL}/partners/agreements/${observerPartnerAgreementId}/observer`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, '', ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchInstructors() {
  const ACTION_CREATOR_NAME = 'fetchInstructors';
  const endpoint = `${API_URL}/instructors`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_INSTRUCTORS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchIntegrityViolationReport(violations, violationType, course, instructor, rescinded) {
  const ACTION_CREATOR_NAME = 'fetchMasterStudentSheet';
  const endpoint = `${API_URL}/reports/integrity_violations?violation_type=${violationType}&course_id=${course}&instructor=${instructor}&rescinded=${rescinded}`;
  const formData = new FormData();
  violations.forEach((violation) => formData.append('violations', JSON.stringify(violation)));
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function createTerm(creationFields) {
  const ACTION_CREATOR_NAME = 'createTerm';
  const endpoint = `${API_URL}/terms/create`;
  const formData = new FormData();
  for (const attr in creationFields) {
    if (creationFields[attr] != null) {
      formData.append(attr, creationFields[attr]);
    }
  }
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_TERMS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function patchTerm(termId, fieldsToUpdate) {
  const ACTION_CREATOR_NAME = 'patchTerm';
  const endpoint = `${API_URL}/terms/${termId}`;
  const formData = new FormData();
  for (const attr in fieldsToUpdate) {
    if (fieldsToUpdate[attr] != null) {
      formData.append(attr, fieldsToUpdate[attr]);
    }
  }
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_TERMS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function createInstructor(instructor) {
  const ACTION_CREATOR_NAME = 'createInstructor';
  const formData = new FormData();
  Object.keys(instructor).forEach((key) => {
    if (instructor[key] !== '' && !(key === 'degrees' && instructor[key].length === 0)) {
      if (key === 'degrees') {
        formData.append(key, JSON.stringify(instructor[key]));
      } else {
        formData.append(key, instructor[key]);
      }
    }
  });
  const endpoint = `${API_URL}/instructors`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, '', ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function updateInstructor(instructor) {
  const ACTION_CREATOR_NAME = 'updateInstructor';
  const formData = new FormData();
  Object.keys(instructor).forEach((key) => {
    if (instructor[key] !== '' && !(key === 'degrees' && instructor[key].length === 0) && key !== 'id') {
      if (key === 'degrees') {
        formData.append(key, JSON.stringify(instructor[key]));
      } else {
        formData.append(key, instructor[key]);
      }
    }
  });
  const endpoint = `${API_URL}/instructors/${instructor.id}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, '', ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function createAdmin(admin, callback) {
  const ACTION_CREATOR_NAME = 'createAdmin';
  const formData = new FormData();
  Object.keys(admin).forEach((key) => {
    formData.append(key, admin[key]);
  });
  const endpoint = `${API_URL}/admins`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, '', ''),
      },
    });
    if (actionResponse.meta.responseStatus === 200) {
      callback && callback();
    }
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function resetFacultyPassword(password) {
  const ACTION_CREATOR_NAME = 'resetFacultyPassword';
  const endpoint = `${API_URL}/instructors/reset_password`;
  const formData = new FormData();
  formData.append('password', password);
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        headers: _getAuthHeader(),
        body: formData,
        types: _getFetchTypes(endpoint, '', ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchBlueprintCourses() {
  const ACTION_CREATOR_NAME = 'fetchBlueprintCourses';
  const endpoint = `${API_URL}/blueprints`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, FETCH_BLUEPRINT_COURSES),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchBlueprintCoursesByCourseCode(courseCode) {
  const ACTION_CREATOR_NAME = 'fetchBlueprintCourses';
  const endpoint = `${API_URL}/blueprints/${courseCode}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, FETCH_BLUEPRINT_COURSES_BY_COURSE_CODE),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function createInstructorSection(courseId, blueprintId, instructorId) {
  const ACTION_CREATOR_NAME = 'createInstructorSection';
  const endpoint = `${API_URL}/sections/${courseId}/${blueprintId}/${instructorId}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchActiveEnrollmentsForInstructor(instructorId) {
  const ACTION_CREATOR_NAME = 'fetchActiveEnrollmentsForInstructor';
  const endpoint = `${API_URL}/active_enrollments/${instructorId}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, FETCH_ACTIVE_ENROLLMENTS_FOR_INSTRUCTOR),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchTranscriptRequestTypes() {
  const ACTION_CREATOR_NAME = 'fetchTranscriptRequestTypes';
  const endpoint = `${API_URL}/transcripts/student_transcript_request_types`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, FETCH_TRANSCRIPT_REQUEST_TYPES),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function createProfessorVacationCoverage(creationFields) {
  const ACTION_CREATOR_NAME = 'createProfessorVacationCoverage';
  const formData = new FormData();
  Object.keys(creationFields).forEach((key) => {
    formData.append(key, creationFields[key]);
  });
  const endpoint = `${API_URL}/vacation_coverage`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, FETCH_VACATION_COVERAGE),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchAcademicScholarshipReport() {
  const ACTION_CREATOR_NAME = 'fetchAcademicScholarshipReport';
  const endpoint = `${API_URL}/academic_scholarships`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, FETCH_ACADEMIC_SCHOLARSHIP_REPORT),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function updateAcademicScholarshipStatus(scholarship, newScholarshipStatus, func) {
  const ACTION_CREATOR_NAME = 'updateAcademicScholarshipStatus';
  const endpoint = `${API_URL}/academic_scholarships`;
  const formData = new FormData();
  formData.append('scholarship', JSON.stringify(scholarship));
  formData.append('new_status', newScholarshipStatus);
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, UPDATE_ACADEMIC_SCHOLARSHIP_ENTRY),
      },
    });
    if (actionResponse.meta.responseStatus === 200) {
      func && func();
    }
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function sendAcademicScholarshipEmails(scholarship, emailType) {
  const ACTION_CREATOR_NAME = 'sendAcademicScholarshipEmails';
  const endpoint = `${API_URL}/academic_scholarships/send_notification_email/${emailType}`;
  const formData = new FormData();
  formData.append('scholarship', JSON.stringify(scholarship));
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchVacationCoverage() {
  const ACTION_CREATOR_NAME = 'fetchVacationCoverage';
  const endpoint = `${API_URL}/vacation_coverage`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, FETCH_VACATION_COVERAGE),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchPartnerAgreementTypes() {
  const ACTION_CREATOR_NAME = 'fetchTermsInstitutionCampusId';
  const endpoint = `${API_URL}/partner_agreement_types`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_PARTNER_AGREEMENT_TYPES),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function patchVacationCoverage(coverageId, fieldsToUpdate) {
  const ACTION_CREATOR_NAME = 'patchVacationCoverage';
  const endpoint = `${API_URL}/vacation_coverage/${coverageId}`;
  const formData = new FormData();
  for (const attr in fieldsToUpdate) {
    if (fieldsToUpdate[attr] !== undefined) {
      formData.append(attr, fieldsToUpdate[attr]);
    }
  }
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, FETCH_VACATION_COVERAGE),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function createPartnerAgreement(institutionCampusId, partnerAgreementTypeId, callback) {
  const ACTION_CREATOR_NAME = 'createPartnerAgreement';
  const endpoint = `${API_URL}/partner_agreements`;
  const formData = new FormData();
  formData.append('institution_campus_id', institutionCampusId);
  formData.append('partner_agreement_type_id', partnerAgreementTypeId);
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, ''),
      },
    });
    callback?.();
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function fetchInstitutions() {
  const ACTION_CREATOR_NAME = 'fetchInstitutions';
  const endpoint = `${API_URL}/institutions`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_INSTITUTIONS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function removeVacationCoverage(coverageId) {
  const ACTION_CREATOR_NAME = 'removeVacationCoverage';
  const endpoint = `${API_URL}/vacation_coverage/${coverageId}`;
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: DELETE,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, FETCH_VACATION_COVERAGE),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function createInstitution(institution) {
  const ACTION_CREATOR_NAME = 'createInstitution';
  const endpoint = `${API_URL}/institution`;
  const formData = new FormData();
  formData.append('institution_name', institution);
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_INSTITUTIONS),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}

export function createInstitutionCampus(creationFields) {
  const ACTION_CREATOR_NAME = 'createInstitutionCampus';
  const endpoint = `${API_URL}/institution_campus`;
  const formData = new FormData();
  for (const attr in creationFields) {
    if (creationFields[attr] != null) {
      formData.append(attr, creationFields[attr]);
    }
  }
  return async (dispatch, getState) => {
    dispatch(loadingState(ACTION_CREATOR_NAME));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: _getAuthHeader(),
        types: _getFetchTypes(endpoint, SET_ACTIVE_CAMPUSES_ROSTER),
      },
    });
    dispatch(notLoadingState(ACTION_CREATOR_NAME));
  };
}
