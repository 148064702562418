/*
 * Ryan O'Dowd
 * 2019-01-08
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const DRAWER_WIDTH = 240;
const APP_BAR_HEIGHT = 64;
const INNER_PADDING = 16;

const styles = {
  container: {
    maxWidth: '100vw',
    // @TODO: backgroundColor: Globals.colors.darkGray,
  },
  spacer: {
    flexGrow: 1,
  },
  appBarTitle: {
    whiteSpace: 'nowrap',
    marginRight: 32,
  },
  fetches: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'scroll',
    direction: 'rtl',
  },
  fetchIcon: {
    marginLeft: 4,
    marginRight: 4,
  },
  fixed: {
    position: 'fixed',
  },
  loginInner: {
    marginTop: 0,
  },
  overlay: {
    backgroundColor: 'rgba(111, 111, 111, 0.6)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 999,
  },
  homeLink: {
    color: Globals.colors.light,
    textDecoration: 'none',
  },

  root: {
    display: 'flex',
  },
  appBar: {
    position: 'relative',
    paddingLeft: 0,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    color: Globals.colors.light,
    backgroundColor: Globals.colors.primary,
  },
  drawerHeader: {
    height: APP_BAR_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    justifyContent: 'center',
    marginBottom: 0,
  },
  innerContainer: {
    flexGrow: 1,
    width: '100vw',
    maxWidth: 1600,
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    color: Globals.colors.dark,
    minHeight: `calc(100vh - ${APP_BAR_HEIGHT}px - ${2 * INNER_PADDING}px)`,
  },
  alertContainer: {
    display: 'flex',
  },
};

export default styles;
