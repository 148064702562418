/*
 * Stevie Michalik
 * 2023-07-26
 * © Copyright 2023 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
  },
  headerText: {
    color: Globals.colors.accent,
  },
  selectOptions: {
    marginRight: 15,
    display: 'flex',
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    margin: '12px',
  },
  selectorContainer: {
    display: 'flex',
  },
  sectionContainer: {
    display: 'flex',
    margin: '24px',
    flexDirection: 'column',
  },
  selectorTotalContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    margin: '12px',
    gap: '8px',
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '12px',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
  },
  instructorPayHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '12px',
  },
};

export default styles;
