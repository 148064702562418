/*
 * Nathaniel Padgett
 * 2020-09-24
 * © Copyright 2020 NursingABC, Inc.  All Rights Reserved.
 */

const MARGIN_SPACING = 5;
const styles = {
  formControlWrapper: {
    width: 150,
    marginRight: MARGIN_SPACING,
  },
};

export default styles;
