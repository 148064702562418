/*
 * Ally Zernick
 * 2022-06-16
 * © Copyright 2022 NursingABC, Inc.  All Rights Reserved.
 */

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Paper,
  Radio,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  displayStudentProgressData,
  exportStudentProgressReportPerCampus,
  fetchCourses,
  fetchPartnerAgreements,
  fetchStudent,
  fetchStudents,
  getAllCampuses,
  getAllCampusesWithProgressReportStudents,
  getTerms,
  patchStudentProgressData,
  removeStudentProgressData,
  updateStudentProgressData,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import FlagIcon from 'mdi-react/FlagIcon';
import Globals from '../../Globals';
import HasViolation from '../HasViolation';
import Highlighter from 'react-highlight-words';
import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './styles';

const StudentProgress = () => {
  const [_openDialog, setOpenDialog] = useState(false);
  const [_enrollmentsSelectedToRemove, setEnrollmentsSelectedToRemove] = useState('');
  const [_campusId, setCampusId] = useState('');
  const [_progressReportSchool, setProgressReportSchool] = useState('');
  const [_selectedTerm, setSelectedTerm] = useState('');
  const [_selectedPartnerAgreement, setSelectedPartnerAgreement] = useState();
  const [_selectedCourses, setSelectedCourses] = useState([]);
  const [_isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [_wasStudentAdded, setWasStudentAdded] = useState(false);
  const [_schoolDeadlineDate, setSchoolDeadlineDate] = useState(moment().format('YYYY-MM-DD'));
  const [_studentId, setStudentId] = useState(null);
  const [_studentWasRemoved, setStudentWasRemoved] = useState();
  const [_searchTerm, setSearchTerm] = useState('');
  const [_edit, setEdit] = useState(false);
  const [_editRow, setEditRow] = useState(0);
  const [_studentProgressReportDisabled, setStudentProgressReportDisabled] = useState(true);
  const [_rowsPerPage, setRowsPerPage] = useState(5);
  const [_pageNum, setPageNum] = useState(0);
  const [_displayActiveStudents, setDisplayActiveStudents] = useState(true);
  const _students = useSelector((state) => Object.values(state.students));
  const _displayData = useSelector((state) => state.displayStudentProgressData);
  const _courses = useSelector((state) => state.courses);
  const _activeCampusesWithProgressReportStudents = useSelector((state) => state.activeCampusesWithProgressReportStudents);
  const _allCampuses = useSelector((state) => state.allCampuses);
  const _terms = useSelector((state) => state.terms);
  const _partnerAgreements = useSelector((state) => state.partnerAgreements);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStudents(_displayActiveStudents));
  }, [_displayActiveStudents, dispatch]);

  useEffect(() => {
    dispatch(fetchCourses());
    dispatch(getTerms());
    dispatch(getAllCampuses());
    dispatch(getAllCampusesWithProgressReportStudents());
    dispatch(fetchPartnerAgreements());
  }, [dispatch]);

  const _removeSelectedEnrollments = (event) => {
    if (event.target.checked !== true) {
      setEnrollmentsSelectedToRemove(_enrollmentsSelectedToRemove.filter((id) => { return id !== event.target.name; }));
    } else { setEnrollmentsSelectedToRemove([..._enrollmentsSelectedToRemove, event.target.name]); }
  };

  useEffect(() => {
    if (_studentId !== null && _selectedCourses.length && _schoolDeadlineDate !== null && _selectedTerm !== '' && _campusId !== '') {
      setStudentProgressReportDisabled(false);
    }
  }, [_studentId, _campusId, _schoolDeadlineDate, _selectedTerm, _selectedCourses]);

  useEffect(() => {
    if (_wasStudentAdded) {
      dispatch(getAllCampuses());
      dispatch(getAllCampusesWithProgressReportStudents());
      setWasStudentAdded(false);
    }
  }, [dispatch, _wasStudentAdded]);

  useEffect(() => {
    if (_studentWasRemoved) {
      dispatch(displayStudentProgressData(_progressReportSchool));
      setStudentWasRemoved(false);
    }
  }, [dispatch, _studentWasRemoved, _progressReportSchool]);

  const _resetState = () => {
    setOpenDialog(false);
    setWasStudentAdded(true);
    setSelectedCourses([]);
    setStudentProgressReportDisabled(true);
    setSelectedTerm('');
    setCampusId('');
    setStudentId(null);
  };

  const _deleteProgressReportId = () => {
    if (_enrollmentsSelectedToRemove) {
      _enrollmentsSelectedToRemove.map((p) => { return dispatch(removeStudentProgressData(p), _progressReportSchool); });
      setStudentWasRemoved(true);
    }
  };

  const _handleCourseCheckboxChange = (event, course) => {
    if (event.target.checked !== true) {
      setSelectedCourses(_selectedCourses.filter((c) => { return c !== course; }));
    } else {
      setSelectedCourses([..._selectedCourses, course]);
    }
  };

  const _addEnrollment = () => {
    return (
      <>
        <br />
        <Button
          disabled={_studentProgressReportDisabled}
          onClick={() => {
            dispatch(updateStudentProgressData(_studentId, _selectedCourses, _campusId, _selectedTerm, _schoolDeadlineDate));
            _resetState();
          }}
          style={styles.buttonStyle}
          variant='contained'
          color='primary'
        >
          Add
        </Button>
      </>
    );
  };

  const _viewSchoolsExport = () => {
    return (
      <div>
        <h2>To view a schools current progress report please choose a school from the dropdown then click display</h2>
        <FormControl style={styles.formControlSelectSchool} variant='outlined'>
          <Autocomplete
            style={styles.selectOptions}
            id='combo-box-demo'
            disableClearable={true}
            options={_activeCampusesWithProgressReportStudents.map((a) => ({id: a.id, name: a.name}))}
            getOptionLabel={(activeSchoolsWithReports) => activeSchoolsWithReports.name}
            onChange={(e, progressReportSchool) => setProgressReportSchool(progressReportSchool.id)}
            renderInput={(params) => <TextField {...params} label='School' variant='outlined' />}
          />
        </FormControl>
        <br />
        <Button
          onClick={() => dispatch(displayStudentProgressData(_progressReportSchool))}
          style={styles.DisplayReportDatabuttonStyle}
          variant='contained'
          color='primary'
        >
          {`Display school's progress report`}
        </Button>
      </div>
    );
  };

  const _addStudentToSchoolExport = () => {
    let filteredStudents;
    let wordsToMatch;
    let emptyRows;

    if (_students.length > 0) {
      const searchTerms = _searchTerm.split('|').filter((multipleSearchTerms) => multipleSearchTerms).map((searchTermPart) => searchTermPart.split(' ').map((text) => text.toLowerCase()));

      wordsToMatch = () => {
        let searchWords = [];
        _searchTerm.split(' ').forEach((term) => {
          if (term.includes('|')) {
            searchWords = [...searchWords, ...term.split('|').filter((value) => value)];
          } else {
            searchWords = [...searchWords, term];
          }
        });
        return searchWords;
      };

      filteredStudents = _students.filter((student) => {
        if (!_searchTerm) {
          return true;
        }

        return searchTerms.some((groupOfSearchTerms) => {
          return groupOfSearchTerms.every((searchTerm) => {
            return Object.keys(student).some((studentAttribute) => {
              if (studentAttribute === 'phone') {
                return `${student[studentAttribute]}`.replace(/\D/g, '').includes(searchTerm);
              }
              return `${student[studentAttribute]}`.toLowerCase().includes(searchTerm);
            });
          });
        });
      });

      emptyRows = _rowsPerPage - Math.min(_rowsPerPage, filteredStudents.length - _pageNum * _rowsPerPage);
    }
    return (
      <div style={styles.addStudentToExportDiv}>
        <h2>Click the button below to add a new student to a progress report</h2>
        <Button
          color='primary'
          variant='contained'
          onClick={() => { setOpenDialog(true); }}
        >
          ADD STUDENT
        </Button>
        <Dialog maxWidth='lg' open={_openDialog === true} onClose={() => _resetState()}>
          <DialogTitle>{`Add Student to a School's Progress Report`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please select the student you want to add to a progress report then choose the courses, school, semester and year to add.
            </DialogContentText>
            <div style={styles.studentIdDiv}>
              <div style={styles.detailsRow}>
                <h3 style={styles.idEntry}>Search Student: </h3>
                <TextField
                  id='studentSearchLabel'
                  style={styles.textField}
                  value={_searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  label='Search'
                  margin='dense'
                  variant='outlined'
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={_displayActiveStudents}
                      onChange={(event) => {
                        setDisplayActiveStudents(event.target.checked);
                      }}
                    />
                  }
                  label='Active only'
                />
              </div>
              { _students.length > 0 &&
              <Paper style={styles.smallPaper}>
                <Table
                  id='studentSearch'
                  aria-labelledby='tableTitle'
                  size='medium'
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'>Student Id</TableCell>
                      <TableCell align='left'>Name</TableCell>
                      <TableCell align='left'>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredStudents.slice(_pageNum * _rowsPerPage, _pageNum * _rowsPerPage + _rowsPerPage).map((student) => {
                      return (
                        <React.Fragment key={student.id}>
                          <TableRow
                            style={+_studentId === +student.id ? styles.selectedStudentRow : styles.studentRow}
                            hover
                            tabIndex={-1}
                            onClick={() => {
                              dispatch(fetchStudent(student.id));
                              setStudentId(student.id);
                            }}
                          >
                            <TableCell align='left'>
                              <Radio
                                checked={_studentId === student.id}
                                name='studentSelect'
                                value={student.id}
                                onChange={() => {
                                  setStudentId(student.id);
                                }}
                              />
                              <Highlighter
                                highlightStyle={styles.highlightedText}
                                searchWords={wordsToMatch()}
                                autoEscape={true}
                                textToHighlight={`${student.id}`}
                              />
                            </TableCell>
                            <TableCell align='left'>
                              <Highlighter
                                highlightStyle={styles.highlightedText}
                                searchWords={wordsToMatch()}
                                autoEscape={true}
                                textToHighlight={`${student.first_name} ${student.last_name}`}
                              />
                            </TableCell>
                            <TableCell align='left'>
                              <Highlighter
                                highlightStyle={styles.highlightedText}
                                searchWords={wordsToMatch()}
                                autoEscape={true}
                                textToHighlight={student.email}
                              />
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{height: 75 * emptyRows}}>{/* @TODO: magic number */}
                        <TableCell colSpan={4} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component='div'
                  count={Object.keys(filteredStudents).length}
                  rowsPerPage={_rowsPerPage}
                  page={_pageNum}
                  backIconButtonProps={{'aria-label': 'Previous Page'}}
                  nextIconButtonProps={{'aria-label': 'Next Page'}}
                  onPageChange={(event, _pageNum) => { setPageNum(_pageNum); }}
                  onRowsPerPageChange={(e) => { setRowsPerPage(e.target.value); }}
                />
              </Paper>
              }
              <div>
                <FormControl style={styles.checkboxes} component='fieldset'>
                  <FormLabel component='legend'>Available Courses</FormLabel>
                  {_renderCourseList()}
                </FormControl>
              </div>
            </div>
            <div style={styles.dropDowns}>
              <h3>Select School  |  Term  |  Deadline</h3>
              <FormControl style={styles.formControlSelectSchool} variant='outlined'>
                {Object.keys(_allCampuses).length > 0 &&
                <Autocomplete
                  disableClearable={true}
                  style={styles.selectOptions}
                  id='combo-box-demo'
                  options={_allCampuses.map((a) => ({id: a.id, name: a.name}))}
                  getOptionLabel={(activeCampus) => activeCampus.name}
                  onChange={(e, campus) => {
                    setCampusId(campus.id);
                    setSelectedPartnerAgreement(_partnerAgreements.find((p) => p.institution_campus_id === campus.id)?.partner_agreement_id);
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label='School'
                      variant='outlined'
                    />}
                />
                }
              </FormControl>
              <FormControl style={styles.formControlSelectSecondary} variant='outlined'>
                {Object.keys(_terms).length > 0 &&
                <Autocomplete
                  style={styles.selectOptions}
                  disableClearable={true}
                  options={_terms.filter((t) => t.institution_campus_partner_agreement_id === null || t.institution_campus_partner_agreement_id === _selectedPartnerAgreement).map((t) => ({
                    id: t.id,
                    term_name: t.term_name,
                    partner_id: t.institution_campus_partner_agreement_id,
                  }))}
                  getOptionLabel={(term) => term.term_name}
                  onChange={(e, term) => setSelectedTerm(term.id)}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label='Term'
                      variant='outlined'
                    />}
                >
                </Autocomplete>
                }
              </FormControl>
              <FormControl style={styles.formControlSelectSecondary}>
                <TextField
                  label='School Deadline'
                  disabled={true}
                  disableClearable={true}
                  value={moment(_schoolDeadlineDate, 'YYYY-MM-DD').format('L')}
                  onChange={(e) => setSchoolDeadlineDate(e.target.value)}
                  margin='dense'
                  required={true}
                />
              </FormControl>
              {_isDatePickerOpen &&
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    style={styles.datePicker}
                    open={true}
                    value={moment(_schoolDeadlineDate)}
                    onChange={(e, value) => setSchoolDeadlineDate(e.format('YYYY-MM-DD'))}
                    onClose={() => setIsDatePickerOpen(false)}
                  />
                </MuiPickersUtilsProvider>}
              <IconButton
                style={styles.editIcon}
                onClick={() => setIsDatePickerOpen(true)}
              >
                <EditIcon />
              </IconButton>
              <br />
              {_addEnrollment()}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                _resetState();
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const _saveEdits = (id) => {
    dispatch(patchStudentProgressData(id, _selectedTerm, moment(_schoolDeadlineDate).format('YYYY-MM-DD'), _progressReportSchool));
    setEdit(!_edit);
  };

  const _cancelEdits = () => {
    setEdit(!_edit);
  };

  const _setEdit = (id, term, deadline) => {
    setEditRow(id);
    setSchoolDeadlineDate(deadline);
    setEdit(!_edit);

    Object.values(_terms).forEach((t) => {
      if (term === t.term_name) {
        setSelectedTerm(t.id);
      }
    });
  };

  const _displayCurrentData = () => {
    return (
      <div style={styles.container}>
        <h3>Current Data:</h3>
        <Paper>
          <Table
            id='studentProgress'
            aria-labelledby='tableTitle'
            size='medium'
          >
            <TableHead>
              <TableRow>
                <TableCell align='left'>Student</TableCell>
                <TableCell align='left'>Student Id</TableCell>
                <TableCell align='left'>Enrollment Id</TableCell>
                <TableCell align='left'>Course</TableCell>
                <TableCell align='left'>Current Grade</TableCell>
                <TableCell align='left'>Final Letter Grade</TableCell>
                <TableCell align='left'>Term</TableCell>
                <TableCell align='left'>School Deadline</TableCell>
                <TableCell align='left'>Select</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_displayData.sort((a, b) => a.student.localeCompare(b.student)).map((studentData) => {
                return (
                  <React.Fragment key={studentData.start_date}>
                    <TableRow
                      hover
                    >
                      {_edit && (studentData.id === _editRow) ? (
                        <>
                          <TableCell align='left'>
                            {parseInt(studentData.has_violation) !== 0 && <HasViolation severity={studentData.has_violation} />}
                            {studentData.partner_school && <Tooltip title={studentData.partner_school}><FlagIcon style={{color: Globals.colors.primary}} /></Tooltip>}
                            {studentData.student}
                          </TableCell>
                          <TableCell align='left'> {studentData.student_id} </TableCell>
                          <TableCell align='left'> {studentData.enrollment_id} </TableCell>
                          <TableCell align='left'> {studentData.course} </TableCell>
                          <TableCell align='left'> {studentData.current_grade} </TableCell>
                          <TableCell align='left'> {studentData.final_grade} </TableCell>
                          <TableCell align='left'>
                            <FormControl style={styles.formControlSelectSecondary} variant='outlined'>
                              <Autocomplete
                                style={styles.autocompleteEditTerm}
                                disableClearable={true}
                                options={_terms.map((t) => ({id: t.id, term_name: t.term_name}))}
                                getOptionLabel={(term) => term.term_name}
                                onChange={(e, term) => {
                                  setSelectedTerm(term.id);
                                }}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                    label='Term'
                                    variant='outlined'
                                  />}
                              >
                              </Autocomplete>
                            </FormControl>
                          </TableCell>
                          <TableCell align='left'>
                            <FormControl>
                              <TextField
                                label='School Deadline'
                                disabled={true}
                                disableClearable={true}
                                value={moment(_schoolDeadlineDate, 'YYYY-MM-DD').format('L')}
                                onChange={(e) => setSchoolDeadlineDate(e.format('YYYY-MM-DD'))}
                                margin='dense'
                                required={true}
                              />
                            </FormControl>
                            {_isDatePickerOpen &&
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                  open={true}
                                  value={moment(_schoolDeadlineDate)}
                                  onChange={(e) => setSchoolDeadlineDate(e.format('YYYY-MM-DD'))}
                                  onClose={() => setIsDatePickerOpen(false)}
                                />
                              </MuiPickersUtilsProvider>}
                            <IconButton
                              style={styles.editIcon}
                              onClick={() => setIsDatePickerOpen(true)}
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell align='left'>
                            <FormControl>
                              <FormGroup>
                                <Checkbox
                                  onChange={((e) => { _removeSelectedEnrollments(e); })}
                                  name={studentData.id}
                                />
                              </FormGroup>
                            </FormControl>
                            <Button align='right' onClick={(() => { _saveEdits(studentData.id); })}>
                              <DoneIcon />
                            </Button>
                            <Button align='right' onClick={_cancelEdits}>
                              <CloseIcon />
                            </Button>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell align='left'>
                            {parseInt(studentData.has_violation) !== 0 && <HasViolation severity={studentData.has_violation} />}
                            {studentData.partner_school && <Tooltip title={studentData.partner_school}><FlagIcon style={{color: Globals.colors.primary}} /></Tooltip>}
                            {studentData.student}
                          </TableCell>
                          <TableCell align='left'> {studentData.student_id} </TableCell>
                          <TableCell align='left'> {studentData.enrollment_id} </TableCell>
                          <TableCell align='left'> {studentData.course} </TableCell>
                          <TableCell align='left'> {studentData.current_grade} </TableCell>
                          <TableCell align='left'> {studentData.final_grade} </TableCell>
                          <TableCell align='left'> {studentData.term} </TableCell>
                          <TableCell align='left'> {studentData.school_deadline_date} </TableCell>
                          <TableCell align='left'>
                            <FormControl>
                              <FormGroup>
                                <Checkbox
                                  onChange={((e) => { _removeSelectedEnrollments(e); })}
                                  name={studentData.id}
                                />
                              </FormGroup>
                            </FormControl>
                            <Button align='right' onClick={(() => { _setEdit(studentData.id, studentData.term, studentData.school_deadline_date); })}>
                              <CreateIcon />
                            </Button>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
        <br />
        <Button
          onClick={() => {
            dispatch(exportStudentProgressReportPerCampus(_progressReportSchool));
          }}
          style={styles.exportButton}
          variant='contained'
          color='primary'
        >
          Download Student Progress Report
        </Button>
        <Button
          onClick={() => {
            _deleteProgressReportId();
            setStudentWasRemoved(true);
          }}
          style={styles.buttonStyle}
          variant='contained'
          color='primary'
        >
          Remove Selected Enrollment
        </Button>
      </div>
    );
  };

  const _renderCourseList = () => {
    if (Object.keys(_courses).length > 0) {
      return (
        <>
          {Object.keys(_courses)
            .sort((a, b) => _courses[a].code > _courses[b].code ? 1 : -1)
            .map((courses) => {
              return (
                <FormGroup key={courses} style={styles.checkboxDiv}>
                  <FormControlLabel
                    control={<Checkbox name={_courses[courses].code} onChange={((e) => { _handleCourseCheckboxChange(e, _courses[courses].id); })} />}
                    label={`(${_courses[courses].code}) ${_courses[courses].title}`}
                  />
                </FormGroup>
              );
            })}
        </>
      );
    }
  };

  return (
    <div>
      {!Object.values(_allCampuses).length ? (<p>Loading...</p>)
        : (<div style={styles.container}>
          <h1>Export Student Progress </h1>
          {_addStudentToSchoolExport()}
          <div style={styles.lineSeparator}></div>
          {_viewSchoolsExport()}
          <div style={styles.lineSeparator}></div>
          {_displayCurrentData()}
        </div>)
      }
    </div>
  );
};

StudentProgress.propTypes = {
  activeCampuses: PropTypes.array,
  removeStudentProgressData: PropTypes.func,
  updateStudentProgressData: PropTypes.func,
  displayStudentProgressData: PropTypes.func,
  displayData: PropTypes.array,
  patchStudentProgressData: PropTypes.func,
  exportStudentProgress: PropTypes.func,
  fetchStudent: PropTypes.func,
  fetchStudents: PropTypes.func,
  fetchStudentCourses: PropTypes.func,
  getActiveCampuses: PropTypes.func,
  getAllCampusesWithProgressReportStudents: PropTypes.object,
  studentCourses: PropTypes.object,
  courses: PropTypes.object,
  activeSchoolsWithProgressReportStudents: PropTypes.object,
};

export default (StudentProgress);
