/*
 * Ally Zernick
 * 2022-10-20
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  selectors: {
    width: '20%',
    marginRight: 10,
  },
  noteText: {
    color: 'gray',
    paddingBottom: '15px',
    marginLeft: '15px',
  },
  barGraphContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    marginTop: '10px',
    gap: '5px',
    padding: '5px',
    border: '1px solid lightgray',
  },
  barInterior: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
  },
  barLabel: {
    fontSize: '14px',
    width: '100px',
    marginTop: '2.5px',
    marginBottom: '2.5px',
    textAlign: 'center',
  },
  divider: {
    width: '1px',
    backgroundColor: 'lightgray',
    height: '20px',
  },
  download: {
    marginTop: '10px',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    color: 'gray',
  },
};

export default styles;
