/*
 * Ryan O'Dowd
 * 2020-05-13
 * © Copyright 2020 NursingABC, Inc.  All Rights Reserved.
 */

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
} from '@material-ui/core';
import Globals, {
  ACCEPTANCE_LETTER,
} from '../../Globals';
import {
  downloadSecureFile,
  fetchSecureFileS3PreAuthLink,
  fetchSecureFiles,
  saveFileFromDownloadedS3Bucket,
  setUploadedS3SecureFile,
  uploadSecureFile,
} from '../../actions';
import DownloadIcon from 'mdi-react/DownloadIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import {
  fileHumanReadableSize,
} from '../../utilities';
import moment from 'moment';
import styles from './styles';
import {
  v4 as uuidv4,
} from 'uuid';

class SecureFiles extends React.Component {
  static propTypes = {
    admin: PropTypes.object.isRequired,
    downloadSecureFile: PropTypes.func.isRequired,
    fetchSecureFiles: PropTypes.func.isRequired,
    fetchSecureFileS3PreAuthLink: PropTypes.func.isRequired,
    loadingStates: PropTypes.object.isRequired,
    s3Actions: PropTypes.array.isRequired,
    saveFileFromDownloadedS3Bucket: PropTypes.func.isRequired,
    secureFiles: PropTypes.object.isRequired,
    setUploadedS3SecureFile: PropTypes.func.isRequired,
    uploadSecureFile: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      uploadDialogIsOpen: false,
      uploadType: '',
      uploadFilename: '',
      uploadContentType: '',
      fileToUpload: null,
      searchText: '',
      pageNum: 0,
      rowsPerPage: 10,
      fileToUploadSize: 0,
      displayDownloadProgress: false,
      currentDownloadFileId: null,
    };
  }

  componentDidMount() {
    this.props.fetchSecureFiles();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.s3Actions.s3DownloadPreAuthReceived) {
      fetch(this.props.s3Actions.s3DownloadPreAuthURL, {
        method: 'GET',
      }).then((response) => response.blob()
      ).then((blob) => {
        this.props.saveFileFromDownloadedS3Bucket(blob, this.props.s3Actions.filename, blob.type);
        this.setState({displayDownloadProgress: false, currentDownloadFileId: null});
      }).catch((error) => {
        // @ TODO: is there a better way for displaying errors here?
        console.error(error);
      });
    }
  }

  _cancelUpload() {
    this.setState({ // @TODO: update
      uploadDialogIsOpen: false,
      uploadType: '',
      uploadFilename: '',
      uploadContentType: '',
      fileToUpload: null,
      fileId: '',
    });
  }

  _renderPanelWithTable(fileCategory, title, description, data) {
    const sortedData = data.sort((s1, s2) => (s1.date_modified < s2.date_modified) ? 1 : (s1.date_modified > s2.date_modified) ? -1 : 0);
    const searchTerms = this.state.searchText.split(' ').map((text) => text.toLowerCase());
    const filteredData = sortedData.filter((file) => {
      return searchTerms.every((searchTerm) => {
        return Object.keys(file).some((fileAttribute) => {
          return `${file[fileAttribute]}`.toLowerCase().includes(searchTerm);
        });
      });
    });
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, filteredData.length - this.state.pageNum * this.state.rowsPerPage);

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div style={styles.sectionWrapper}>
            <span style={styles.sectionTitle}>{title}</span>
            <span style={styles.sectionDescription}>{description}</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Paper style={styles.tableWrapper}>{/* @TODO: add padding and stuff */}
            <Toolbar style={styles.tableToolbar}>
              <div style={styles.leftItems}>
                {/* @TODO: search bar...needs to work on all fields, not just visible ones. but if not visible, need to show waht it's matching against...also have specific search bars for each column */}
                <TextField
                  style={styles.textField}
                  label='Search'
                  value={this.state.searchText}
                  onChange={(e) => this.setState({searchText: e.target.value})}
                  margin='dense'
                />
              </div>
              <div style={styles.rightItems}>
                {fileCategory !== 'acceptance_letter' &&
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => this.setState({uploadDialogIsOpen: true, uploadType: fileCategory})}
                  >
                  Upload new
                  </Button>
                }
              </div>
            </Toolbar>
            <div>
              <Table aria-labelledby='tableTitle'>
                {/* @TODO: make columns sortable? */}
                <TableHead>
                  <TableRow>
                    <TableCell>File name</TableCell>
                    <TableCell>Date modified</TableCell>
                    <TableCell>Download</TableCell>
                    {/* @TODO: add more fields */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.slice(this.state.pageNum * this.state.rowsPerPage, this.state.pageNum * this.state.rowsPerPage + this.state.rowsPerPage).map((file) => {
                  // @TODO: auto sort by last name?
                    return (
                      <React.Fragment key={file.id}>
                        <TableRow
                          hover
                          tabIndex={-1}
                        >
                          <TableCell>
                            <Highlighter
                              highlightStyle={styles.highlightedText}
                              searchWords={this.state.searchText.split(' ')}
                              autoEscape={true}
                              textToHighlight={file.name}
                            />
                          </TableCell>
                          <TableCell>
                            {moment(file.date_modified).format('ddd M/D/YYYY, LT')}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color='secondary'
                              onClick={() => {
                                this.props.downloadSecureFile(Globals.s3Buckets.privateAssets, `${fileCategory}/${file.name}`);
                                this.setState({displayDownloadProgress: true, currentDownloadFileId: file.id});
                              }}
                            >
                              <DownloadIcon />
                              {this.state.displayDownloadProgress && this.state.currentDownloadFileId === file.id && <CircularProgress size={30}/>}
                            </IconButton>
                            {file.size}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{height: 49 * emptyRows}}>{/* @TODO: magic number */}
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            {/* @TODO: update pagination props */}
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component='div'
              count={filteredData.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.pageNum}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onPageChange={(event, pageNum) => {
                // @TODO: update query params
                this.setState({pageNum});
              }}
              onRowsPerPageChange={(event) => {
                this.setState({rowsPerPage: event.target.value});
              }}
            />
          </Paper>
        </AccordionDetails>
      </Accordion>
    );
  }

  render() {
    return (
      <div style={styles.container}>
        <h3>Secure Files</h3>
        {this._renderPanelWithTable('disability', 'Disability Documentation', 'for adding additional time to exams', this.props.secureFiles.disability)}
        <br />
        {this._renderPanelWithTable('military', 'Military Documentation', '10% discount on registration', this.props.secureFiles.military)}
        <br />
        {this._renderPanelWithTable('personal', 'Personal Documentation', 'name changes, address changes, etc.', this.props.secureFiles.personal)}
        <br />
        {!!this.props.admin.perms[ACCEPTANCE_LETTER] &&
          <div>
            {this._renderPanelWithTable('acceptance_letter', 'Partner Acceptance Letters', 'student acceptance letters', this.props.secureFiles.acceptance_letter)}
          </div>
        }

        <Dialog
          open={this.state.uploadDialogIsOpen}
          onClose={() => this._cancelUpload}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Upload new file</DialogTitle>
          <DialogContent>
            <p>Please select the file to upload.   (Must be a PDF or image file.)</p>
            <Button
              variant='contained'
              color='secondary'
              component='label'
            >
              Upload
              <input
                type='file'
                id='upload-button'
                accept='application/pdf,image/*'
                hidden={true}
                onChange={(i) => {
                  const file = i.target.files[0];
                  this.setState({
                    fileId: uuidv4(),
                    fileToUpload: file,
                    uploadFilename: file.name,
                    uploadContentType: file.type,
                    fileToUploadSize: file.size,
                  });
                }}
              />
            </Button>
            {this.state.uploadFilename && <ul>
              <li>{this.state.uploadFilename}</li>
            </ul>}
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='primary'
              disabled={!this.state.fileToUpload}
              onClick={() => this.props.fetchSecureFileS3PreAuthLink(this.state.uploadType, this.state.uploadFilename, this.state.uploadContentType, this.state.fileId, Globals.s3Buckets.privateAssets, () => {
                this.props.uploadSecureFile(this.state.fileToUpload, this.props.s3Actions[this.state.fileId].s3UploadPreAuthURL, this.state.uploadContentType, () => {
                  const secureFileInfo = {
                    date_modified: moment(),
                    id: uuidv4(),
                    name: this.state.uploadFilename,
                    size: fileHumanReadableSize(this.state.fileToUploadSize),
                  };
                  this.props.setUploadedS3SecureFile(this.state.uploadType, secureFileInfo);
                  this._cancelUpload();
                });
              }) }
            >
              Upload
            </Button>
            {this.props.loadingStates.uploadSecureFile && <CircularProgress size={30}/>}
            {/* @TODO: make sure order of confrim/cancel buttons are consistent across site */}
            <Button
              color='secondary'
              onClick={() => this._cancelUpload()}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    secureFiles: state.secureFiles,
    s3Actions: state.s3Actions,
    loadingStates: state.loadingStates,
    admin: state.admin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    downloadSecureFile,
    fetchSecureFileS3PreAuthLink,
    fetchSecureFiles,
    saveFileFromDownloadedS3Bucket,
    setUploadedS3SecureFile,
    uploadSecureFile,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SecureFiles);
