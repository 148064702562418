/*
 * Ryan O'Dowd
 * 2019-01-08
 * © Copyright 2019 NursingABC, Inc.  All Rights Reserved.
 */
import {
  AUTHORIZATION_CODES,
  CONTRACT_EDITOR_SHARING,
  CREATE_AND_EDIT_TERMS,
  CREATE_INSTITUTION_OR_CAMPUS,
  DEVELOPER,
  EDIT_STUDENT_INFO,
  ELECTRONIC_TRANSCRIPTS,
  EXPORT_OUTCOMES,
  FINANCE,
  INSTRUCTIONAL_DESIGN,
  INSTRUCTOR_ASSIGNMENT,
  INTEGRITY_VIOLATIONS,
  LOW_GRADES,
  PARTNER_SCHOOLS,
  PIE,
  READ_STUDENT_INFO,
  REFUNDS,
  REGISTRATIONS,
  routes,
  SCHOLARSHIPS,
  SECURE_FILES,
  STUDENT_PROGRESS,
  SYLLABI,
  TRANSCRIPTS,
  VACATION_COVERAGE,
  VIDEO_CAPTIONING,
} from '../../Globals';
import {
  AccountCircle,
  AssignmentInd,
  SmsFailedOutlined,
  SupervisedUserCircle,
} from '@material-ui/icons'; // @TODO: replace all icons app-wide with mdi
import AcademicScholarships from '../AcademicScholarships';
import Account from '../Account';
import Admins from '../Admins';
import AlertIcon from 'mdi-react/AlertIcon';
import AttachMoneyIcon from 'mdi-react/AttachMoneyIcon';
import AuthorizationCodes from '../DiscountCodes';
import AwsIcon from 'mdi-react/AwsIcon';
import AwsTools from '../DeveloperTools/AwsTools';
import BeachIcon from 'mdi-react/BeachIcon';
import BookOpenPageVariantIcon from 'mdi-react/BookOpenPageVariantIcon';
import BroomIcon from 'mdi-react/BroomIcon';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckbookIcon from 'mdi-react/CheckbookIcon';
import ClosedCaptionIcon from 'mdi-react/ClosedCaptionIcon';
import CompanyDashboard from '../CompanyDashboard';
import Contacts from '../Contacts';
import ContactsIcon from 'mdi-react/ContactsIcon';
import ContractEditorSharing from '../ContractEditorSharing';
import DatabaseIcon from 'mdi-react/DatabaseIcon';
import DatabaseTools from '../DeveloperTools/DatabaseTools';
import DomainIcon from 'mdi-react/DomainIcon';
import ElectronicTranscriptTransfer from '../ElectronicTranscriptTransfer';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ExportOutcomes from '../ExportOutcomes';
import FileExportOutline from 'mdi-react/FileExportIcon';
import FileLockIcon from 'mdi-react/FileLockIcon';
import FilmstripIcon from 'mdi-react/FilmstripIcon';
import Home from '../Home';
import HomeIcon from 'mdi-react/HomeIcon';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import InstructorAssignment from '../InstructorAssignment';
import IntegrityViolations from '../IntegrityViolations';
import LockIcon from 'mdi-react/LockIcon';
import LoginIcon from 'mdi-react/LoginIcon';
import LowGradeWarnings from '../LowGradeWarnings';
import MergeStudents from '../MergeStudents';
import NoteMultipleIcon from 'mdi-react/NoteMultipleIcon';
import PartnerAgreements from '../PartnerAgreements';
import PartnerSchools from '../PartnerSchools';
import PartnerStudentImport from '../PartnerStudentImport';
import Payroll from '../Payroll';
import React from 'react';
import Refunds from '../Refunds';
import Registrations from '../Registrations';
import Reporting from '../Reporting';
import SaleIcon from 'mdi-react/SaleIcon';
import SchoolIcon from 'mdi-react/SchoolIcon';
import SchoolNames from '../SchoolNames';
import SectionCreation from '../SectionCreation';
import SecureFiles from '../SecureFiles';
import SendLockIcon from 'mdi-react/SendLockIcon';
import StudentManagement from '../StudentManagement';
import StudentProgress from '../StudentProgress';
import Syllabi from '../Syllabi';
import Terms from '../Terms';
import Transcripts from '../Transcripts';
import VacationCoverage from '../VacationCoverage';
import VideoCaptioning from '../VideoCaptioning';

const _adminHasPerm = (adminPerms, option) => {
  if (!adminPerms) {
    return false;
  }
  if (adminPerms.developer || !option.perms || option.perms.length === 0) {
    return true;
  }

  for (const optionPerm of option.perms) {
    if (!Object.keys(adminPerms).includes(optionPerm)) {
      // @TODO: throw error
    }
    /* @TODO: instructor assignment
    if (!Number.isInteger(adminPerms[optionPerm])) {
      for (const subPerm of Object.values(adminPerms[optionPerm])) {
        if (subPerm === 1) {
          return true;
        }
      }
    }
    */
    if (adminPerms[optionPerm] === 1) {
      return true;
    }
  }

  return false;
};

const homeOptions = (adminPerms) => [ // @TODO: in sidebar, this list should be ordered
  {
    name: 'Home',
    section: 'none',
    icon: <HomeIcon />,
    href: routes.home,
    component: Home,
    hideOnHomeScreen: true,
  },
  {
    name: 'Clean Student Input',
    section: 'students',
    icon: <BroomIcon />,
    href: routes.cleanInput,
    component: SchoolNames,
    description: 'Clean up school names, addresses, and other data that are entered incorrectly by students.  Most input is corrected automatically, but this utility is helpful for extreme misspellings, new schools, and other entries requiring manual approval/whitelisting.',
    perms: [DEVELOPER],
    isAlphaFeature: true,
  },
  {
    name: 'Merge Students',
    section: 'general',
    icon: null,
    href: routes.mergeStudents,
    component: MergeStudents,
    hideInSidebar: true,
    hideOnHomeScreen: true,
    perms: [EDIT_STUDENT_INFO],
  },
  {
    name: 'Students',
    section: 'students',
    icon: <SupervisedUserCircle />,
    href: routes.studentManagement,
    component: StudentManagement,
    description: 'View all student information.',
    perms: [READ_STUDENT_INFO, EDIT_STUDENT_INFO],
  },
  {
    name: 'Authorization Codes',
    section: 'students',
    icon: <SaleIcon />,
    href: routes.discountCodes,
    component: AuthorizationCodes,
    description: 'Provision authorization codes to provide discounts for new registrations.',
    perms: [AUTHORIZATION_CODES],
  },
  {
    name: 'Registrations',
    section: 'students',
    icon: <LoginIcon />,
    href: routes.registrations,
    component: Registrations,
    description: 'View recent registrations',
    perms: [REGISTRATIONS],
  },
  {
    name: 'Transcript Requests',
    section: 'students',
    icon: <CheckbookIcon />,
    href: routes.transcripts,
    component: Transcripts,
    description: 'Export transcript requests',
    perms: [TRANSCRIPTS],
  },
  {
    name: 'Electronic Transcript Transfer',
    section: 'students',
    icon: <SendLockIcon />,
    href: routes.etts,
    component: ElectronicTranscriptTransfer,
    description: 'Securely manage electronic transcript requests with external recipients',
    perms: [ELECTRONIC_TRANSCRIPTS],
  },
  {
    name: 'Secure Files',
    section: 'students',
    icon: <FileLockIcon />,
    href: routes.secureFiles,
    component: SecureFiles,
    description: 'Secure uploads for disability, military, or personal documentation',
    perms: [SECURE_FILES],
  },
  {
    name: 'Academic Scholarships',
    section: 'students',
    icon: <SchoolIcon />,
    href: routes.academicScholarships,
    component: AcademicScholarships,
    description: 'Manage all of the Portage Learning Academic Scholarship eligible students',
    perms: [DEVELOPER, SCHOLARSHIPS],
  },
  {
    name: 'Instructor Assignment',
    section: 'academics',
    icon: <AssignmentInd />,
    href: routes.instructorAssignment,
    component: InstructorAssignment,
    description: 'View or modify automatically-assigned instructors.',
    perms: [INSTRUCTOR_ASSIGNMENT],
  },
  {
    name: 'Syllabi',
    section: 'academics',
    icon: <NoteMultipleIcon />,
    href: routes.syllabi,
    component: Syllabi,
    description: 'Update syllabi for any course on the website and in Canvas.',
    perms: [SYLLABI],
  },
  {
    name: 'Export Outcomes',
    section: 'academics',
    icon: <FileExportOutline />,
    href: routes.exportOutcomes,
    component: ExportOutcomes,
    description: 'Export outcome results from course Id.',
    perms: [EXPORT_OUTCOMES],
  },
  {
    name: 'Low Grade Warnings',
    section: 'academics',
    icon: <AlertIcon />,
    href: routes.lowGradeWarnings,
    component: LowGradeWarnings,
    description: 'View low grade warnings.',
    perms: [DEVELOPER, LOW_GRADES],
  },
  {
    name: 'Integrity Violations',
    section: 'academics',
    icon: <SmsFailedOutlined />,
    href: routes.integrityViolations,
    component: IntegrityViolations,
    description: 'View academic integrity violations.',
    perms: [DEVELOPER, INTEGRITY_VIOLATIONS],
  },
  {
    name: 'Student Import',
    section: 'academics',
    icon: <ImportExportIcon />,
    href: routes.partnerStudentImport,
    component: PartnerStudentImport,
    description: 'Import and assign students from a spreadsheet',
    perms: [DEVELOPER, PARTNER_SCHOOLS],
  },
  {
    name: 'Partner Agreements, Institutions & Campuses',
    section: 'academics',
    icon: <EngineeringIcon />,
    href: routes.partnerAgreements,
    component: PartnerAgreements,
    description: 'Create new partner agreements, institutions and campuses.',
    perms: [DEVELOPER, CREATE_INSTITUTION_OR_CAMPUS],
  },
  {
    name: 'Terms',
    section: 'academics',
    icon: <CalendarMonthIcon />,
    href: routes.terms,
    component: Terms,
    description: 'Create and edit terms',
    perms: [DEVELOPER, CREATE_AND_EDIT_TERMS],
  },
  {
    name: 'Create Instructor Sections',
    section: 'academics',
    icon: <NoteMultipleIcon />,
    href: routes.createSections,
    component: SectionCreation,
    description: 'Create sections in Canvas',
    perms: [DEVELOPER, INSTRUCTIONAL_DESIGN],
  },
  {
    name: 'Student Progress Reports',
    section: 'students',
    icon: <FileExportOutline />,
    href: routes.studentProgress,
    component: StudentProgress,
    description: 'Student progress reports based on given student id or search by school.',
    perms: [STUDENT_PROGRESS],
  },
  {
    name: 'Payroll - Part Time Instructors',
    section: 'finance',
    icon: <AttachMoneyIcon />,
    href: routes.payroll,
    component: Payroll,
    description: 'Display and export part time instructor payroll info.',
    perms: [FINANCE],
  },
  {
    name: 'Refunds',
    section: 'finance',
    icon: <AttachMoneyIcon />,
    href: routes.refunds,
    component: Refunds,
    description: 'Approve refund requests for students who have been withdrawn.',
    perms: [REFUNDS],
  },
  {
    name: 'Contacts',
    section: 'pie',
    icon: <ContactsIcon />,
    href: routes.newContacts,
    component: Contacts,
    description: 'View contacts from transcript requests, new registration, etc.',
    perms: [PIE],
  },
  {
    name: 'Partner Schools',
    section: 'school_relations',
    icon: <HomeIcon />,
    href: routes.partnerSchools,
    component: PartnerSchools,
    description: 'View partner schools.',
    perms: [PARTNER_SCHOOLS],
  },
  {
    name: 'Contract-Editor Sharing',
    section: 'multimedia',
    icon: <FilmstripIcon />,
    href: routes.contractEditorSharing,
    component: ContractEditorSharing,
    description: 'Share source media with contract editors.',
    perms: [CONTRACT_EDITOR_SHARING],
  },
  {
    name: 'Video Captioning',
    section: 'multimedia',
    icon: <ClosedCaptionIcon />,
    href: routes.videoCaptioning,
    component: VideoCaptioning,
    description: 'Manage 3Play transcripts for video captioning.',
    perms: [VIDEO_CAPTIONING],
  },
  {
    name: 'Admins',
    section: 'developer',
    icon: <LockIcon />,
    href: routes.admins,
    component: Admins,
    description: 'A place for developers to create new admins and change permissions for existing admins.',
    perms: [DEVELOPER],
  },
  {
    name: 'AWS Tools',
    section: 'developer',
    icon: <AwsIcon />,
    href: routes.awsTools,
    component: AwsTools,
    description: 'Dev tool to reprocess the DLQ and purge the Final DLQ.',
    perms: [DEVELOPER],
  },
  {
    name: 'Database Tools',
    section: 'developer',
    icon: <DatabaseIcon />,
    href: routes.databaseTools,
    component: DatabaseTools,
    description: "Basic updates for students and enrollments tables and Canvas sections.  Removes the need for superfluous migrations but doesn't require write perms to prod database.",
    perms: [DEVELOPER],
  },
  {
    name: 'Company Dashboard',
    section: 'general',
    icon: <DomainIcon />,
    href: routes.companyDashboard,
    component: CompanyDashboard,
    description: 'Slideshow for registration counts and other company data',
  },
  {
    name: 'Reporting',
    section: 'general',
    icon: <DatabaseIcon />,
    href: routes.reporting,
    component: Reporting,
    description: 'Reports created to deal with specific information needs',
    perms: [REGISTRATIONS, FINANCE],
  },
  {
    name: 'Wiki',
    section: 'general',
    icon: <BookOpenPageVariantIcon />,
    href: routes.wiki,
    description: 'Documentation on NursingABC, Inc. proceedures, HR documents, and other reference material.',
  },
  {
    name: 'Account',
    section: 'general',
    icon: <AccountCircle />,
    href: routes.account,
    component: Account,
    startOfNewSection: true,
    hideOnHomeScreen: true,
  },
  {
    name: 'Professor Vacation Coverage',
    section: 'academics',
    icon: <BeachIcon />,
    href: routes.vacationCoverage,
    component: VacationCoverage,
    description: 'Assign coverage for professor vacation.',
    perms: [DEVELOPER, VACATION_COVERAGE],
  },
].filter((option) => _adminHasPerm(adminPerms, option)); // @TODO: if isAlphaFeature, maybe only let developers access it

export default homeOptions;
