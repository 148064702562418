/*
 * Ryan O'Dowd
 * 2021-03-15
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
} from '@material-ui/core';
import React from 'react';
import {
  fetchSchoolRelationsTranscriptRequests,
} from '../../actions';
import styles from './styles';
import {
  useDispatch,
} from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';

const NewContacts = () => {
  const dispatch = useDispatch();

  return (
    <div style={styles.container}>
      <h3>Click the button below to download transcript requests for the past week.</h3>
      <Button
        color='primary'
        variant='contained'
        onClick={() => dispatch(fetchSchoolRelationsTranscriptRequests())}
      >
          Download Transcripts Report
      </Button>
    </div>
  );
};

export default withRouter(NewContacts);
