/*
 * Ryan O'Dowd
 * 2020-10-22
 * © Copyright 2020 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  TreeItem,
  TreeView,
} from '@material-ui/lab';
import {
  clearS3ShareLink,
  fetchMultimediaCloudDirectories,
  fetchMultimediaFiles,
  generateShareS3PreAuthLink,
} from '../../actions';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import styles from './styles';

const ONE_HOUR = 60 * 60;
const ONE_DAY = ONE_HOUR * 24;
const THREE_DAYS = ONE_DAY * 3;
const ONE_WEEK = ONE_DAY * 7;

const DEFAULT_SHARE_DURATION = THREE_DAYS;

class ContractEditorSharing extends React.Component {
  static propTypes = {
    apiGetS3ShareLink: PropTypes.object.isRequired,
    clearS3ShareLink: PropTypes.func.isRequired,
    fetchMultimediaCloudDirectories: PropTypes.func.isRequired,
    fetchMultimediaFiles: PropTypes.func.isRequired,
    generateShareS3PreAuthLink: PropTypes.func.isRequired,
    multimediaFiles: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      uploadDialogIsOpen: false,
      uploadType: '',
      uploadFilename: '',
      uploadContentType: '',
      fileToUpload: null,
      searchText: '',
      pageNum: 0,
      rowsPerPage: 10,
      fileToUploadSize: 0,
      shareDuration: DEFAULT_SHARE_DURATION,
      currentNodeSelected: null,
    };
  }

  componentDidMount() {
    this.props.fetchMultimediaFiles();
  }

  _renderTreeItemChildren(children) {
    return children.map((node) => {
      if (node.children) {
        return (
          <React.Fragment key={node.id}>
            <TreeItem nodeId={node.id} label={node.name}>
              {this._renderTreeItemChildren(node.children)}
            </TreeItem>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={node.id}>
          <TreeItem
            onClick={(e) => {
              if (!node.is_eligible_for_download) {
                // @TODO this is super hacky. It works because this function gets called after onNodeSelect callback.
                // Its purpose is to make sure that the admin can't select a s3 link for a Glacier archive file
                this.setState({shareDialogIsOpen: false, currentNodeSelected: null});
              }
            }}
            nodeId={node.id}
            label={node.is_eligible_for_download || node.name === 'Loading...' ? node.name : `${node.name} (Not Available for Download)`}
            style={node.is_eligible_for_download || node.name === 'Loading...' ? {} : {cursor: 'default', opacity: '.5'}}
          />
        </React.Fragment>
      );
    });
  }

  _searchAndUpdateTreeNodes(node, nodeId) {
    node.forEach((nodes) => {
      if (nodes.id === nodeId && nodes.name !== '') {
        nodes.children.forEach((node) => {
          if (node.name === 'Loading...') {
            this.props.fetchMultimediaCloudDirectories(nodes.id);
          }
        });
      } else if (nodes.children) {
        this._searchAndUpdateTreeNodes(nodes.children, nodeId);
      }
    });
  }

  _closeShareDialog() {
    this.setState({shareDialogIsOpen: false, shareDuration: DEFAULT_SHARE_DURATION});
    this.props.clearS3ShareLink();
  }

  _renderShareDialog() {
    return (
      <Dialog
        open={this.state.shareDialogIsOpen}
        onClose={() => this._closeShareDialog()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Share file</DialogTitle>
        <DialogContent>
          <p>Please select the duration for which the download link should be valid.</p>
          <Select
            style={styles.TODO}
            value={this.state.shareDuration}
            onChange={(e) => this.setState({shareDuration: e.target.value})}
            input={<Input id='country' name='country' style={styles.textField} />}
          >
            <MenuItem value={ONE_HOUR}>1 hour</MenuItem>
            <MenuItem value={ONE_DAY}>1 day</MenuItem>
            <MenuItem value={THREE_DAYS}>3 days</MenuItem>
            <MenuItem value={ONE_WEEK}>1 week</MenuItem>
          </Select>
          {this.props.apiGetS3ShareLink.s3DownloadPreAuthURL &&
            <div style={styles.presignedUrl}>
              <span>{this.props.apiGetS3ShareLink.s3DownloadPreAuthURL}</span>
              <IconButton
                color='secondary'
                onClick={() => navigator.clipboard.writeText(this.props.apiGetS3ShareLink.s3DownloadPreAuthURL)}
              >
                <ContentCopyIcon />
              </IconButton>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            onClick={() => this.props.generateShareS3PreAuthLink(this.state.currentNodeSelected, this.state.shareDuration)}
          >
            Generate link
          </Button>
          <Button
            color='secondary'
            onClick={() => this._closeShareDialog()}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    return (
      <div>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeSelect={(event, nodeId) => {
            if (!nodeId.endsWith('/')) {
              this.setState({shareDialogIsOpen: true, currentNodeSelected: nodeId});
            }
          }}
          onNodeToggle={(event, nodeIds) => {
            Object.keys(this.props.multimediaFiles).forEach((courseCode) => {
              const courseObject = this.props.multimediaFiles[courseCode];
              if (courseObject.children) {
                this._searchAndUpdateTreeNodes(courseObject.children, nodeIds[0]);
              }
            });
          }}
        >
          {Object.keys(this.props.multimediaFiles).map((courseCode) => {
            return (
              <React.Fragment key={courseCode}>
                <TreeItem nodeId={this.props.multimediaFiles[courseCode].id} label={this.props.multimediaFiles[courseCode].name}>
                  {this._renderTreeItemChildren(this.props.multimediaFiles[courseCode].children || [])}
                </TreeItem>
              </React.Fragment>
            );
          })}
        </TreeView>
        {this.state.shareDialogIsOpen && this._renderShareDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    multimediaFiles: state.multimediaFiles,
    apiGetS3ShareLink: state.apiGetS3ShareLink,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    clearS3ShareLink,
    fetchMultimediaCloudDirectories,
    fetchMultimediaFiles,
    generateShareS3PreAuthLink,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractEditorSharing);
