/*
 * Ryan O'Dowd
 * 2020-04-29
 * © Copyright 2020 NursingABC, Inc.  All Rights Reserved.
 */

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
  },
  inputField: {
    display: 'none',
  },
  newCourseButton: {
    display: 'flex',
    justifyContent: 'right',
  },
};

export default styles;
