/*
 * Ryan O'Dowd
 * 2019-01-08
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import {
  AppBar,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  BrowserRouter,
  Link,
  Route,
  Switch,
} from 'react-router-dom';
import {
  FETCH_ERROR_OBJECT,
  FETCH_SUCCESS_OBJECT,
  fetchCoursesForDepartmentChair,
  fetchCurrentAdmin,
  removeFetch,
  updateFetch,
} from '../../actions';
import {
  Auth,
} from 'aws-amplify';
import CheckIcon from 'mdi-react/CheckIcon';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import ChristmasLights from '../ChristmasLights';
import CloseIcon from 'mdi-react/CloseIcon';
import Error404 from '../HiddenPages/Error404';
import Footer from '../Footer';
import Globals from '../../Globals';
import Header from './Header';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuIcon from '@material-ui/icons/Menu';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import commonStyles from '../../commonStyles';
import {
  connect,
} from 'react-redux';
import homeOptions from '../Home/homeOptions';
import styles from './styles';

class App extends React.Component {
  static propTypes = {
    admin: PropTypes.object.isRequired,
    authState: PropTypes.string.isRequired,
    departmentChairCourses: PropTypes.array.isRequired,
    fetchCoursesForDepartmentChair: PropTypes.func.isRequired,
    fetchCurrentAdmin: PropTypes.func.isRequired,
    fetches: PropTypes.object.isRequired,
    loadingStates: PropTypes.object.isRequired,
    removeFetch: PropTypes.func.isRequired,
    updateFetch: PropTypes.func.isRequired,
    // @TODO: logout: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      drawerIsOpen: false,
    };
  }

  componentDidMount() {
    this._saveJwt();
    if (this.props.admin && !this.props.admin.perms.developer) {
      this._removeFetches();
    }
    if (this.props.admin && this.props.admin.department === 'Faculty Dept Chair' && !this.props.departmentChairCourses.length) {
      this.props.fetchCoursesForDepartmentChair(this.props.admin.username);
    }
  }

  _removeFetches() {
    Object.values(this.props.fetches).forEach((fetchCall) => {
      this.props.removeFetch(fetchCall.requestId);
    });
  }

  _renderToast() {
    const fetchRequest = Object.values(this.props.fetches).find((fetchRequest) => !!fetchRequest.message && !fetchRequest.isRead);
    if (!fetchRequest) {
      return null;
    }

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      if (fetchRequest.status !== FETCH_ERROR_OBJECT.status) {
        this.props.removeFetch(fetchRequest.requestId);
      } else {
        this.props.updateFetch({...fetchRequest, isRead: true});
      }
    };

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={true}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <MuiAlert severity={fetchRequest.isSuccessfullFetch ? 'success' : 'error'} variant='standard'>
          <div style={styles.alertContainer}>
            <p id='message-id'>{fetchRequest.message}</p>
            <IconButton
              key='close'
              aria-label='close'
              color='inherit'
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </MuiAlert>
      </Snackbar>
    );
  }

  async _saveJwt() {
    try {
      const admin = await Auth.currentAuthenticatedUser(); // @TODO: confirm that this will return null or something if the jwt has expired
      localStorage.setItem('jwt', admin.signInUserSession.accessToken.jwtToken);
      this.props.fetchCurrentAdmin();
    } catch (e) {
      if (e !== 'not authenticated') {
        // if the admin hasn't logged in yet, don't log out because that will clear the entire redux store
        // @TODO: this.props.logout();
      }
    }
  }

  componentDidUpdate(prevProps) {
    // @NOTE: check prevProps.authState because if this is a page refresh during masquerading, we don't want to overwrite the masquerade with the actual admin
    if ((prevProps.authState !== 'loading' || !localStorage.getItem('jwt')) && this.props.authState !== prevProps.authState && this.props.authState === 'signedIn') {
      this._saveJwt();
    }

    if (!this.props.departmentChairCourses.length && prevProps.loadingStates.fetchCurrentAdmin !== this.props.loadingStates.fetchCurrentAdmin && this.props.admin && this.props.admin.department === Globals.userDepartments.facultyDepartmentChair) {
      this.props.fetchCoursesForDepartmentChair(this.props.admin.username);
    }
  }

  render() {
    if (this.props.authState !== 'signedIn' || !this.props.admin) {
      // @TODO: can this be handled in ../../index.js?
      return null;
    }

    return (
      <>
        <Header />
        <div style={styles.container}>
          <BrowserRouter>
            <div>
              <AppBar
                style={styles.appBar}
                position='fixed'
              >
                <Toolbar disableGutters={!this.state.drawerIsOpen}>
                  <IconButton
                    color='inherit'
                    aria-label='Open drawer'
                    onClick={() => this.setState({drawerIsOpen: true})}
                    style={{...styles.menuButton, ...(this.state.drawerIsOpen ? styles.hide : {})}}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography style={styles.appBarTitle} variant='h6' color='inherit'>
                    {/* @TODO: only underline on hover, and keep white */}
                    <Link style={styles.homeLink} to={'/'}>Admin Panel</Link>
                  </Typography>
                  <div style={styles.spacer}></div>
                  {
                    <>
                      <div style={this.props.admin.perms.developer ? styles.fetches : null}>
                        {!this.props.admin.perms.developer &&
                        Object.values(this.props.loadingStates).some((loadingState) => !!loadingState) && <CircularProgress color={Globals.colors.light} size={30}/>
                        }

                        {/* due to `direction: rtl`, the scrolling now starts from the right, so this array needs to be reversed to allow newest fetches to be the right-most items */}
                        {!!this.props.admin.perms.developer &&
                        Object.values(this.props.fetches).reverse().map((fetchCall) => {
                          let icon = <CircularProgress style={styles.fetchIcon} color='inherit' size={20} />;
                          if (fetchCall.status === FETCH_SUCCESS_OBJECT.status) {
                            icon = <CheckIcon style={styles.fetchIcon} color={Globals.colors.light} />;
                          } else if (fetchCall.status === FETCH_ERROR_OBJECT.status) {
                            icon = <CloseIcon style={styles.fetchIcon} color={Globals.colors.light} />;
                          }

                          return (
                            <Tooltip
                              key={fetchCall.requestId}
                              title={`${fetchCall.endpoint}${fetchCall.message ? `: ${fetchCall.message}` : ''}`}
                            >
                              <span>{icon}</span>
                            </Tooltip>
                          );
                        })}
                      </div>

                      {!!this.props.admin.perms.developer &&
                      <Button
                        color='inherit'
                        onClick={() => {
                          this._removeFetches();
                        }}
                      >
                        Clear
                      </Button>
                      }
                    </>
                  }
                </Toolbar>
              </AppBar>
              <Drawer
                style={styles.drawer}
                anchor='left'
                open={this.state.drawerIsOpen}
                onClose={() => this.setState({drawerIsOpen: false})}
              >
                <div style={styles.drawerHeader}>
                  <IconButton onClick={() => this.setState({drawerIsOpen: false})}>
                    {this.state.drawerIsOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                  </IconButton>
                </div>
                <List>
                  {homeOptions(this.props.admin.perms).filter((homeOption) => !homeOption.hideInSidebar).sort((a, b) => a.name.localeCompare(b.name)).map((homeOption) => {
                    let thisProps = {
                      component: Link,
                      to: homeOption.href.split('/:')[0],
                    };
                    if (homeOption.href.startsWith('https://')) {
                      thisProps = {
                        component: 'a',
                        href: homeOption.href,
                      };
                    }
                    return (
                      <React.Fragment key={homeOption.name}>
                        {homeOption.startOfNewSection && <Divider />}
                        <ListItem
                          button
                          onClick={() => this.setState({
                            drawerIsOpen: false,
                          })}
                          {...thisProps}
                        >
                          <ListItemIcon>{homeOption.icon}</ListItemIcon>
                          <ListItemText primary={homeOption.name} />
                        </ListItem>
                      </React.Fragment>
                    );
                  })}
                </List>
              </Drawer>
              {/* <ChristmasLights /> */}
              {this.state.drawerIsOpen && <div style={styles.overlay}></div>}
              <main style={styles.content}>
                <div style={styles.innerContainer}>
                  <div style={commonStyles.paddedContainer}>
                    <Switch>
                      {homeOptions(this.props.admin.perms).map((option) => {
                        return <Route key={option.name} exact={true} path={option.href} component={option.component} />;
                      })}
                      <Route component={Error404} />
                    </Switch>
                  </div>
                </div>
              </main>
            </div>
          </BrowserRouter>
          {this._renderToast()}
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.admin,
    departmentChairCourses: state.departmentChairCourses,
    fetches: state.fetches,
    loadingStates: state.loadingStates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchCoursesForDepartmentChair,
    fetchCurrentAdmin,
    removeFetch,
    updateFetch,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
