/*
 * Ally Zernick
 * 2022-08-08
 * © Copyright 2023 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Card,
  CardContent,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, {
  useEffect, useState,
} from 'react';
import {
  createPartnerSchool,
  fetchPartnerSchools,
  getAllCampuses,
  patchPartnerSchool,
} from '../../actions';
import {
  useDispatch, useSelector,
} from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import {
  DEVELOPER,
} from '../../Globals';
import DoneIcon from '@material-ui/icons/Done';
import {
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import provinces from 'provinces';
import styles from './styles';

const PartnerSchools = (props) => {
  const [_searchTerm, setSearchTerm] = useState('');
  const [_schoolName, setSchoolName] = useState('');
  const [_schoolNameLong, setSchoolNameLong] = useState('');
  const [_schoolURL, setSchoolURL] = useState('');
  const [_extraInformation, setExtraInformation] = useState();
  const [_fileToUpload, setFileToUpload] = useState(null);
  const [_uploadFileName, setUploadFileName] = useState(null);
  const [_backgroundColor, setBackgroundColor] = useState([]);
  const [_campusId, setCampusId] = useState(0);
  const [_program, setProgram] = useState('');
  const [_preReq, setPreReq] = useState('');
  const [_schoolState, setSchoolState] = useState('state');
  const [_edit, setEdit] = useState(false);
  const [_editCardId, setEditCardId] = useState(0);
  const [_editSchoolName, setEditSchoolName] = useState(null);
  const [_editSchoolNameLong, setEditSchoolNameLong] = useState(null);
  const [_editSchoolURL, setEditSchoolURL] = useState(null);
  const [_editExtraInformation, setEditExtraInformation] = useState(null);
  const [_editBackgroundColor, setEditBackgroundColor] = useState(null);
  const [_editProgram, setEditProgram] = useState(null);
  const [_editPreReq, setEditPreReq] = useState(null);
  const admin = useSelector((state) => state.admin);
  const allCampuses = useSelector((state) => state.allCampuses);
  const partnerSchools = useSelector((state) => state.fetchPartnerSchools);
  const states = provinces.filter((value) => value.country === 'US');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPartnerSchools());
    dispatch(getAllCampuses());
  }, [dispatch]);

  const _saveEdits = (id) => {
    dispatch(patchPartnerSchool(id, _editSchoolName, _editSchoolNameLong, _editSchoolURL,
      _editBackgroundColor, _uploadFileName, _fileToUpload, _editExtraInformation, _editProgram, _editPreReq,
      () => { dispatch(fetchPartnerSchools()); }));
    _cancelEdits();
  };

  const _cancelEdits = () => {
    setEdit(!_edit);
    setEditSchoolName(null);
    setEditSchoolNameLong(null);
    setEditSchoolURL(null);
    setEditExtraInformation(null);
    setEditBackgroundColor(null);
    setEditProgram(null);
    setEditPreReq(null);
    setUploadFileName(null);
    setFileToUpload(null);
  };

  const _renderPartners = () => {
    const filteredSchools = partnerSchools.filter((schools) => ((schools.school_name_large).toLowerCase().indexOf(_searchTerm.toLowerCase()) !== -1 || (schools.school_name).toLowerCase().indexOf(_searchTerm.toLowerCase()) !== -1) && (schools.state === _schoolState || _schoolState === 'state')).sort((a, b) => a.school_name.localeCompare(b.school_name));
    return (
      <div>
        {!Object.values(filteredSchools).length ? (_schoolState !== 'state' ? (<p>No Schools Found.</p>) : (<p>Loading</p>))
          : (<div style={{display: 'flex', flexWrap: 'wrap'}}>
            {filteredSchools.map((schoolData) => (
              <Card key={schoolData.id} style={styles.container}>
                <CardContent style={styles.cardContent}>
                  {_edit && (schoolData.id === _editCardId) ? (
                    <div>
                      {!!admin.perms[DEVELOPER] &&
                      <div style={{textAlign: 'left'}}>
                        <h4>School name:
                          <Input
                            style={styles.inputLabel}
                            value={_editSchoolName}
                            placeholder={schoolData.school_name}
                            onChange={(e) => setEditSchoolName(e.target.value)}
                          />
                        </h4>
                        <InputLabel>School name long:
                          <Input
                            style={styles.inputLabel}
                            value={_editSchoolNameLong}
                            placeholder={schoolData.school_name_large}
                            onChange={(e) => setEditSchoolNameLong(e.target.value)}
                          />
                        </InputLabel>
                        <InputLabel>Special information:
                          <Input
                            style={styles.inputLabel}
                            value={_editExtraInformation}
                            placeholder={schoolData.special_school_information}
                            onChange={(e) => setEditExtraInformation(e.target.value)}
                          />
                        </InputLabel>
                        <InputLabel>Route url:
                          <Input
                            style={styles.inputLabel}
                            value={_editSchoolURL}
                            placeholder={schoolData.slug}
                            onChange={(e) => setEditSchoolURL(e.target.value)}
                          />
                        </InputLabel>
                        <Autocomplete
                          disableClearable={true}
                          style={styles.inputLabel}
                          value={schoolData.background_color}
                          id='combo-box-demo'
                          options={[...new Set(partnerSchools.map((school) => school.background_color))]}
                          onChange={(e, color) => {
                            setEditBackgroundColor(color);
                          }}
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              label='Background Color'
                              onChange={(e) => {
                                setEditBackgroundColor(e.target.value);
                              }}
                            />}
                        />
                        {/* <InputLabel>Image name:
                          <Input
                            style={styles.inputLabel}
                            value={_uploadFileName}
                            placeholder={schoolData.img_url}
                            disabled={true}
                          />
                        </InputLabel>
                        <Button
                          style={{marginBottom: 5}}
                          variant='contained'
                          color='primary'
                          component='label'
                        >
                          Upload New Image
                          <input
                            type='file'
                            id='upload-button'
                            accept='application/png,image/*'
                            hidden={true}
                            onChange={(i) => {
                              const file = i.target.files[0];
                              setFileToUpload(file);
                              setUploadFileName(file.name);
                            }}
                          />
                        </Button> */}
                        <InputLabel>Program:
                          <Input
                            style={styles.inputLabel}
                            value={_editProgram}
                            placeholder={schoolData.program}
                            onChange={(e) => setEditProgram(e.target.value)}
                          />
                        </InputLabel>
                        <InputLabel>Pre req verbiage:
                          <Input
                            style={styles.inputLabel}
                            value={_editPreReq}
                            placeholder={schoolData.pre_req_verbiage}
                            onChange={(e) => setEditPreReq(e.target.value)}
                          />
                        </InputLabel>
                        <Button
                          align='right'
                          onClick={(() => _saveEdits(schoolData.id))}
                        >
                          <DoneIcon />
                        </Button>
                        <Button align='right' onClick={_cancelEdits}>
                          <CloseIcon />
                        </Button>
                      </div>
                      }
                    </div>
                  ) : (
                    <>
                      <h4>{schoolData.school_name}</h4>
                      <Link to={{pathname: `https://portagelearning.com/partners/${schoolData.slug}`}} target='_blank'>{schoolData.school_name_large}</Link>
                      {schoolData.special_school_information &&
                        <p><b>Special information:</b> {schoolData.special_school_information}</p>
                      }
                      {!!admin.perms[DEVELOPER] &&
                      <div style={styles.partnerCard}>
                        <p style={styles.partnerInfo}><b>Route url:</b> {schoolData.slug}</p>
                        <p style={styles.partnerInfo}><b>Background color:</b> {schoolData.background_color}</p>
                        {/* <p><b>Image name:</b> {schoolData.img_url}</p> */}
                        {schoolData.program !== null && <p style={styles.partnerInfo}><b>Program:</b> {schoolData.program}</p>}
                        <p style={styles.partnerInfo}><b>State:</b> {schoolData.state}</p>
                        <Button
                          align='right'
                          style={styles.editButton}
                          onClick={(() => {
                            setEdit(true);
                            setEditCardId(schoolData.id);
                          })}
                        >
                          <CreateIcon />
                        </Button>
                      </div>
                      }
                    </>
                  )}
                </CardContent>
              </Card>
            ))}
          </div>
          )
        }
      </div>
    );
  };

  return (
    <div>
      <h2>Partner Schools</h2>
      {/* {!!admin.perms[DEVELOPER] &&
        <div style={styles.lineSeparator}>
          <div>
            <h3>Create new partner school:</h3>
            <div>
              <TextField
                id='schoolName'
                style={styles.schoolName}
                value={_schoolName}
                onChange={(e) => setSchoolName(e.target.value)}
                label='*School Name'
                margin='dense'
              />
              <TextField
                id='schoolNameLong'
                style={styles.schoolNameLong}
                value={_schoolNameLong}
                onChange={(e) => setSchoolNameLong(e.target.value)}
                label='*School Name Long'
                margin='dense'
              />
              <TextField
                id='schoolURL'
                style={styles.url}
                value={_schoolURL}
                onChange={(e) => setSchoolURL(e.target.value)}
                label='*Route URL'
                margin='dense'
              />
            </div>
            <div>
              <TextField
                id='extraInformation'
                style={styles.specialInformation}
                value={_extraInformation}
                onChange={(e) => setExtraInformation(e.target.value)}
                label='Special School Information'
                margin='dense'
              />
              <TextField
                value={_uploadFileName}
                style={styles.imagePath}
                disabled={true}
              />
              <Button
                style={styles.buttonStyle}
                variant='contained'
                color='primary'
                component='label'
              >
                Upload School Image
                <input
                  type='file'
                  id='upload-button'
                  accept='application/png,image/*'
                  hidden={true}
                  onChange={(i) => {
                    const file = i.target.files[0];
                    setFileToUpload(file);
                    setUploadFileName(file.name);
                  }}
                />
              </Button>
            </div>
            {!Object.values(partnerSchools).length ? (<p>Loading</p>)
              : (<div style={{display: 'flex'}}>
                <Autocomplete
                  disableClearable={true}
                  style={styles.backgroundColorSelect}
                  value={_backgroundColor}
                  id='combo-box-demo'
                  options={[...new Set(partnerSchools.map((school) => school.background_color))]}
                  onChange={(e, color) => {
                    setBackgroundColor(color);
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label='Background Color'
                      onChange={(e) => {
                        setBackgroundColor(e.target.value);
                      }}
                    />}
                />
                {!Object.values(allCampuses).length ? (<p>Loading</p>)
                  : (
                    <Autocomplete
                      disableClearable={true}
                      style={styles.institutionSelect}
                      id='combo-box-demo'
                      options={allCampuses.map((a) => ({id: a.id, name: a.name}))}
                      getOptionLabel={(activeCampus) => activeCampus.name}
                      onChange={(e, campus) => {
                        setCampusId(campus.id);
                      }}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          label='Institution Campus'
                        />}
                    />
                  )}
                <TextField
                  id='program'
                  style={styles.program}
                  value={_program}
                  onChange={(e) => setProgram(e.target.value)}
                  label='Program'
                  margin='dense'
                />
                <TextField
                  id='preReq'
                  style={styles.program}
                  value={_preReq}
                  onChange={(e) => setPreReq(e.target.value)}
                  label='Pre Req Verbiage'
                  margin='dense'
                />
                <Button
                  style={styles.buttonStyle}
                  variant='contained'
                  color='primary'
                  disabled={(_campusId === 0 || _schoolURL === '/' || _schoolName === '' ||
                    _schoolNameLong === '' || _backgroundColor === '' || _uploadFileName === '' || _fileToUpload === null)}
                  onClick={() => {
                    dispatch(createPartnerSchool(_campusId, _schoolURL, _schoolName, _schoolNameLong,
                      _backgroundColor, _extraInformation, _uploadFileName, _fileToUpload, _program, _preReq,
                      () => { dispatch(fetchPartnerSchools()); }));
                  }}
                >
                  Create Partner School
                </Button>
              </div>
              )}
          </div>
        </div>
      } */}
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h3>Search partners:</h3>
        <TextField
          id='schoolSearch'
          style={styles.searchBox}
          variant='outlined'
          value={_searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          label='Search'
          margin='dense'
        />
        <Select
          variant='outlined'
          onChange={(e) => setSchoolState(e.target.value)}
          value={_schoolState}
          style={{height: '40px', minWidth: '200px'}}
        >
          <MenuItem value='state'>All States</MenuItem>
          {states.map((s) => {
            return (
              <MenuItem key={s.short} value={s.short}>{s.name}</MenuItem>
            );
          })}
        </Select>
      </div>
      {partnerSchools.length > 0 &&
      <>
        {_renderPartners()}
      </>
      }
    </div>
  );
};

PartnerSchools.propTypes = {
  admin: PropTypes.object,
  createPartnerSchool: PropTypes.func,
  getAllCampuses: PropTypes.func,
  fetchPartnerSchools: PropTypes.func,
  patchPartnerSchools: PropTypes.func,
};

export default (PartnerSchools);
