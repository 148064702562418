/*
 * Ryan O'Dowd
 * 2021-02-22
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
};

export default styles;
