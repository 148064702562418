/*
 * Ryan O'Dowd
 * 2019-01-09
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  sectionHeader: {
    marginTop: 32,
    textTransform: 'capitalize',
    paddingBottom: 4,
    borderBottom: `1px solid ${Globals.colors.primary}44`,
    color: Globals.colors.primary,
  },
};

export default styles;
