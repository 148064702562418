/*
 * Ryan O'Dowd
 * 2019-01-15
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
  },
  button: {
    margin: 8,
  },
};

export default styles;
