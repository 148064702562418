/*
 * Nathaniel Padgett
 * 2021-02-10
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Checkbox,
  FormControlLabel,
  Input,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {
  DEVELOPER,
} from '../../../Globals';
import PropTypes from 'prop-types';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import {
  manuallyEnrollStudent,
} from '../../../actions';
import styles from './styles';

const SELECT_INITIAL_VALUE = 'none';

const ManualEnrollment = (props) => {
  const [unpaidNoteError, setUnpaidNoteError] = React.useState(true);
  const [courseAsUnpaid, setCourseAsUnpaid] = React.useState(false);
  const [addCourseSelectionId, setAddCourseSelectionId] = React.useState(null);
  const [displayUnpaidNotes, setDisplayUnpaidNotes] = React.useState(false);
  const [suppressWelcomeEmail, setSuppressWelcomeEmail] = React.useState(false);
  const [unpaidNoteValue, setUnPaidNoteValue] = React.useState('');
  const [assignInstructorManually, setAssignInstructorManually] = React.useState(false);

  return (
    <div>
      <div style={styles.addCourseWrapper}>
        <Select
          style={styles.addCourseSelectField}
          value={addCourseSelectionId}
          onChange={(e) => setAddCourseSelectionId(e.target.value)}
          input={<Input id='course' name='course' />}
        >
          {[
            <MenuItem key='placeholder' value={SELECT_INITIAL_VALUE} disabled={true}>Course*</MenuItem>,
            props.coursesOffered.map((course) => <MenuItem key={course.id} value={course.id}>{`${course.code}: ${course.title}`}</MenuItem>),
          ]}
        </Select>
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            if (!courseAsUnpaid || !unpaidNoteError) {
              props.manuallyEnrollStudent(props.studentId, addCourseSelectionId, suppressWelcomeEmail, courseAsUnpaid, unpaidNoteValue, assignInstructorManually);
              props.onClose();
            }
          }}
          disabled={!addCourseSelectionId}
          variant='contained'
          color='secondary'
        >
          Enroll
        </Button>
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            props.onClose();
          }}
          color='primary'
        >
          Cancel
        </Button>
      </div>
      <div>
        {!!props.admin.perms[DEVELOPER] &&
          <FormControlLabel
            control={
              <Checkbox
                checked={suppressWelcomeEmail}
                onChange={() => {
                  setSuppressWelcomeEmail(!suppressWelcomeEmail);
                }}
                name='suppressWelcomeEmail'
                color='primary'
              />
            }
            label='Suppress welcome email to student'
          />
        }
        <FormControlLabel
          control={
            <Checkbox
              checked={assignInstructorManually}
              onChange={() => {
                setAssignInstructorManually(!assignInstructorManually);
              }}
              name='assignInstructorManually'
              color='primary'
            />
          }
          label="Don't automatically assign instructor"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={courseAsUnpaid}
              onChange={(event) => {
                setCourseAsUnpaid(event.target.checked);
                setDisplayUnpaidNotes(event.target.checked);
              }}
              name='setCourseAsNotPaid'
              color='primary'
            />
          }
          label='Set course as not paid'
        />
        {displayUnpaidNotes &&
          <TextField
            style={styles.textField}
            label='Unpaid Note'
            error={unpaidNoteError}
            placeholder='Add note to course enrollment'
            value={unpaidNoteValue}
            onChange={(e) => {
              setUnPaidNoteValue(e.target.value);
              setUnpaidNoteError(e.target.value.length === 0);
            }}
            margin='dense'
            required={true}
          />
        }
      </div>

    </div>
  );
};

ManualEnrollment.propTypes = {
  admin: PropTypes.object.isRequired,
  coursesOffered: PropTypes.array.isRequired,
  manuallyEnrollStudent: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  studentId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    admin: state.admin,
    coursesOffered: Object.values(state.courses).filter((course) => !course.disabled).sort((a, b) => (a.code).localeCompare(b.code)),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    manuallyEnrollStudent,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualEnrollment);
