/*
 * Ryan O'Dowd
 * 2021-02-22
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  fetchVideoCaptions,
  finalizeVideoCaptions,
  generateLinksWithTimes,
} from '../../actions';
import CheckIcon from 'mdi-react/CheckIcon';
import Globals from '../../Globals';
import LinkIcon from 'mdi-react/LinkIcon';
import PropTypes from 'prop-types';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import styles from './styles';

class VideoCaptioning extends React.Component {
  static propTypes = {
    fetchVideoCaptions: PropTypes.func.isRequired,
    finalizeVideoCaptions: PropTypes.func.isRequired,
    generateLinksWithTimes: PropTypes.func.isRequired,
    loadingStates: PropTypes.object.isRequired,
    videoCaptions: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      courseCodeOfOpenLinks: null,
    };
  }

  componentDidMount() {
    this.props.fetchVideoCaptions();
  }

  _humanReadableTime(seconds) {
    return `${Math.floor(seconds / 60 / 60)}:${(Math.floor(seconds / 60) % 60).toString().padStart(2, '0')}:${(Math.ceil(seconds) % 60).toString().padStart(2, '0')}`;
  }

  _renderLinksDialog(totalSeconds) {
    return (
      <Dialog
        open={true}
        onClose={() => this.setState({courseCodeOfOpenLinks: null})}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{`${this.state.courseCodeOfOpenLinks.toUpperCase()} expiring links`}</DialogTitle>
        <DialogContent>
          {!this.props.videoCaptions[this.state.courseCodeOfOpenLinks].links
            ? (
              <p>Loading...</p>
            ) : (
              <Table aria-labelledby='tableTitle'>
                {/* @TODO: make columns sortable? */}
                <TableHead>
                  <TableRow>
                    <TableCell>Expiring link (30 days)</TableCell>
                    <TableCell>Duration</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.videoCaptions[this.state.courseCodeOfOpenLinks].links.map((link) => {
                    return (
                      <TableRow
                        key={link.url}
                        hover={true}
                        tabIndex={-1}
                      >
                        <TableCell>
                          {link.url}
                        </TableCell>
                        <TableCell>
                          {this._humanReadableTime(link.duration)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell>
                      <strong>Total time:</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{this._humanReadableTime(totalSeconds)}</strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button
            color='secondary'
            onClick={() => this.setState({courseCodeOfOpenLinks: null})}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    let totalSeconds = 0;
    if (this.props.videoCaptions[this.state.courseCodeOfOpenLinks] && this.props.videoCaptions[this.state.courseCodeOfOpenLinks].links) {
      totalSeconds = this.props.videoCaptions[this.state.courseCodeOfOpenLinks].links.map((link) => link.duration).reduce((acc, curr) => acc + curr, 0);
    }

    return (
      <div style={styles.container}>
        <h3>Video Captioning</h3>
        <div>
          <p>Below are all of the folders in 3Play.</p>
          <p>{`"pending" means that they've been organized in 3Play (usually this means they've been sent as well, but that's up to the person who put them in the folder).  When 3Play has finished captioning, you can click the "Generate links" icon to get a list of all videos to review.  These links are good for 30 days and can be sent to instructors.`}</p>
          <p>{`After that icon is clicked, the status will change to "in_review".  (This may require a page refresh to see.)  When all of the videos have been edited/approved by the instructor(s), you can click the "FINALIZE" button.  This will save backups and change the status to "complete".`}</p>
          <p>The final step is to log in to <a href='https://3playmedia.com'>3Play</a> and publish all videos with captions back to Vimeo.</p>
          <p>Please note: Folders in 3Play should be named with the course code (all lowercase, no spaces).</p>
          <p>Important: Please be sure that all files have been returned by 3Play before generating links.  <strong>Videos with missing captions will be skipped.</strong></p>
          <p>{"Please let Engineering know when you create a new folder--folders can't be deleted, so we can clean up the existing archived folders when there are more courses to caption."}</p>
        </div>
        <Paper style={styles.tableWrapper}>{/* @TODO: add padding and stuff */}
          <Table aria-labelledby='tableTitle' size='small'>
            {/* @TODO: make columns sortable? */}
            <TableHead>
              <TableRow>
                <TableCell>Course</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Generate links</TableCell>
                <TableCell>Finalize</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(this.props.videoCaptions).sort().map((courseCode) => {
                return (
                  <TableRow
                    key={courseCode}
                    hover={true}
                  >
                    <TableCell>
                      {courseCode}
                    </TableCell>
                    <TableCell>
                      {this.props.videoCaptions[courseCode].status}
                    </TableCell>
                    <TableCell>
                      {this.props.videoCaptions[courseCode].status !== 'complete' &&
                        <IconButton
                          color='secondary'
                          onClick={() => {
                            this.props.generateLinksWithTimes(courseCode);
                            this.setState({courseCodeOfOpenLinks: courseCode});
                          }}
                        >
                          <LinkIcon />
                        </IconButton>
                      }
                    </TableCell>
                    <TableCell>
                      {this.props.videoCaptions[courseCode].status === 'in_review'
                        ? (
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={() => this.props.finalizeVideoCaptions(courseCode)}
                          >
                            {/* @TODO: show loading indicator instead of button text during api call */}
                            Finalize
                          </Button>
                        ) : (
                          <CheckIcon color={Globals.colors.accent} />
                        )
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>

        {this.state.courseCodeOfOpenLinks && this._renderLinksDialog(totalSeconds)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    videoCaptions: state.videoCaptions,
    loadingStates: state.loadingStates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchVideoCaptions,
    finalizeVideoCaptions,
    generateLinksWithTimes,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoCaptioning);
