/*
 * Ryan O'Dowd
 * 2018-11-15
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import './global.css';
import {
  ForgotPassword,
  RequireNewPassword,
  SignIn,
} from './components/Authentication';
import {
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import Amplify from 'aws-amplify';
import App from './components/App';
import {
  Authenticator,
} from 'aws-amplify-react';
import Globals from './Globals';
import {
  PersistGate,
} from 'redux-persist/integration/react';
import {
  Provider,
} from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import config from './config';
import configureStore from './Store';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
});

const THEME = createMuiTheme({
  palette: {
    type: 'light',
    primary: Globals.themePrimary,
    secondary: Globals.themeAccent,
  },
  typography: {
    useNextVariants: true,
  },
});

const reduxStuff = configureStore();
const rootJsx = (
  <Provider store={reduxStuff.store}>
    <PersistGate loading={null} persistor={reduxStuff.persistor}>
      <MuiThemeProvider theme={THEME}>
        <Authenticator hideDefault={true}>
          <ForgotPassword />
          <RequireNewPassword />
          <SignIn />
          <App />
        </Authenticator>
      </MuiThemeProvider>
    </PersistGate>
  </Provider>
);

ReactDOM.render(rootJsx, document.getElementById('root'));
