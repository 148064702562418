/*
 * Ryan O'Dowd
 * 2019-01-09
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
    fontSize: 14,
  },
  cardWrapper: {
  },
  knownSchoolsList: {
    columns: 5,
  },
  schoolName: {
    display: 'flex',
    marginBottom: 2,
    fontSize: 20,
    whiteSpace: 'pre-wrap',
  },
  schoolCityAndState: {
    display: 'flex',
    marginBottom: 4,
    fontSize: 14,
    whiteSpace: 'pre-wrap',
  },
  studentLinks: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 18,
  },
  studentLink: {
    fontSize: 10,
    marginRight: 4,
  },
  chip: {
    width: '100%',
    margin: 2,
    marginBottom: 4,
    color: Globals.colors.light,
    borderColor: Globals.colors.light,
    backgroundColor: Globals.colors.dark,
  },
  chipTitle: {
    display: 'block',
    fontSize: 12,
    lineHeight: '12px',
  },
  jwScore: {
    color: Globals.colors.darkGray,
  },
  dialogContent: {
    height: 400, // @TODO: good value?
    maxHeight: '80vh', // @TODO: good value?
  },
  textField: {
    flexGrow: 1,
    paddingRight: 5,
  },

  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
    // @TODO: cursor color
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: 4,
  },
  singleValue: {
    fontSize: 16,
    backgroundColor: 'plum',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: 4,
    left: 0,
    right: 0,
  },
  divider: {
    height: 8,
  },
  formRow: {
    display: 'flex',
    alignItems: 'baseline',
  },
  stateSelectField: {
    width: 60,
  },
  maxWidth: {
    maxWidth: 400,
  },
};

export default styles;
