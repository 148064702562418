/*
 * Ryan O'Dowd
 * 2019-11-21
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import './styles.css';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  clearApiMergeStudentFlag,
  fetchStudent,
  fetchStudentCourses,
  mergeStudents,
  updateStudent,
} from '../../actions';
import Alert from '@material-ui/lab/Alert';
import Details from '../StudentManagement/Details';
import PropTypes from 'prop-types';
import React from 'react';
import RefreshIcon from 'mdi-react/RefreshIcon';
import SwapHorizontalIcon from 'mdi-react/SwapHorizontalIcon';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import {
  getFormattedPhone,
} from '../../utilities';
import moment from 'moment';
import queryString from 'query-string';
import styles from './styles';
import {
  withRouter,
} from 'react-router-dom';

const DISABLED_NOTES = 'Temporarily disabled while Student Services investigates account.';

class MergeStudents extends React.Component {
  static propTypes = {
    admin: PropTypes.object.isRequired,
    apiMergeStudent: PropTypes.shape({
      merged: PropTypes.bool,
    }).isRequired,
    clearApiMergeStudentFlag: PropTypes.func.isRequired,
    fetchStudent: PropTypes.func.isRequired,
    fetchStudentCourses: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    mergeStudents: PropTypes.func.isRequired,
    students: PropTypes.object.isRequired,
    studentCourses: PropTypes.object.isRequired,
    updateStudent: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      sourceStudentIdOrEmailText: '',
      destinationStudentIdOrEmailText: '',
      sourceStudentId: null,
      destinationStudentId: null,
      keepSourceAddress: true,
      keepSourceBirthday: true,
      keepSourceEmail: true,
      keepSourceName: true,
      keepSourcePhone: true,
      disabledStudentIds: [],
    };
  }

  componentDidMount() {
    const sourceStudentIdOrEmailText = queryString.parse(this.props.location.search, {ignoreQueryPrefix: true}).initial_source_student_id;
    const destinationStudentIdOrEmailText = queryString.parse(this.props.location.search, {ignoreQueryPrefix: true}).initial_destination_student_id;
    this.setState({
      sourceStudentIdOrEmailText,
      destinationStudentIdOrEmailText,
    });

    if (sourceStudentIdOrEmailText) {
      this.props.fetchStudent(sourceStudentIdOrEmailText);
    }
    if (destinationStudentIdOrEmailText) {
      this.props.fetchStudent(destinationStudentIdOrEmailText);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.students !== prevProps.students) {
      const sourceStudent = this._getStudent(this.props.students, this.state.sourceStudentIdOrEmailText);
      const destinationStudent = this._getStudent(this.props.students, this.state.destinationStudentIdOrEmailText);
      if (sourceStudent) {
        this.props.fetchStudentCourses(sourceStudent.id);
      }
      if (destinationStudent) {
        this.props.fetchStudentCourses(destinationStudent.id);
      }
      this.setState({
        sourceStudentId: sourceStudent ? sourceStudent.id : null,
        destinationStudentId: destinationStudent ? destinationStudent.id : null,
      });
    }

    if (this.props.apiMergeStudent.merged) {
      this.setState({
        sourceStudentIdOrEmailText: '',
        destinationStudentIdOrEmailText: '',
        sourceStudentId: null,
        destinationStudentId: null,
      });
      this.props.clearApiMergeStudentFlag();
    }
  }

  _getStudent(students, idOrEmail) {
    return this.props.students[idOrEmail] || Object.values(students).find((u) => u.email === idOrEmail);
  }

  _renderField(label, content, action) {
    return (
      <div style={styles.formFieldWrapper}>
        <h6 style={styles.formFieldLabel}>{label}</h6>
        <div style={styles.formFieldContent}>{content} {action}</div>
      </div>
    );
  }

  _getFormattedAddress(student) {
    const addressLine2 = student.line_2 ? `${student.line_2}\n` : '';
    const country = student.country ? `, ${student.country}` : '';

    return `${student.line_1}\n${addressLine2}${student.city}, ${student.state} ${student.zip_code}${country}`;
  }

  _getFormattedName(student) {
    const suffix = student.suffix ? ` ${student.suffix}` : '';
    const middleName = student.middle_name ? ` ${student.middle_name} ` : ' ';

    return `${student.first_name}${middleName}${student.last_name}${suffix}`;
  }

  _getFormattedBirthday(student) {
    return moment.utc(student.birthday).format('LL');
  }

  _renderStudent(student, isSourceStudent) {
    const thisInputStateKey = isSourceStudent ? 'sourceStudentIdOrEmailText' : 'destinationStudentIdOrEmailText';

    const sourceStudent = this.props.students[this.state.sourceStudentId];
    const destinationStudent = this.props.students[this.state.destinationStudentId];

    let nameClass = '';
    let emailClass = '';
    let phoneClass = '';
    let birthdayClass = '';
    let addressClass = '';
    if (student && sourceStudent && destinationStudent) {
      nameClass = !(isSourceStudent ^ this.state.keepSourceName) ? 'selected' : '';
      if (this._getFormattedName(sourceStudent) === this._getFormattedName(destinationStudent)) {
        nameClass = `${nameClass} sameValue`;
      }
      emailClass = !(isSourceStudent ^ this.state.keepSourceEmail) ? 'selected' : '';
      if (sourceStudent.email === destinationStudent.email) {
        emailClass = `${emailClass} sameValue`;
      }
      phoneClass = !(isSourceStudent ^ this.state.keepSourcePhone) ? 'selected' : '';
      if (getFormattedPhone(sourceStudent.phone) === getFormattedPhone(destinationStudent.phone)) {
        phoneClass = `${phoneClass} sameValue`;
      }
      birthdayClass = !(isSourceStudent ^ this.state.keepSourceBirthday) ? 'selected' : '';
      if (this._getFormattedBirthday(sourceStudent) === this._getFormattedBirthday(destinationStudent)) {
        birthdayClass = `${birthdayClass} sameValue`;
      }
      addressClass = !(isSourceStudent ^ this.state.keepSourceAddress) ? 'selected' : '';
      if (this._getFormattedAddress(sourceStudent) === this._getFormattedAddress(destinationStudent)) {
        addressClass = `${addressClass} sameValue`;
      }
    }

    return (
      <>
        <div style={styles.textFieldWrapper}>
          <TextField
            style={styles.textField}
            label={`${isSourceStudent ? 'Source' : 'Destination'} student ID or email`}
            value={this.state[thisInputStateKey]}
            onChange={(e) => this.setState({[thisInputStateKey]: e.target.value})}
            margin='dense'
            required={true}
          />
          <IconButton
            color='secondary'
            disabled={(isSourceStudent && !this.state.sourceStudentIdOrEmailText) || (!isSourceStudent && !this.state.destinationStudentIdOrEmailText)}
            onClick={() => {
              this.props.fetchStudent(this.state[thisInputStateKey]);
            }}
          >
            <RefreshIcon size={18} />
          </IconButton>
        </div>
        {student &&
          <>
            <div style={styles.row}>
              <TextField
                style={styles.nonSelectableTextField}
                label='ID'
                value={student.id}
                margin='dense'
                disabled={true}
                variant='outlined'
              />
              <TextField
                style={styles.nonSelectableTextField}
                label='Account creation date'
                value={moment(student.created_at).format('LLL')}
                margin='dense'
                disabled={true}
                variant='outlined'
              />
            </div>
            <div
              className={nameClass}
              onClick={() => this.setState({keepSourceName: isSourceStudent})}
            >
              <TextField
                style={styles.selectableTextField}
                label='Name'
                value={this._getFormattedName(student)}
                margin='dense'
                disabled={true}
                variant='outlined'
                color='secondary'
              />
            </div>
            <div
              className={emailClass}
              onClick={() => this.setState({keepSourceEmail: isSourceStudent})}
            >
              <TextField
                style={styles.selectableTextField}
                label='Email'
                value={student.email}
                margin='dense'
                disabled={true}
                variant='outlined'
                color='secondary'
              />
            </div>
            <div
              className={phoneClass}
              onClick={() => this.setState({keepSourcePhone: isSourceStudent})}
            >
              <TextField
                style={styles.selectableTextField}
                label='Phone number'
                value={getFormattedPhone(student.phone)}
                margin='dense'
                disabled={true}
                variant='outlined'
                color='secondary'
              />
            </div>
            <div
              className={birthdayClass}
              onClick={() => this.setState({keepSourceBirthday: isSourceStudent})}
            >
              <TextField
                style={styles.selectableTextField}
                label='Birthday'
                value={this._getFormattedBirthday(student)}
                margin='dense'
                disabled={true}
                variant='outlined'
                color='secondary'
              />
            </div>
            <div
              className={addressClass}
              onClick={() => this.setState({keepSourceAddress: isSourceStudent})}
            >
              <TextField
                style={styles.selectableTextField}
                label='Address'
                multiline={true}
                value={this._getFormattedAddress(student)}
                margin='dense'
                disabled={true}
                variant='outlined'
                color='secondary'
              />
            </div>
            {this.state.disabledStudentIds.includes(student.id)
              ? <p>{`${DISABLED_NOTES} Please be sure to have Engineering re-enable the account when this has been resolved.`}</p>
              : (student.disabled
                ? (
                  <p>User disabled; will not be able to sign in.</p>
                ) : (
                  <Tooltip title='Temporarily prevent student from signing in to provide more time to investigate the merged accounts.  Especially useful for cases where the new account has registered for a course that is currently active in the older account.'>
                    <Button
                      variant='outlined'
                      color='primary'
                      disabled={!this.state.sourceStudentId || !this.state.destinationStudentId}
                      onClick={() => {
                        this.props.updateStudent({
                          id: student.id,
                          disabled: 1,
                          notes: DISABLED_NOTES,
                        });
                        this.setState((currState) => {
                          return {disabledStudentIds: [...currState.disabledStudentIds, student.id]};
                        });
                      }}
                    >
                      Disable sign in
                    </Button>
                  </Tooltip>
                )
              )
            }
            <div style={styles.detailsWrapper}>
              <Details
                studentId={student.id}
                studentCourses={this.props.studentCourses}
                renderCoursesOnly={true}
              />
            </div>
          </>
        }
      </>
    );
  }

  render() {
    const sourceStudent = this.props.students[this.state.sourceStudentId];
    const destinationStudent = this.props.students[this.state.destinationStudentId];
    const sourceIdSmallerThanDestinationId = +this.state.sourceStudentId < +this.state.destinationStudentId;

    return (
      <div style={styles.container}>
        <h3>Merge Students</h3>
        <Paper style={styles.innerContainer}>
          <Grid container={true} style={styles.mergeWrapper}>
            <Grid item style={styles.studentWrapper} xs={12} sm={5}>
              {this._renderStudent(sourceStudent, true)}
            </Grid>
            <Grid item style={styles.studentWrapper} xs={12} sm={2}>
              <div style={styles.swapWrapper}>
                <IconButton
                  color='secondary'
                  disabled={!this.state.sourceStudentIdOrEmailText && !this.state.destinationStudentIdOrEmailText}
                  onClick={() => {
                    const [newDestinationId, newSourceId] = [this.state.sourceStudentId, this.state.destinationStudentId];
                    const [newDestinationText, newSourceText] = [this.state.sourceStudentIdOrEmailText, this.state.destinationStudentIdOrEmailText];
                    // switch highlighted fields
                    const selectedFields = {};
                    Object.entries(this.state).forEach(([key, value]) => {
                      if (key.startsWith('keepSource')) {
                        selectedFields[key] = !value;
                      }
                    });
                    this.setState({
                      sourceStudentId: newSourceId,
                      sourceStudentIdOrEmailText: newSourceText,
                      destinationStudentId: newDestinationId,
                      destinationStudentIdOrEmailText: newDestinationText,
                      ...selectedFields,
                    });
                  }}
                >
                  <SwapHorizontalIcon size={32} />
                </IconButton>
              </div>
            </Grid>
            <Grid item style={styles.studentWrapper} xs={12} sm={5}>
              {this._renderStudent(destinationStudent, false)}
            </Grid>
          </Grid>
          <Button
            variant='contained'
            color='primary'
            disabled={!this.state.sourceStudentId || !this.state.destinationStudentId}
            onClick={() => this.setState({confirmMergeDialogOpen: true})}
          >
            Merge Students
          </Button>
          {this.state.confirmMergeDialogOpen &&
            <Dialog
              open={true}
              onClose={() => this.setState({confirmMergeDialogOpen: false})}
            >
              <DialogTitle id='alert-dialog-title'>Confirm merge</DialogTitle>
              <DialogContent>
                <p>Are you sure you want to merge these two students?  This will result in the following student:</p>
                {sourceIdSmallerThanDestinationId &&
                <Alert severity='warning'>You are about to merge an older ID into a newer ID. This is not recommended as Geneva will likely already have the
                older ID in their system. If you would like to continue to merge these accounts, click the merge anyways button. If you would like to change the ID, click the cancel button and switch the IDs.
                </Alert>
                }

                <div style={styles.confirmTable}>
                  <TextField
                    label='ID'
                    value={destinationStudent.id}
                    margin='dense'
                    disabled={true}
                    variant='outlined'
                  />

                  <TextField
                    label='Name'
                    value={this.state.keepSourceName
                      ? this._getFormattedName(sourceStudent)
                      : this._getFormattedName(destinationStudent)
                    }
                    margin='dense'
                    disabled={true}
                    variant='outlined'
                  />

                  <TextField
                    label='Email'
                    value={this.state.keepSourceEmail
                      ? sourceStudent.email
                      : destinationStudent.email
                    }
                    margin='dense'
                    disabled={true}
                    variant='outlined'
                  />

                  <TextField
                    label='Phone number'
                    value={this.state.keepSourcePhone
                      ? getFormattedPhone(sourceStudent.phone)
                      : getFormattedPhone(destinationStudent.phone)
                    }
                    margin='dense'
                    disabled={true}
                    variant='outlined'
                  />

                  <TextField
                    label='Birthday'
                    value={this.state.keepSourceBirthday
                      ? this._getFormattedBirthday(sourceStudent)
                      : this._getFormattedBirthday(destinationStudent)
                    }
                    margin='dense'
                    disabled={true}
                    variant='outlined'
                  />

                  <TextField
                    label='Address'
                    value={this.state.keepSourceAddress
                      ? this._getFormattedAddress(sourceStudent)
                      : this._getFormattedAddress(destinationStudent)
                    }
                    multiline={true}
                    margin='dense'
                    disabled={true}
                    variant='outlined'
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({confirmMergeDialogOpen: false})} color='secondary'>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    const sourceFieldsToKeep = [];
                    Object.entries(this.state).forEach(([key, value]) => {
                      if (key.startsWith('keepSource') && value) {
                        sourceFieldsToKeep.push(key.replace('keepSource', '').toLowerCase());
                      }
                    });
                    this.props.mergeStudents(this.state.sourceStudentId, this.state.destinationStudentId, sourceFieldsToKeep);
                    this.setState({confirmMergeDialogOpen: false});
                  }}
                  color='secondary'
                  variant='contained'
                >
                  {sourceIdSmallerThanDestinationId ? 'Merge anyways' : 'Merge'}
                </Button>
              </DialogActions>
            </Dialog>
          }
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.admin,
    apiMergeStudent: state.apiMergeStudent,
    students: state.students,
    studentCourses: state.studentCourses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    clearApiMergeStudentFlag,
    fetchStudent,
    fetchStudentCourses,
    mergeStudents,
    updateStudent,
  }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MergeStudents));
