/*
 * Ryan O'Dowd
 * 2019-01-21
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import React from 'react';
import moment from 'moment';
import styles from './styles';

const Footer = () => {
  return (
    <div style={styles.container}>
      <div style={styles.innerLeft} />

      <div style={styles.innerRight}>
        <p>
          {`Copyright © ${moment().year()} NursingABC, Inc. All rights reserved.`}
        </p>
      </div>
    </div>
  );
};

export default Footer;
