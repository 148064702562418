/*
 * Ryan O'Dowd
 * 2019-01-08
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  container: {
    textAlign: 'left',
    margin: 'auto',
    height: 160,
    cursor: 'pointer',
  },
  alphaFeature: {
    backgroundColor: Globals.colors.alphaFeature,
  },
  cardContent: {
    height: 'calc(100% - 40px)', // subtract padding
    overflowY: 'auto',
  },
  name: {
    display: 'flex',
    margin: 0,
  },
  description: {
    marginBottom: 0,
  },
};

export default styles;
