/*
 * Ryan O'Dowd
 * 2020-10-06
 * © Copyright 2020 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Globals, {
  APPLY_DISCOUNT,
  DEVELOPER,
  DISABLE_STUDENT,
  EDIT_STUDENT_INFO,
  FINANCE,
  GRADES,
  MANUALLY_ASSIGN_INSTRUCTOR,
  PAID_IN_FULL,
  READ_STUDENT_INFO,
  RESTORE_ENROLLMENT,
  UNWITHDRAW_ENROLLMENT,
} from '../../../Globals';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  applyDiscountCodeToEnrollment,
  backfillGrades,
  clearCanvasStudentEnrollmentUpdateFlag,
  clearNABCCanvasCoursePoints,
  closeForceConcludeDialog,
  extendStudentQuizTime,
  fetchAssignableCourseInstructors,
  fetchCognitoStudentData,
  fetchCourses,
  fetchGrades,
  fetchNABCCanvasCoursePoints,
  fetchPreviousInfoForStudent,
  fetchStudentCourses,
  fetchUnassignedStudentIds,
  fetchWithdrawReasons,
  forceConcludeStudentCourse,
  manuallyAssignInstructor,
  updateEnrollments,
  updateStudent,
  updateTransactionEnrollment,
} from '../../../actions';
import CameraEnhanceOutlined from '@mui/icons-material/CameraEnhanceOutlined';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from '@material-ui/icons/Close';
import CourseSyllabus from './CourseSyllabus';
import CreateDiscountDialog from '../../DiscountCodes/CreateDiscountDialog';
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';
import EditIcon from '@material-ui/icons/Edit';
import EditStudentDialog from '../EditStudentDialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FlagIcon from '@material-ui/icons/Flag';
import Grades from '../Grades';
import HasViolation from '../../HasViolation';
import ManualEnrollment from '../ManualEnrollment';
import MomentUtils from '@date-io/moment';
import MuiAlert from '@material-ui/lab/Alert';
import PencilIcon from 'mdi-react/PencilIcon';
import PropTypes from 'prop-types';
import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import StudentInformationDetails from './StudentInformationDetails';
import TranscriptHistory from '../TranscriptHistory';
import UndoChangeInRegistrationDialog from './UndoChangeInRegistrationDialog';
import WithdrawDialog from './WithdrawDialog';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import {
  formatNumberAsUScurrency,
} from '../../../utilities';
import moment from 'moment';
import styles from './styles';

class Details extends React.Component {
  static propTypes = {
    admin: PropTypes.object.isRequired,
    applyDiscountCodeToEnrollment: PropTypes.func.isRequired,
    backfillGrades: PropTypes.func.isRequired,
    assignableCourseInstructors: PropTypes.object.isRequired,
    clearCanvasStudentEnrollmentUpdateFlag: PropTypes.func.isRequired,
    clearNABCCanvasCoursePoints: PropTypes.func.isRequired,
    closeForceConcludeDialog: PropTypes.func.isRequired,
    courses: PropTypes.object.isRequired,
    coursesOffered: PropTypes.array.isRequired,
    departmentChairCourses: PropTypes.array.isRequired,
    extendStudentQuizTime: PropTypes.func.isRequired,
    fetchAssignableCourseInstructors: PropTypes.func.isRequired,
    fetchCognitoStudentData: PropTypes.func.isRequired,
    fetchCourses: PropTypes.func.isRequired,
    fetchGrades: PropTypes.func.isRequired,
    fetchNABCCanvasCoursePoints: PropTypes.func.isRequired,
    fetchUnassignedStudentIds: PropTypes.func.isRequired,
    fetchStudentCourses: PropTypes.func.isRequired,
    fetchPreviousInfoForStudent: PropTypes.func.isRequired,
    forceConcludeStudentCourse: PropTypes.func.isRequired,
    loadingStates: PropTypes.object.isRequired,
    manuallyAssignInstructor: PropTypes.func.isRequired,
    updateTransactionEnrollment: PropTypes.func.isRequired,
    updateStudent: PropTypes.func.isRequired,
    studentsPreviousRecords: PropTypes.object.isRequired,
    studentCognitoInfo: PropTypes.object.isRequired,
    studentForceConcludePoints: PropTypes.shape({
      nabc: PropTypes.shape({
        grade_percentage: PropTypes.string,
        letter_grade: PropTypes.string,
        possible_points: PropTypes.string,
        score: PropTypes.string,
      }),
      canvas: PropTypes.shape({
        grade_percentage: PropTypes.string,
        letter_grade: PropTypes.string,
        possible_points: PropTypes.string,
        score: PropTypes.string,
      }),
      completed: PropTypes.bool,
    }),
    updateEnrollments: PropTypes.func.isRequired,
    studentCourses: PropTypes.object.isRequired,
    studentId: PropTypes.number.isRequired,
    grades: PropTypes.object,
    refundEligibility: PropTypes.object,
    renderCoursesOnly: PropTypes.bool,
    student: PropTypes.object, // @TODO: this is required but may be null until students reducer is filled
    fetchWithdrawReasons: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      pageNum: 0,
      rowsPerPage: 10,
      confirmDisableDialogOpen: false,
      confirmWithdrawDialogOpen: false,
      studentIdToEdit: null,
      studentSettingsDialogOpen: false,
      errorNoNotesText: false,
      confirmUndoChangeInRegistrationDialogOpen: false,

      // controlled inputs
      notesText: '',
      addCourseSelectionId: '', // @TODO: warning for going from controlled to uncontrolled when clicking enroll button
      isDatePickerOpen: false,
      isStartDatePickerOpen: false,
      dateDeadlineForClass: 0,
      startDeadline: 0,
      suppressWelcomeEmail: false,
      assignInstructorManually: false,
      isConfirmForceConcludeDialogOpen: false,
      enableConfirmForceConcludeButton: false,
      displayTranscriptHistoryRequests: false,
      idOfInstructorToAssign: '',
      forceConcludeNabcHighlighted: true,
      displayDiscountCodeDialog: false,
      courseIdForDiscountCode: '',
      enrollmentId: '',
      displayDialogForMultipleRefundCancelsOnWithdraw: false,
      displayConsortiumGradeChangeDialog: false,
    };
  }

  _getDeptChairCourseIds() {
    if (this.props.departmentChairCourses.length) {
      return this.props.departmentChairCourses.map((course) => {
        return course.id;
      });
    }
    return null;
  }

  componentDidMount() {
    this.props.fetchGrades(this.props.studentId);
    this.props.fetchPreviousInfoForStudent(this.props.studentId);
    this.props.fetchStudentCourses(this.props.studentId, this._getDeptChairCourseIds());
    this.props.fetchCourses();
    this.props.fetchWithdrawReasons();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.studentId !== this.props.studentId) {
      this.props.fetchStudentCourses(this.props.studentId, this._getDeptChairCourseIds());
      this.props.fetchGrades(this.props.studentId);
    }

    if (this.props.studentForceConcludePoints.completed) {
      this.setState({isConfirmForceConcludeDialogOpen: false});
      this.props.closeForceConcludeDialog();
      this.props.fetchStudentCourses(this.props.studentId, this._getDeptChairCourseIds());
    }
  }

  _setWithdrawDialog = (bool) => {
    if (bool !== undefined) {
      this.setState({confirmWithdrawDialogOpen: bool});
    }
  }

  _setUndoChangeInRegistrationDialog = (bool) => {
    if (bool !== undefined) {
      this.setState({confirmUndoChangeInRegistrationDialogOpen: bool});
    }
  }

  _renderUnwithdrawSnackBar() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.displayDialogForMultipleRefundCancelsOnWithdraw}
        onClose={() => this.setState({displayDialogForMultipleRefundCancelsOnWithdraw: false})}
      >
        <MuiAlert severity={'error'} variant='standard'>
          <div style={styles.alertContainer}>
            <p id='message-id'>Error: This enrollment has multiple refund requests. Please contact Engineering to cancel this refund.</p>
            <IconButton
              key='close'
              onClick={() => this.setState({displayDialogForMultipleRefundCancelsOnWithdraw: false})}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </MuiAlert>
      </Snackbar>
    );
  }

  _renderStudentCourses(student) {
    return (
      <div style={styles.studentCoursesWrapper}>
        <div>
          {this._renderCoursesInfo(student)}
          {this.state.isAddCourseVisible && <ManualEnrollment studentId={this.props.studentId} onClose={() => this.setState({isAddCourseVisible: false})} />}
        </div>
        <div style={styles.addCourseAndTranscriptHistoryButtonsWrapper}>
          {(!!this.props.admin.perms[EDIT_STUDENT_INFO] && !this.state.isAddCourseVisible && !this.props.renderCoursesOnly) &&
            <Button
              onClick={() => {
                this.setState({isAddCourseVisible: true});
              }}
              variant='contained'
              color='secondary'
            >
              Add course
            </Button>
          }
          {(!!this.props.admin.perms[READ_STUDENT_INFO] && !this.state.isAddCourseVisible && this.props.admin.department !== Globals.userDepartments.facultyDepartmentChair) &&
            <Button
              style={styles.transcriptHistoryRequestButton}
              variant='contained'
              color='secondary'
              onClick={() => this.setState({displayTranscriptHistoryRequests: true})}
            >
              Transcript Request History
            </Button>
          }
        </div>
      </div>
    );
  }

  _renderConsortiumGradeChangeDialog() {
    return (
      <Dialog
        open={true}
        onClose={() => this.setState({displayConsortiumGradeChangeDialog: false})}
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle id='alert-dialog-consortium-dialog-title'>Grade Change Error</DialogTitle>
        <DialogContent>
          <div>
            <p>This student is a consortium student, please contact <a href='mailto:studentsuccess@portagelearning.edu'>Student Success</a> for a grade change.</p>
          </div>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              color='secondary'
              onClick={() => this.setState({displayConsortiumGradeChangeDialog: false})}
            >
                OK
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }

  _renderTranscriptHistoryDialog(student) {
    return (
      <Dialog
        open={true}
        onClose={() => this.setState({displayTranscriptHistoryRequests: false})}
        fullWidth={true}
        maxWidth='md'
      >
        <DialogTitle id='alert-dialog-transcript-history-title'>Transcript Request History</DialogTitle>
        <DialogContent>
          <div>
            <TranscriptHistory studentId={student.id} />
          </div>
        </DialogContent>
        <DialogActions>
          <div style={styles.transcriptHistoryDialogCloseButton}>
            <Button
              color='secondary'
              onClick={() => this.setState({displayTranscriptHistoryRequests: false})}
            >
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }

  _renderStudentSettingsDialog(student) {
    return (
      <Dialog
        open={true}
        onClose={() => this.setState({studentSettingsDialogOpen: false})}
        onEnter={() => this.props.fetchCognitoStudentData(student.id)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Student settings</DialogTitle>
        <DialogContent style={styles.innerDialogContent}>
          {!!this.props.admin.perms[EDIT_STUDENT_INFO] &&
            <div style={styles.settingsSection}>
              <h4>Canvas</h4>
              <div style={styles.settingsRow}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={student.extend_quiz_time}
                      onChange={() => this.props.extendStudentQuizTime(student.canvas_id, student.extend_quiz_time ? 0 : 1)}
                    />
                  }
                  label='Time accommodations'
                  style={styles.muiFormControlLabelCustom}
                />
                {this.props.loadingStates.extendStudentQuizTime && <CircularProgress size={30} />}
              </div>
            </div>
          }
        </DialogContent>
        <DialogActions>
          {(!!this.props.admin.perms[DEVELOPER] || !!this.props.admin.perms[DISABLE_STUDENT]) &&
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                if (!this.props.studentCognitoInfo.Enabled) {
                  this.props.updateStudent({
                    id: student.id,
                    disabled: 0,
                    notes: 're-enabled',
                  }, () => this.props.fetchCognitoStudentData(this.props.studentId));
                } else {
                  this.setState({confirmDisableDialogOpen: true});
                }
              }}
            >
              {`${this.props.studentCognitoInfo.Enabled ? 'Disable' : 'Enable'} Login`}
            </Button>
          }
          {this.state.confirmDisableDialogOpen &&
            <Dialog
              open={true}
              onClose={() => this.setState({confirmDisableDialogOpen: false})}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>Disable student?</DialogTitle>
              <DialogContent style={styles.innerDialogContent}>
                {`Are you sure you want to disable this student?  No student data will be affected, but ${student.first_name} no longer will be able to log in to any NursingABC or Portage Learning service.`}
                <TextField
                  style={{...styles.textField, width: '100%'}}
                  label='Reason'
                  error={this.state.errorNoNotesText}
                  placeholder='Reason for disabling student'
                  value={this.state.notesText}
                  onChange={(e) => this.setState({notesText: e.target.value, errorNoNotesText: false})}
                  margin='dense'
                  required={true}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    if (!this.state.notesText) {
                      this.setState({errorNoNotesText: true});
                      return;
                    }
                    this.props.updateStudent({
                      id: student.id,
                      disabled: 1,
                      notes: this.state.notesText,
                    }, () => this.props.fetchCognitoStudentData(this.props.studentId));
                    this.setState({
                      confirmDisableDialogOpen: false,
                      notesText: '',
                    });
                  }}
                >
                  Confirm disable
                </Button>
                <Button
                  color='secondary'
                  onClick={() => this.setState({confirmDisableDialogOpen: false})}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          }
          {this.props.loadingStates.resetStudentPassword && <CircularProgress size={30} />}
          <span style={styles.expand}></span>
          <Button
            onClick={() => this.setState({studentSettingsDialogOpen: false})}
            color='secondary'
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _renderData(label, value, onEditClick) {
    return (
      <Grid
        style={styles.dataItemWrapper}
        item
        md={3}
        sm={6}
        xs={12}
      >
        <div style={styles.dataItemInnerWrapper}>
          <span style={styles.dataItemLabel}>{label}</span>
          <span style={styles.dataItemValue}>{value}</span>
        </div>
        {onEditClick &&
          <div style={styles.deadlineWrapper}>
            <IconButton onClick={() => onEditClick()}>
              <EditIcon />
            </IconButton>
          </div>
        }
      </Grid>
    );
  }

  _displayEnrollmentAmountPaid(enrollmentTransactions) {
    const transactions = enrollmentTransactions.transactions.filter((et) => et.payment_type === 'payment');
    return (transactions[0]) ? `${formatNumberAsUScurrency(transactions[0].amount)}` : '-';
  }

  _displayEnrollmentDiscountCode(enrollmentTransactions) {
    const transactions = enrollmentTransactions.transactions.filter((et) => et.payment_type === 'payment');
    return transactions[0]?.discount ? `${transactions[0].discount.code}` : '-';
  }

  _displayEnrollmentPaymentProcessor(enrollmentTransactions) {
    const paymentProcessor = enrollmentTransactions.transactions.filter((et) => et.payment_type === 'payment');
    if (paymentProcessor[0]?.payment_processor === 'braintree') {
      return <a href={`https://www.braintreegateway.com/merchants/yryksm7vjfz3t554/transactions/${paymentProcessor[0].transaction_id}`} target='_blank' rel='noopener noreferrer'>braintree ({paymentProcessor[0].transaction_id})</a>;
    } else if (paymentProcessor[0]?.payment_processor === 'pen') {
      return 'pen';
    }
    return '-';
  }

  _hasOpenRefunds(enrollmentTransactions) {
    return enrollmentTransactions.transactions.some((t) => t.payment_type === 'refund' && t.status === 'open');
  }

  _displayEnrollmentRefunds(enrollmentTransactions) {
    const refundTransactions = enrollmentTransactions.transactions.filter((et) => et.payment_type === 'refund');
    return (
      <Grid
        style={styles.dataItemWrapper}
        item
        md={3}
        sm={6}
        xs={12}
      >
        <div>
          <div style={styles.dataItemInnerWrapper}>
            <span style={styles.dataItemLabel}>Refunds</span>
          </div>
          {refundTransactions.length === 0
            ? <div>-</div>
            : <List styles={{padding: 0}}>
              {refundTransactions.map((refund) => {
                return (
                  <React.Fragment key={refund.id}>
                    <Tooltip title={<div>{refund?.notes}  <br /> {moment(refund.updated_at).format('L')}</div>}><ListItem style={styles.listDataItemValue}>{formatNumberAsUScurrency(refund.amount)} - {refund.status}</ListItem></Tooltip>
                  </React.Fragment>
                );
              })}
            </List>
          }
        </div>
      </Grid>
    );
  }

  _shouldRenderAssignInstructor(course) {
    if (course.withdrawn) {
      return false;
    }

    if (!!this.props.admin.perms[MANUALLY_ASSIGN_INSTRUCTOR] && course.instructor_name === 'unassigned' && !course.change_of_previous_enrollment_id) {
      return true;
    }

    return !!(course.change_of_previous_enrollment_id && course.paid_in_full && course.instructor_name === 'unassigned');
  }

  _renderCoursesInfo(student) {
    if (this.props.studentCourses[this.props.studentId] && this.props.grades[this.props.studentId]) {
      return (
        <div style={styles.coursesWrapper}>
          {this.props.studentCourses[this.props.studentId].map((c) => {
            let changeOfRegistrationText = '';
            const finalGrade = c.final_letter_grade || '-';
            let status = 'active';
            let panelSummaryStyle = {};
            if (c.open_for_review) {
              status = 'openForReview';
            } else if (c.open_for_grade_change) {
              status = 'openForGradeChange';
            } else if (c.complete) {
              status = 'completed';
            } else if (c.withdrawn) {
              if (c.final_letter_grade === 'NG') {
                panelSummaryStyle = styles.ngOverlay;
                status = 'ng';
              } else {
                status = 'withdrawn';
              }
            }
            if (c.change_of_previous_enrollment_id) {
              changeOfRegistrationText = `(Change in registration from ${this.props.studentCourses[this.props.studentId].find((e) => e.id === c.change_of_previous_enrollment_id).name})`;
            }
            const studentCourseForChangeOfEnrollment = this.props.studentCourses[this.props.studentId].find((e) => e.change_of_previous_enrollment_id === c.id);
            if (studentCourseForChangeOfEnrollment) {
              changeOfRegistrationText = `(Change in registration to ${studentCourseForChangeOfEnrollment.name})`;
            }
            const menuItems = [];
            if (this._shouldRenderAssignInstructor(c)) {
              menuItems.push(
                <MenuItem
                  key='assignInstructor'
                  onClick={(event) => {
                    this.setState({
                      assignInstructorDialogIsOpen: true,
                      enrollmentId: c.id,
                    });
                    this.props.fetchAssignableCourseInstructors(c.course_id);
                  }}
                >
                  Assign instructor
                </MenuItem>
              );
            }
            if ((!!this.props.admin.perms[EDIT_STUDENT_INFO] && (c.complete === 0 && c.withdrawn === 0)) && (c.change_of_previous_enrollment_id === null)) {
              menuItems.push(
                <MenuItem
                  key='withdraw'
                  onClick={() => {
                    this.setState({enrollmentId: c.id});
                    this._setWithdrawDialog(true);
                  }}
                >
                  Withdraw/Change in Registration
                </MenuItem>
              );
            }
            if ((c.final_letter_grade !== 'NG' && c.withdrawn === 1 && !this.props.studentCourses[this.props.studentId].find((course) => course.change_of_previous_enrollment_id === c.id)) || (c.final_letter_grade !== 'NG' && c.withdrawn === 1 && !this._hasOpenRefunds(c.transactions_enrollments))) {
              if (!!this.props.admin.perms[DEVELOPER] || !!this.props.admin.perms[UNWITHDRAW_ENROLLMENT]) {
                menuItems.push(
                  <MenuItem
                    key='unwithdraw'
                    onClick={() => {
                      const inPolicy = ['WP', 'WX', 'WF'].includes(c.final_letter_grade) && moment(c.date_concluded) >= moment().subtract(3, 'months');
                      if (inPolicy || window.confirm('It looks like this unwithdraw is significantly out of policy.  If you would like to proceed anyways, please press "OK".  To cancel this request, press "Cancel".')) {
                        this.props.updateEnrollments(c.id, {unwithdraw: 1});
                      }
                    }}
                  >
                    Unwithdraw
                  </MenuItem>
                );
              }
            }
            if (c.change_of_previous_enrollment_id !== null && c.final_letter_grade !== 'NG') {
              if (!!this.props.admin.perms[DEVELOPER] || !!this.props.admin.perms[UNWITHDRAW_ENROLLMENT]) {
                menuItems.push(
                  <MenuItem
                    key='withdraw'
                    onClick={() => {
                      this.setState({enrollmentId: c.id});
                      this._setUndoChangeInRegistrationDialog(true);
                    }}
                  >
                    Undo Change in Registration
                  </MenuItem>
                );
              }
            }
            if ((c.final_letter_grade === 'NG' || c.final_letter_grade === 'WX' || c.final_letter_grade === 'WF' || c.final_letter_grade === 'WP') && this._hasOpenRefunds(c.transactions_enrollments) && !this.props.studentCourses[this.props.studentId].find((course) => course.change_of_previous_enrollment_id === c.id)) {
              if (!!this.props.admin.perms[DEVELOPER] || !!this.props.admin.perms[UNWITHDRAW_ENROLLMENT]) {
                menuItems.push(
                  <MenuItem
                    key='unwithdrawAndCancelRefund'
                    onClick={() => {
                      const updateEnrollmentValues = {
                        open_refund_id: null,
                        unwithdraw: 1,
                      };
                      if (c.refunds.length === 1) {
                        updateEnrollmentValues.open_refund_id = c.refunds[0];
                      } else if (c.refunds.length > 1) {
                        this.setState({displayDialogForMultipleRefundCancelsOnWithdraw: true});
                        return;
                      }
                      this.props.updateEnrollments(c.id, updateEnrollmentValues);
                    }}
                  >
                    Unwithdraw and cancel refund
                  </MenuItem>
                );
              }
            }
            if (!!this.props.admin.perms[DEVELOPER] || !!this.props.admin.perms[PAID_IN_FULL]) {
              menuItems.push(
                <MenuItem
                  key='markAsPaidUnpaid'
                  onClick={() => {
                    this.props.updateTransactionEnrollment(c.id, {is_paid_in_full: +!c.paid_in_full});
                  }}
                  variant='contained'
                  color='primary'
                >
                  {c.paid_in_full ? 'Mark as "unpaid"' : 'Mark as "paid"'}
                </MenuItem>
              );
            }
            if (!!this.props.admin.perms.developer && c.complete === 0 && c.withdrawn === 0) {
              menuItems.push(
                <MenuItem
                  key='forceConclude'
                  onClick={() => {
                    this.setState({
                      devMenuAnchorEl: null, isConfirmForceConcludeDialogOpen: true, enrollmentId: c.id,
                    });
                    this.props.fetchNABCCanvasCoursePoints(this.props.studentId, this.state.enrollmentId);
                  }}
                >
                  Force conclude
                </MenuItem>
              );
            }
            if (!!this.props.admin.perms[APPLY_DISCOUNT] && !c.transactions_enrollments.transactions[0]?.discount) {
              menuItems.push(
                <MenuItem
                  key='displayDiscountCodeDialog'
                  onClick={() => this.setState({
                    displayDiscountCodeDialog: true,
                    courseIdForDiscountCode: c.course_id,
                    enrollmentId: c.id,
                  })}
                >
                  Apply authorization code
                </MenuItem>
              );
            }
            if ((c.final_letter_grade === null || this.props.admin.perms[DEVELOPER]) && !!this.props.admin.perms[GRADES]) {
              menuItems.push(
                <MenuItem
                  key='backfillGrades'
                  onClick={() => {
                    this.props.backfillGrades(c.id, c.student_id);
                    this.setState({devMenuAnchorEl: null});
                  }}
                >
                  Sync grades
                </MenuItem>
              );
            }
            if ((c.original_date_concluded && c.final_letter_grade !== 'NG' && c.final_letter_grade !== null && !c.open_for_grade_change && !c.open_for_review && !moment().isAfter(moment(c.original_date_concluded).add(14, 'days')) && this.props.admin.perms[RESTORE_ENROLLMENT]) || (c.final_letter_grade !== null && c.final_letter_grade !== 'NG' && this.props.admin.perms[RESTORE_ENROLLMENT] && this.props.admin.department !== Globals.userDepartments.facultyDepartmentChair && !c.open_for_grade_change && !c.open_for_review)) {
              menuItems.push(
                <MenuItem
                  key='restoreForReview'
                  onClick={() => {
                    this.props.updateEnrollments(c.id, {open_for_review: 1, course_ids: this._getDeptChairCourseIds()});
                    this.setState({devMenuAnchorEl: null});
                  }}
                >
                  Restore Enrollment For Review
                </MenuItem>
              );
            }
            if (c.date_concluded && c.final_letter_grade !== 'NG' && c.open_for_review && this.props.admin.perms[RESTORE_ENROLLMENT]) {
              menuItems.push(
                <MenuItem
                  key='concludeForReview'
                  onClick={() => {
                    this.props.updateEnrollments(c.id, {open_for_review: 0, course_ids: this._getDeptChairCourseIds()});
                    this.setState({devMenuAnchorEl: null});
                  }}
                >
                  Conclude Enrollment For Review
                </MenuItem>
              );
            }
            if ((c.original_date_concluded && c.final_letter_grade !== 'NG' && c.final_letter_grade !== null && !c.open_for_review && !c.open_for_grade_change && !moment().isAfter(moment(c.original_date_concluded).add(14, 'days')) && this.props.admin.perms[RESTORE_ENROLLMENT] && !c.instructor_changed_grade_after_completion) || (c.final_letter_grade !== null && c.final_letter_grade !== 'NG' && this.props.admin.perms[RESTORE_ENROLLMENT] && this.props.admin.department !== Globals.userDepartments.facultyDepartmentChair && !c.open_for_review && !c.open_for_grade_change)) {
              menuItems.push(
                <MenuItem
                  key='restoreForGradeChange'
                  onClick={() => {
                    if (c.institution_campus_partner_agreement_id && this.props.admin.department === Globals.userDepartments.facultyDepartmentChair) {
                      this.setState({displayConsortiumGradeChangeDialog: true});
                    } else {
                      this.props.updateEnrollments(c.id, {
                        open_for_grade_change: 1,
                        date_deadline: moment(c.date_deadline).add(21, 'days').unix(),
                        course_ids: this._getDeptChairCourseIds(),
                      });
                    }
                    this.setState({devMenuAnchorEl: null});
                  }}
                >
                  Restore Enrollment For Grade Change
                </MenuItem>
              );
            }
            if (c.original_date_concluded && c.final_letter_grade !== 'NG' && c.open_for_grade_change && this.props.admin.perms[RESTORE_ENROLLMENT]) {
              menuItems.push(
                <MenuItem
                  key='concludeForGradeChange'
                  onClick={() => {
                    this.props.updateEnrollments(c.id, {
                      open_for_grade_change: 0,
                      conclude_for_grade_change: 1,
                      course_ids: this._getDeptChairCourseIds(),
                    });
                    this.setState({devMenuAnchorEl: null});
                  }}
                >
                  Conclude Enrollment For Grade Change
                </MenuItem>
              );
            }
            if (c.open_for_grade_change && this.props.admin.perms[RESTORE_ENROLLMENT]) {
              menuItems.push(
                <MenuItem
                  key='cancleGradeChange'
                  onClick={() => {
                    this.props.updateEnrollments(c.id, {
                      open_for_grade_change: 0,
                      date_deadline: moment(c.date_started).add(1, 'years').unix(),
                      course_ids: this._getDeptChairCourseIds(),
                    });
                    this.setState({devMenuAnchorEl: null});
                  }}
                >
                    Cancel grade change request
                </MenuItem>
              );
            }

            return (
              <Accordion key={c.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <span key={c.id} style={{...styles.expansionPanelSummaryInner, ...panelSummaryStyle}}>
                    {<span style={{...styles.statusWrapper, backgroundColor: Globals.colors.courseStatuses[c.instructor_name === 'unassigned' ? 'unassigned' : status]}}></span>}
                    <div style={styles.courseHeader}>
                      <div style={styles.enrollmentTitle}>
                        <span style={styles.courseName}>{c.name}</span>
                        {changeOfRegistrationText && <span style={styles.courseChangeOfRegistration}><i>{changeOfRegistrationText}</i></span>}
                        {!!c.has_violation && <HasViolation severity={c.has_violation} />}
                        {c.partner_school && <Tooltip title={c.partner_school}><FlagIcon style={{color: Globals.colors.primary}} /></Tooltip>}
                        {c.side_view_camera_required === 1 && <Tooltip title={'Side view camera required for this course due to a previous violation for this course'}><CameraEnhanceOutlined style={{color: Globals.colors.primary}} /></Tooltip>}
                        <div style={{color: Globals.colors.courseStatuses.openForReview}}>
                          {!!c.open_for_review && <p> Open for review</p>}
                          {!!c.open_for_grade_change && <p> Open for grade change</p>}
                        </div>
                      </div>
                      <span style={styles.courseInstructor}>{c.instructor_name}</span>
                    </div>
                  </span>
                </AccordionSummary>
                <AccordionDetails style={styles.panelDetails}>
                  <Grid container style={styles.dataWrapper}>
                    {this._renderData('Status', status)}
                    {this._renderData('Registration date', moment(c.created_at).format('L LT'))}
                    {this._renderData(
                      'Start date',
                      c.date_started ? (
                        <span style={styles.startDateWrapper}>
                          {moment(c.date_started).format('L LT')}
                          {!!c.force_started && <SportsScoreIcon style={styles.forceStartIcon} />}
                        </span>
                      ) : '-'
                    )}
                    {c.date_concluded
                      ? this._renderData(`${c.withdrawn ? 'Withdraw' : 'Completion'} date`, moment(c.date_concluded).format('L LT'))
                      : this._renderData('Completion date', '-')
                    }
                    {!!this.props.admin.perms[GRADES] && this._renderData('Final grade', finalGrade)}
                    {this._renderData('Completion date deadline',
                      c.date_deadline ? moment(c.date_deadline).format('L') : '-',
                      ((c.complete === 0 && c.withdrawn === 0) || (c.open_for_grade_change === 1 && ['WX', 'WP', 'WF'].includes(c.final_letter_grade))) ? () => {
                        this.setState({isDatePickerOpen: true, enrollmentId: c.id});
                      } : undefined)}
                    {this._renderData('Start date deadline',
                      c.start_deadline ? moment(c.start_deadline).format('L') : '-',
                      (c.complete === 0 && c.withdrawn === 0 && !c.date_started) ? () => {
                        this.setState({isStartDatePickerOpen: true, enrollmentId: c.id});
                      } : undefined)}
                    {this._renderData('Paid', c.paid_in_full ? <CheckIcon color={Globals.colors.green} size={25} /> : <CloseIcon style={{fill: Globals.colors.primary}} size={14} />)}
                    {c.notes && this._renderData('Notes', c.notes)}
                    {this.state.isDatePickerOpen && c.id === this.state.enrollmentId &&
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          open={true}
                          value={moment(c.date_deadline)}
                          style={styles.datePicker}
                          onChange={(e, value) => this.setState({dateDeadlineForClass: e.unix()})}
                          onClose={() => this.setState({isDatePickerOpen: false})}
                          onAccept={(e) => this.props.updateEnrollments(c.id, {date_deadline: (e.unix()) + moment(c.date_deadline || 0).seconds(), course_ids: this._getDeptChairCourseIds()})}
                        />
                      </MuiPickersUtilsProvider>
                    }
                    {this.state.isStartDatePickerOpen && c.id === this.state.enrollmentId &&
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          open={true}
                          value={c.start_deadline ? c.start_deadline : moment()}
                          style={styles.datePicker}
                          onChange={(e, value) => {}}
                          onClose={() => this.setState({isStartDatePickerOpen: false})}
                          onAccept={(e) => this.props.updateEnrollments(c.id, {start_deadline: (e.unix() + e.seconds())})}
                        />
                      </MuiPickersUtilsProvider>
                    }
                    <Tooltip
                      disableFocusListener={!this.props.admin.perms[DEVELOPER]}
                      disableHoverListener={!this.props.admin.perms[DEVELOPER]}
                      title={<div>Section id: {c.section_id}</div>}
                    >
                      {this._renderData('Enrollment ID', c.id)}
                    </Tooltip>
                    {c.complete === 1 && this._renderData('Course Eval.', c.taken_evaluation ? <CheckIcon color={Globals.colors.green} size={25} /> : <CloseIcon style={{fill: Globals.colors.primary}} size={14} />)}
                    {this._renderData('Amount Paid', this._displayEnrollmentAmountPaid(c.transactions_enrollments))}
                    {this._renderData('Discount Code', this._displayEnrollmentDiscountCode(c.transactions_enrollments))}
                    {this._displayEnrollmentRefunds(c.transactions_enrollments)}
                    {!!this.props.admin.perms[FINANCE] && this._renderData('Payment Processor', this._displayEnrollmentPaymentProcessor(c.transactions_enrollments))}
                    {this._renderData('Has completed grade change', c.has_grade_change ? <CheckIcon color={Globals.colors.green} size={25} /> : <CloseIcon style={{fill: Globals.colors.primary}} size={14} />)}
                  </Grid>
                  <div style={styles.container}>
                    <Grid container>
                      {/* Restore is now only for developers, Admins will still have the ability to withdraw  */}
                      {((!!this.props.admin.perms[DEVELOPER] && c.withdrawn === 0) || (!!this.props.admin.perms[EDIT_STUDENT_INFO] && c.withdrawn === 0)) &&
                        <Grid item xs={3}>
                          {/* @TODO: this should be cleaned up and all dialogs should be abstracted to other components */}
                          {(c.complete === 0) &&
                            <div style={styles.actions}>
                              {this.state.confirmWithdrawDialogOpen && c.id === this.state.enrollmentId &&
                              <WithdrawDialog setWithdrawDialog={this._setWithdrawDialog} isWithdrawOpen={this.state.confirmWithdrawDialogOpen} student={student} studentCourse={c} />
                              }
                            </div>
                          }
                        </Grid>
                      }
                      {((!!this.props.admin.perms[DEVELOPER] && c.withdrawn === 0) || (!!this.props.admin.perms[EDIT_STUDENT_INFO] && c.withdrawn === 0)) &&
                        <Grid item xs={3}>
                          {/* @TODO: this should be cleaned up and all dialogs should be abstracted to other components */}
                          {(c.complete === 0) &&
                            <div style={styles.actions}>
                              {this.state.confirmUndoChangeInRegistrationDialogOpen &&
                              <UndoChangeInRegistrationDialog setUndoRegistrationDialog={this._setUndoChangeInRegistrationDialog} isUndoRegistrationOpen={this.state.confirmUndoChangeInRegistrationDialogOpen} student={student} studentCourse={this.props.studentCourses[this.props.studentId].find((course) => course.id === this.state.enrollmentId)} />
                              }
                            </div>
                          }
                        </Grid>
                      }
                      <span style={styles.expand}></span>
                      <CourseSyllabus createdAt={c.created_at} courseCode={c.code}/>
                      {!!menuItems.length &&
                        <span>
                          <IconButton
                            aria-controls='dev-options-menu'
                            aria-haspopup='true'
                            color='secondary'
                            onClick={(event) => this.setState({devMenuAnchorEl: event.currentTarget, enrollmentId: c.id})}
                          >
                            <DotsVerticalIcon size={18} />
                          </IconButton>
                          <Menu
                            id='dev-options-menu'
                            anchorEl={this.state.devMenuAnchorEl}
                            keepMounted
                            open={!!this.state.devMenuAnchorEl && c.id === this.state.enrollmentId}
                            onClose={() => this.setState({devMenuAnchorEl: null})}
                          >
                            {menuItems}
                          </Menu>
                        </span>
                      }
                      {this.state.isConfirmForceConcludeDialogOpen && c.id === this.state.enrollmentId && this._renderForceConcludeDialog()}
                      {this.state.assignInstructorDialogIsOpen && c.id === this.state.enrollmentId && this._renderAssignInstructorDialog(c.course_id)}
                    </Grid>
                  </div>
                  {(!!this.props.admin.perms[GRADES] || !!this.props.admin.perms[FINANCE]) && c.instructor_name !== 'unassigned' &&
                    <Grades
                      studentId={+this.props.studentId}
                      enrollmentId={c.id}
                      hideActualGrades={!this.props.admin.perms[GRADES]}
                    />
                  }
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      );
    }

    return <div><CircularProgress color='secondary' /></div>;
  }

  _renderForceConcludeDialog() {
    return (
      <Dialog
        open={!!this.state.isConfirmForceConcludeDialogOpen}
        onClose={() => {
          this.setState({isConfirmForceConcludeDialogOpen: false, enrollmentId: null}); this.props.clearNABCCanvasCoursePoints();
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Force conclude student?</DialogTitle>
        <DialogContent style={styles.innerDialogContent}>
          <b>{'PLEASE NOTE: this action will conclude the student enrollment in our database AS WELL AS in Canvas. Proceed with caution.'}</b><br />
          {"Are you sure you want to force conclude this student?  This should be used only if there's a rounding error or other mismatch with Canvas.  Please keep in mind that `grades` and `enrollments`.`final_letter_grade` may be out of sync if this feature is used.  `enrollments`.`final_letter_grade` takes precedence here, and this is what it means to \"force conclude\".  The following data will be saved in our database:"}
          <Grid container style={styles.dataWrapper}>
            <Grid item xs={5}>
              <Box display='flex'>NABC:</Box>
              <Paper
                style={{...styles.clickableStyle, ...(this.state.forceConcludeNabcHighlighted ? styles.highlighted : {})}}
                onClick={() => this.setState({forceConcludeNabcHighlighted: true})}
              >
                {this.props.loadingStates.fetchNABCCanvasCoursePoints && <CircularProgress size={30} />}
                {this.props.studentForceConcludePoints.nabc.score &&
                  <div>
                    <Box display='flex'>{`${this.props.studentForceConcludePoints.nabc.score}/${this.props.studentForceConcludePoints.nabc.possible_points}`}</Box>
                    <Box display='flex'>{this.props.studentForceConcludePoints.nabc.grade_percentage}</Box>
                    <Box display='flex'>{this.props.studentForceConcludePoints.nabc.letter_grade}</Box>
                  </div>
                }
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Box display='flex'>Canvas:</Box>
              <Paper
                style={{...styles.clickableStyle, ...(this.state.forceConcludeNabcHighlighted ? {} : styles.highlighted)}}
                onClick={() => this.setState({forceConcludeNabcHighlighted: false})}
              >
                {this.props.loadingStates.fetchNABCCanvasCoursePoints && <CircularProgress size={30} />}
                {this.props.studentForceConcludePoints.canvas.score &&
                  <div>
                    <Box display='flex'>{`${this.props.studentForceConcludePoints.canvas.score}/${this.props.studentForceConcludePoints.canvas.possible_points}`}</Box>
                    <Box display='flex'>{this.props.studentForceConcludePoints.canvas.grade_percentage}</Box>
                    <Box display='flex'>{this.props.studentForceConcludePoints.canvas.letter_grade}</Box>
                  </div>
                }
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            disabled={this.props.loadingStates.fetchNABCCanvasCoursePoints || this.props.studentForceConcludePoints.canvas.letter_grade === 'Course doesn\'t have a grading schema enabled'}
            onClick={() => {
              this.props.forceConcludeStudentCourse(this.state.enrollmentId, this.props.studentForceConcludePoints[this.state.forceConcludeNabcHighlighted ? 'nabc' : 'canvas'].letter_grade);
            }}
          >
            Confirm force conclude
          </Button>
          <Button
            color='secondary'
            onClick={() => {
              this.setState({isConfirmForceConcludeDialogOpen: false, enrollmentId: null});
              this.props.clearNABCCanvasCoursePoints();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  // @TODO: all _render*Dialog could be separate components to clean up this file
  _renderAssignInstructorDialog(nursingCourseId) {
    const _onInstructorAssignmentDialogClose = () => {
      this.setState({
        assignInstructorDialogIsOpen: false,
        idOfInstructorToAssign: null,
        enrollmentId: null,
      });
    };
    return (
      <Dialog
        open={true}
        onClose={() => _onInstructorAssignmentDialogClose()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Instructor assignment</DialogTitle>
        <DialogContent style={styles.innerDiaglogContent}>
          {this.props.assignableCourseInstructors[nursingCourseId]
            ? (
              <div>
                <p>Pick instructor to assign</p>
                <FormControl style={styles.formControl}>
                  <InputLabel id='instructor-name-label'>Instructor Name</InputLabel>
                  <Select
                    style={styles.selectField}
                    labelId='instructor-name-label'
                    value={this.state.idOfInstructorToAssign}
                    onChange={(e) => this.setState({idOfInstructorToAssign: e.target.value})}
                    input={<Input id='idOfInstructorToAssign' name='idOfInstructorToAssign' />}
                  >
                    {this.props.assignableCourseInstructors[nursingCourseId].map((i) => {
                      return <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            ) : (
              <span>
                <CircularProgress size={30} />
                Loading instructors...
              </span>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            disabled={!this.state.idOfInstructorToAssign}
            onClick={() => {
              this.props.manuallyAssignInstructor(
                this.state.enrollmentId,
                this.state.idOfInstructorToAssign,
                () => {
                  this.props.fetchUnassignedStudentIds();
                  _onInstructorAssignmentDialogClose();
                }
              );
            }}
          >
            Confirm
          </Button>
          {this.props.loadingStates.manuallyAssignInstructor && <CircularProgress size={30} />}
          <Button
            color='secondary'
            onClick={() => _onInstructorAssignmentDialogClose()}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _closeDiscountCodeCreateDialog() {
    this.setState({
      displayDiscountCodeDialog: false,
      courseIdForDiscountCode: '',
      enrollmentId: '',
    });
  }

  render() {
    if (!this.props.student) {
      return <CircularProgress color='secondary' />;
    }

    if (this.props.renderCoursesOnly) {
      return (
        <>
          {this._renderStudentCourses(this.props.student)}
          {this.state.displayTranscriptHistoryRequests && this._renderTranscriptHistoryDialog(this.props.student)}
        </>
      );
    }

    return (
      <Paper style={styles.titleWrapper}>
        <div style={styles.detailsOuterWrapper}>
          {<StudentInformationDetails student={this.props.student}/>}
          {this._renderStudentCourses(this.props.student)}
          {this.state.displayDialogForMultipleRefundCancelsOnWithdraw && this._renderUnwithdrawSnackBar()}
          {this.state.displayDiscountCodeDialog &&
            <CreateDiscountDialog
              getDiscountCodeValue={(discountCodeValue) => this.props.applyDiscountCodeToEnrollment(this.state.enrollmentId, discountCodeValue)}
              open={this.state.displayDiscountCodeDialog}
              closeDialog={() => this._closeDiscountCodeCreateDialog()}
              courseForDiscount={Object.values(this.props.courses).find((course) => course.id === this.state.courseIdForDiscountCode)}
            />
          }
          {(!!this.props.admin.perms[EDIT_STUDENT_INFO] || !!this.props.admin.perms[DISABLE_STUDENT]) &&
            <div style={styles.actionsWrapper}>
              <div style={styles.actionOptions}>
                <IconButton
                  color='secondary'
                  onClick={() => this.setState({studentSettingsDialogOpen: true})}
                >
                  <SettingsIcon />
                </IconButton>
                {this.state.studentSettingsDialogOpen && this._renderStudentSettingsDialog(this.props.student)}
                {!!this.props.admin.perms[EDIT_STUDENT_INFO] &&
                  <IconButton
                    color='secondary'
                    onClick={() => this.setState({studentIdToEdit: this.props.student.id})}
                  >
                    <PencilIcon />
                  </IconButton>
                }
                {this.state.studentIdToEdit && <EditStudentDialog studentId={this.props.student.id} onClose={() => this.setState({studentIdToEdit: null})} />}
                {this.state.displayTranscriptHistoryRequests && this._renderTranscriptHistoryDialog(this.props.student)}
              </div>
            </div>
          }
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    admin: state.admin,
    assignableCourseInstructors: state.assignableCourseInstructors,
    courses: state.courses,
    coursesOffered: Object.values(state.courses).sort((a, b) => (a.code).localeCompare(b.code)),
    departmentChairCourses: state.departmentChairCourses,
    grades: state.grades,
    loadingStates: state.loadingStates,
    student: state.students[props.studentId],
    studentCognitoInfo: state.studentCognitoInfo,
    studentForceConcludePoints: state.studentForceConcludePoints,
    studentsPreviousRecords: state.studentsPreviousRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    applyDiscountCodeToEnrollment,
    backfillGrades,
    clearCanvasStudentEnrollmentUpdateFlag,
    clearNABCCanvasCoursePoints,
    closeForceConcludeDialog,
    extendStudentQuizTime,
    fetchAssignableCourseInstructors,
    fetchCognitoStudentData,
    fetchCourses,
    fetchGrades,
    fetchWithdrawReasons,
    fetchNABCCanvasCoursePoints,
    fetchStudentCourses,
    fetchUnassignedStudentIds,
    fetchPreviousInfoForStudent,
    forceConcludeStudentCourse,
    manuallyAssignInstructor,
    updateEnrollments,
    updateTransactionEnrollment,
    updateStudent,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
