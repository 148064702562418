/*
 * Ryan O'Dowd
 * 2019-01-15
 * © Copyright 2019 NursingABC, Inc.  All Rights Reserved.
 */

export default {
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_EDb7x2lkd',
    APP_CLIENT_ID: '6jjkftr8ssc5q3de6ov0f0cuks',
  },
};
