/*
 * Ally Zernick
 * 11-09-2023
 * © Copyright 2023 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  selects: {
    margin: 10,
    width: '200px',
  },
  selectSchool: {
    margin: 10,
    width: '400px',
  },
  selectInstructor: {
    margin: 10,
    width: '150px',
  },
  partnerFormContainer: {
    display: 'flex',
    // flexDirection: 'column',
    marginTop: 75,
    marginBottom: 50,
  },
  duplicateAlert: {
    cursor: 'pointer',
    marginLeft: 2,
    position: 'absolute',
    marginTop: '-7px',
  },
  duplicateCheck: {
    cursor: 'pointer',
    marginLeft: 24,
    position: 'absolute',
    marginTop: '-7px',
    color: Globals.colors.green,
  },
  partnerFormContent: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  partnerFormLabel: {
    marginBottom: 0,
    marginRight: 10,
    marginLeft: 10,
    fontWeight: 'bold',
  },
  submitContainer: {
    display: 'flex',
    marginTop: 50,
    justifyContent: 'flex-end',
  },
  submitButton: {
    marginRight: 20,
  },
};

export default styles;
