/*
 * Nick Lewyn
 * 2020-11-02
 * © Copyright 2020 NursingABC, Inc.  All Rights Reserved.
 */

const styles = {
  titleFormat: {
    fontWeight: 'bold',
  },
  invalidRequest: {
    color: 'red',
  },
  headerDetails: {
    marginBottom: 10,
    alignItems: 'baseline',
  },
  bodyDetails: {
    marginTop: 20,
  },
};

export default styles;
