/*
 * Nathaniel Padgett
 * 2021-08-25
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Autocomplete,
} from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';
import {
  TextField,
} from '@material-ui/core';
import {
  connect,
} from 'react-redux';

const KnownSchoolsTextField = (props) => {
  return (
    <Autocomplete
      renderInput={(params) => <TextField {...params} label={props.inputLabel}></TextField>}
      autoComplete={true}
      disableClearable={true}
      options={Object.values(props.institutionCampuses) || []}
      inputValue={props.schoolNameInput}
      noOptionsText={props.noOptionsText || ''}
      // @TODO use id as value instead of formatted school name
      getOptionLabel={(option) => `${option.name} - ${option.city}, ${option.state}`}
      onInputChange={(e) => {
        props.onInputChange(e);
      }}
      onChange={(e, value) => {
        props.onChange(e, value);
      }}
    />
  );
};

KnownSchoolsTextField.propTypes = {
  institutionCampuses: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  schoolNameInput: PropTypes.string.isRequired,

  inputLabel: PropTypes.string,
  noOptionsText: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    institutionCampuses: state.allCampuses,
  };
};

export default connect(mapStateToProps, {})(KnownSchoolsTextField);
