/*
 * Ryan O'Dowd
 * 2019-01-09
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    width: '100%',
  },
  rangeSelection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
  rangeText: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  fetchButton: {
    marginLeft: 16,
  },
  panel: {
    width: '100%',
  },
  panelDetails: {
    flexDirection: 'column',
  },
  graphContainer: {
    width: '100%',
    height: 400,
    display: 'flex',
    flexDirection: 'row',
  },
  simulationGraphContainer: {
    background: Globals.colors.instructorAssignment.simulationWarning,
  },
  graphTitle: {
    color: Globals.colors.accent,
  },
  simulation: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    paddingTop: 20,
  },
  button: {
    margin: 8,
  },
  textField: {
    width: 48,
  },

  legend: {
    minWidth: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: 60, // @TODO: ResponsiveBar marginBottom...abstract
  },
  legendOptionWrapper: {
    display: 'flex',
    padding: 1,
  },
  legendSwatch: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    margin: 1,
    borderWidth: 2,
    borderStyle: 'solid',
  },
  legendText: {
    fontSize: 12,
    alignSelf: 'center',
    paddingLeft: 4,
  },
};

export default styles;
