/*
 * Nathaniel Padgett
 * 2021-07-28
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import Globals, {
  BETA_ENV,
  PROD_ENV,
} from '../../../Globals';
import CloseIcon from 'mdi-react/CloseIcon';
import PropTypes from 'prop-types';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import {
  decodeJwt,
} from '../../../utilities';
import {
  fetchCurrentAdmin,
} from '../../../actions';
import styles from './styles';

const Header = (props) => {
  const [_anchorEl, setAnchorEl] = React.useState(null);
  const isMasquerading = localStorage.getItem('jwt') && props.admin.username !== decodeJwt(localStorage.getItem('jwt')).username;
  return (
    (Globals.env !== PROD_ENV && Globals.env !== BETA_ENV) &&
      <>
        {/* @TODO: add hide button to this indicator */}
        {isMasquerading
          ? (
            <div style={styles.devIndicator}>
              {`Masquerading as ${props.admin.first_name} ${props.admin.last_name}`}
              <Button
                aria-owns={_anchorEl ? 'perms-menu' : undefined}
                aria-haspopup='true'
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                Perms
              </Button>
              <Menu
                id='perms-menu'
                anchorEl={_anchorEl}
                open={!!_anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                {Object.keys(props.admin.perms).map((perm) => {
                  let value = props.admin.perms[perm];
                  if (!Number.isInteger(+value)) {
                    const validPerms = Object.keys(props.admin.perms[perm]).filter((subPerm) => {
                      return +props.admin.perms[perm][subPerm] === 1;
                    });

                    if (validPerms.length === 0) {
                      value = 'none';
                    } else if (validPerms.length === props.admin.perms[perm].length) {
                      value = 'all';
                    } else {
                      value = validPerms.join(', ');
                    }
                  }

                  return <MenuItem key={perm} disabled={true}>{`${perm}: ${value}`}</MenuItem>;
                })}
              </Menu>
              <IconButton
                style={styles.stopMasqueradingButton}
                color='inherit'
                onClick={() => props.fetchCurrentAdmin()}
              >
                <CloseIcon />
              </IconButton>
            </div>
          ) : <div style={styles.devIndicator}>DEV ENV: changes here will not affect production data</div>
        }
        <div style={styles.devSpacer}></div>
      </>
  );
};

Header.propTypes = {
  fetchCurrentAdmin: PropTypes.func.isRequired,
  admin: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    admin: state.admin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchCurrentAdmin,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
