/*
 * Ryan O'Dowd
 * 2019-01-08
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Card,
  CardContent,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';
import {
  withRouter,
} from 'react-router-dom';

const HomeCard = (props) => {
  return (
    <>
      <Card
        style={{...styles.container, ...(props.isAlphaFeature ? styles.alphaFeature : {})}}
        onClick={() => {
          if (props.href.startsWith('https://')) {
            window.location = props.href;
          } else {
            props.history.push(props.href.split('/:')[0]);
          }
        }}
      >
        <CardContent style={styles.cardContent}>
          <h3 style={styles.name}>{props.icon}&nbsp;&nbsp;{props.name}</h3>
          <p style={styles.description}>{props.description}</p>
        </CardContent>
      </Card>
    </>
  );
};

HomeCard.propTypes = {
  description: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  href: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,

  isAlphaFeature: PropTypes.bool,
};

export default withRouter(HomeCard);
