/*
 * Ryan O'Dowd
 * 2019-03-22
 * © Copyright 2019 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core';
import {
  getErrorKey, // @TODO: remove need for this and initialize hardcoded state variables in the constructor
  validateFormInputs,
} from '../../../utilities';
import {
  ForgotPassword,
} from 'aws-amplify-react';
import React from 'react';
import TextInput from '../TextInput';
import authStyles from '../authStyles';
import {
  nabcGlobalObject,
} from '../../../Globals';

export default class NABCForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);

    this.state = {};

    this.inputValidations = nabcGlobalObject({
      username: {
        isRequired: true,
      },
      code: {
        isRequired: true,
      },
      password: {
        isRequired: true,
      },
    });
  }

  onSubmit(event) {
    event.preventDefault();
    if (this.state.delivery) {
      const errorDict = validateFormInputs(this.inputs, {
        code: {
          isRequired: true,
        },
        password: {
          isRequired: true,
        },
      });

      this.setState(errorDict);
      if (!Object.keys(errorDict).length) {
        super.submit();
      }
    } else {
      const errorDict = validateFormInputs(this.inputs, {
        username: {
          isRequired: true,
        },
      });

      this.setState(errorDict);
      if (!Object.keys(errorDict).length) {
        super.send();
      }
    }
  }

  showComponent(theme) {
    return (
      <form onSubmit={(e) => this.onSubmit(e)}>
        <Card style={authStyles.container}>
          <CardContent style={authStyles.cardContent}>
            <h2>Reset password</h2>
            <div style={authStyles.form}>
              <TextInput
                name='username'
                label='Email address'
                otherProps={{type: 'email'}}
                handleInputChange={this.handleInputChange}
                errorMessage={this.state[getErrorKey('username')]}
                isRequired={this.inputValidations.username.isRequired}
              />
              {this.state.delivery &&
                <>
                  {/* @TODO: the code is automatically filled in with email from lastpass, but it shouldn't be */}{/* @TODO: actually, different in websites...fix this to update what that repo has */}
                  <TextInput
                    name='code'
                    label='Code from email'
                    handleInputChange={this.handleInputChange}
                    errorMessage={this.state[getErrorKey('code')]}
                    isRequired={this.inputValidations.code.isRequired}
                  />
                  <TextInput
                    name='password'
                    label='New password'
                    otherProps={{type: 'password'}}
                    handleInputChange={this.handleInputChange}
                    errorMessage={this.state[getErrorKey('passsword')]}
                    isRequired={this.inputValidations.password.isRequired}
                  />
                </>
              }
            </div>
          </CardContent>
          <CardActions>
            <Button
              color='secondary'
              onClick={() => this.state.delivery ? super.send() : super.changeState('signIn')}
            >
              {this.state.delivery ? 'Resend Code' : 'Back'}
            </Button>
            <p style={{flexGrow: 1}}></p>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              onClick={() => this.state.delivery ? super.submit() : super.send()}
            >
              {this.state.delivery ? 'Submit' : 'Send Code'}
            </Button>
          </CardActions>
        </Card>
      </form>
    );
  }
}
