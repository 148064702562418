/*
 * Ryan O'Dowd
 * 2019-01-09
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */

const INNER_PADDING = 16; // @TODO: sync with ./components/App/styles.js

const commonStyles = {
  paddedContainer: {
    padding: INNER_PADDING,
  },
};

export default commonStyles;
