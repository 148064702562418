/*
 * Ryan O'Dowd
 * 2019-03-22
 * © Copyright 2019 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core';
import {
  getErrorKey,
  validateFormInputs,
} from '../../../utilities';
import React from 'react';
import {
  RequireNewPassword,
} from 'aws-amplify-react';
import TextInput from '../TextInput';
import authStyles from '../authStyles';
import {
  nabcGlobalObject,
} from '../../../Globals';

export default class NABCRequireNewPassword extends RequireNewPassword {
  constructor(props) {
    super(props);

    this.state = {};

    this.inputValidations = nabcGlobalObject({
      password: {
        isRequired: true,
      },
    });
  }

  onSubmit(event) {
    event.preventDefault();

    const errorDict = validateFormInputs(this.inputs, this.inputValidations);
    this.setState(errorDict);

    if (!Object.keys(errorDict).length) {
      super.change();
    }
  }

  showComponent(theme) {
    return (
      <form onSubmit={(e) => this.onSubmit(e)}>
        <Card style={authStyles.container}>
          <CardContent style={authStyles.cardContent}>
            <h2>Change password</h2>
            <div style={authStyles.form}>
              <TextInput
                name='password'
                label='New password'
                otherProps={{type: 'password'}}
                handleInputChange={this.handleInputChange}
                errorMessage={this.state[getErrorKey('password')]}
                isRequired={this.inputValidations.password.isRequired}
              ></TextInput>
            </div>
          </CardContent>
          <CardActions>
            <Button
              color='secondary'
              onClick={() => super.changeState('signIn')}
            >
              Back
            </Button>
            <p style={{flexGrow: 1}}></p>
            <Button
              variant='contained'
              color='primary'
              type='submit'
            >
              Change
            </Button>
          </CardActions>
        </Card>
      </form>
    );
  }
}
