/*
 * Nathaniel Padgett
 * 2020-09-24
 * © Copyright 2020 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  fetchExamLocking,
  fetchGradeSyncing,
  fetchPurgeFdlq,
} from '../../../actions';
import PropTypes from 'prop-types';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import styles from './styles';

const QUEUE_ERROR = 'Please select a queue';
const DLQs = {
  examLocking: 'canvas_dead_letter',
  gradeSyncing: 'canvas-live-events-grade-syncing-dead-letter',
};

const AwsTools = (props) => {
  const [queue, setQueue] = React.useState('');
  const [queueError, setQueueError] = React.useState('');

  return (
    <div style={styles.container}>
      <h3>Reprocess DLQs</h3>
      <div style={styles.formControlWrapper}>
        <FormControl error={!!queueError} fullWidth={true}>
          <InputLabel htmlFor='dlq'>Queue</InputLabel>
          <Select
            value={queue}
            onChange={(e) => setQueue(e.target.value)}
            onOpen={() => setQueueError('')}
            input={<Input id='dlq' name='dlq' />}
          >
            <MenuItem key='placeholder' value='' disabled={true}>Queue*</MenuItem>
            <MenuItem key={DLQs.gradeSyncing} value='canvas-live-events-grade-syncing-dead-letter'>Grade Syncing</MenuItem>
            <MenuItem key={DLQs.examLocking} value='canvas_dead_letter'>Exam Locking</MenuItem>
          </Select>
          <FormHelperText>{queueError}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <Button
          variant='contained'
          color='primary'
          style={styles.buttonStyles}
          onClick={() => {
            if (!queue) {
              setQueueError(QUEUE_ERROR);
              return;
            }

            if (queue === DLQs.gradeSyncing) {
              props.fetchGradeSyncing(queue);
            } else {
              props.fetchExamLocking(queue);
            }
          }}
        >
          Re-Process DLQ
        </Button>
        <Button
          variant='contained'
          color='primary'
          style={styles.buttonStyles}
          onClick={() => {
            if (queue) {
              props.fetchPurgeFdlq(queue);
            } else {
              setQueueError(QUEUE_ERROR);
            }
          }}
        >
          Clear FDLQ
        </Button>
      </div>
    </div>
  );
};

AwsTools.propTypes = {
  fetchExamLocking: PropTypes.func.isRequired,
  fetchGradeSyncing: PropTypes.func.isRequired,
  fetchPurgeFdlq: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchExamLocking,
    fetchGradeSyncing,
    fetchPurgeFdlq,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AwsTools);
