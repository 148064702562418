/*
 * Ally Zernick
 * 05-17-2023
 * © Copyright 2023 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    textAlign: 'center',
    margin: 10,
    height: 'auto',
    width: '30%',
  },
  cardContent: {
    height: 'calc(100% - 40px)', // subtract padding
    overflowY: 'auto',
  },
  searchBox: {
    margin: 10,
  },
  schoolName: {
    margin: 10,
    width: '25%',
  },
  schoolNameLong: {
    margin: 10,
    width: '35%',
  },
  url: {
    margin: 10,
    width: '15%',
  },
  specialInformation: {
    margin: 10,
    width: '46.3%',
  },
  imagePath: {
    margin: 10,
    width: '10%',
  },
  backgroundColorSelect: {
    margin: 10,
    width: '15%',
  },
  colorLabel: {
    margin: 10,
    color: Globals.colors.accent,
  },
  institutionSelect: {
    margin: 10,
    width: '30%',
  },
  buttonStyle: {
    marginTop: 10,
    height: 50,
    width: '20%',
  },
  lineSeparator: {
    paddingBottom: 15,
    borderBottomColor: `${Globals.colors.accent}22`,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
  },
  program: {
    margin: 10,
    width: '10%',
  },
  inputLabel: {
    marginLeft: 5,
    marginBottom: 5,
    width: '100%',
  },
  partnerCard: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  partnerInfo: {
    marginTop: 15,
    marginBottom: 0,
  },
  editButton: {
    position: 'relative',
    margin: 'auto 0 0 auto',
  },
};

export default styles;
