/*
 * Rob DePietro
 * 2024-09-20
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  MenuItem,
  TextField,
} from '@material-ui/core';
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Globals, {
  DEVELOPER,
  ENROLLMENT_OVERRIDE,
  ENROLLMENT_OVERRIDE_FOR_GRADE_F,
} from '../../../../Globals';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  fetchRefundEligibility,
  fetchStudentCourses,
  requestRefund,
  updateEnrollments,
} from '../../../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import CalendarIcon from 'mdi-react/CalendarMonthIcon';
import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from '../styles';

const WithdrawDialog = ({
  studentCourse, setWithdrawDialog, student, isWithdrawOpen,
}) => {
  const dispatch = useDispatch();
  const [_withdrawReason, setWithdrawReason] = useState('');
  const [_withdrawNotes, setWithdrawNotes] = useState('');
  const [_changeInRegistrationCourse, setChangeInRegistrationCourse] = useState('');
  const [_withdrawRequestDate, setWithdrawRequestDate] = useState(moment());
  const [_overrideRefundEligibility, setOverrideRefundEligibility] = useState(false);
  const [_refundAmountError, setRefundAmountError] = useState(false);
  const courses = useSelector((state) => state.courses);
  const [_customFinalLetterGrade, setCustomFinalLetterGrade] = useState('');
  const [_customRefundAmount, setCustomRefundAmount] = useState('');
  const refundEligibility = useSelector((state) => state.refundEligibility);
  const admin = useSelector((state) => state.admin);
  const coursesOffered = Object.values(courses).sort((a, b) => (a.code).localeCompare(b.code));
  const loadingStates = useSelector((state) => state.loadingStates);
  const reasons = useSelector((state) => state.withdrawReasons);
  const CHANGE_IN_REGISTRATION_REASON = reasons.find((reason) => reason.reason === 'Change of registration').id;
  const OTHER_REASON = reasons.find((reason) => reason.reason === 'Other').id;
  const allStudentCourses = useSelector((state) => state).studentCourses;
  const _activeCourses = allStudentCourses[Object.keys(allStudentCourses)[0]].filter((el) => el.withdrawn === 0 || (['WF', 'WP'].includes(el.final_letter_grade))).map((el) => el.code);

  const SELECT_INITIAL_VALUE = '';
  const FINAL_LETTER_GRADES = [
    'NG',
    'WX',
    'WF',
    'WP',
    (!!admin.perms[ENROLLMENT_OVERRIDE_FOR_GRADE_F] || !!admin.perms[DEVELOPER]) && 'F',
  ];

  useEffect(() => {
    if ((_withdrawReason !== '') || (_withdrawReason !== CHANGE_IN_REGISTRATION_REASON) || (_withdrawReason === CHANGE_IN_REGISTRATION_REASON && _changeInRegistrationCourse !== null)) {
      dispatch(fetchRefundEligibility(studentCourse.id, {
        refund_request_date: moment.utc(_withdrawRequestDate).format('YYYY-M-DD HH:mm:ss'),
        course_change: _withdrawReason === CHANGE_IN_REGISTRATION_REASON ? _changeInRegistrationCourse : null,
      }));
      return;
    }
    setChangeInRegistrationCourse(SELECT_INITIAL_VALUE);
  }, [dispatch, _changeInRegistrationCourse, _withdrawReason, _withdrawRequestDate, studentCourse, CHANGE_IN_REGISTRATION_REASON]);

  const refundMessage = (refundEligibility) => {
    const studentName = `${student.first_name + ' ' + student.last_name + ' ' + `(ID: ${student.id})`}`;
    return (
      <p>{studentName} {refundEligibility.message}</p>
    );
  };

  useEffect(() => {
    if (refundEligibility.change_in_registration_ineligible_message !== null && _overrideRefundEligibility) {
      setCustomFinalLetterGrade('NG');
    }
  }, [_overrideRefundEligibility, refundEligibility.change_in_registration_ineligible_message]);

  const resetFields = () => {
    setChangeInRegistrationCourse(SELECT_INITIAL_VALUE);
    setOverrideRefundEligibility(false);
    setCustomFinalLetterGrade('');
    setCustomRefundAmount('');
  };

  const _withdrawStudent = (enrollmentId, newCourse, courseId, finalLetterGrade, withdrawNotes, withdrawReason, discountAmount, discountCode, callback) => {
    dispatch(updateEnrollments(
      enrollmentId,
      {
        change_in_registration_course: newCourse,
        course_id: courseId,
        final_letter_grade: finalLetterGrade,
        withdraw_notes: withdrawNotes,
        withdraw_reason: withdrawReason,
        discount_amount: discountAmount,
        discount_code: discountCode,
      },
    ));
    callback();
  };

  const _confirmWithdrawDialogClose = () => {
    setWithdrawDialog(false);
    setWithdrawRequestDate(null);
    setWithdrawReason('');
    setWithdrawNotes('');
    setChangeInRegistrationCourse('');
    setOverrideRefundEligibility(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={isWithdrawOpen}
      onClose={() => _confirmWithdrawDialogClose()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'><span style={styles.dialogTitle}>Withdraw / Change in Registration for ({studentCourse.code.toUpperCase()})</span></DialogTitle>
      <DialogContent style={styles.innerDialogContent}>
        <div style={styles.formRow}>
          <FormControl component='fieldset'>
            <FormLabel style={styles.dateLabel} component='legend'>Date withdraw was requested</FormLabel>
            <div style={styles.dateTimeWrapper}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker
                  value={_withdrawRequestDate}
                  minDate={studentCourse.created_at}
                  onChange={(e) => {
                    setWithdrawRequestDate(e);
                  }}
                />
              </MuiPickersUtilsProvider>
              <CalendarIcon style={{color: Globals.colors.accent}}/>
            </div>
          </FormControl>
        </div>
        <div style={styles.formRowReason}>
          <FormControl style={styles.formControlRootReason}>
            <FormLabel style={styles.dateLabel} component='legend'>Reason for the withdraw</FormLabel>
            <TextField
              select
              style={{...styles.formControl, ...styles.textField}}
              value={_withdrawReason}
              variant='outlined'
              label='Reason'
              onChange={(e) => {
                resetFields();
                setWithdrawReason(e.target.value);
              }}
              input={<Input id='refundReason' name='refundReason' style={styles.TODO} />}
            >
              <MenuItem key='placeholder' value={SELECT_INITIAL_VALUE} disabled={true}>Reasons*</MenuItem>
              {reasons.filter((reason) => { return !studentCourse.change_of_previous_enrollment_id || reason.id !== CHANGE_IN_REGISTRATION_REASON; }).map((reason) => {
                return <MenuItem key={reason.id} value={reason.id}>{reason.reason}</MenuItem>;
              })}
            </TextField>
          </FormControl>
          {_withdrawReason === CHANGE_IN_REGISTRATION_REASON &&
              <FormControl style={{...styles.formControlRootReason, marginLeft: 15}}>
                <FormLabel style={styles.dateLabel} component='legend'>New course</FormLabel>
                <TextField
                  select
                  variant='outlined'
                  label='Course'
                  style={{...styles.formControl, ...styles.textField}}
                  value={_changeInRegistrationCourse}
                  onChange={(e) => setChangeInRegistrationCourse(e.target.value)}
                  input={<Input id='courseChangeInRegistration' name='courseChangeInRegistration' />}
                >
                  {[
                    <MenuItem key='placeholder' value={SELECT_INITIAL_VALUE} disabled={true}>Course*</MenuItem>,
                    coursesOffered.filter((course) =>
                      course.code.toLowerCase() !== studentCourse.code.toLowerCase() &&
                      !course.code.includes('-') &&
                      !_activeCourses.includes(course.code)
                    ).map((course) => <MenuItem key={course.id} value={course.id}>{`${course.code}: ${course.title}`}</MenuItem>),
                  ]}
                </TextField>
              </FormControl>
          }
        </div>
        <div style={styles.formRowReason}>
          <FormControl style={styles.formControlRoot}>
            <FormLabel style={styles.dateLabel} component='legend'>Notes {_withdrawReason === OTHER_REASON ? `(required)` : `(optional)`}</FormLabel>
            <TextField
              style={{...styles.textField, width: '100%'}}
              label='Notes'
              required={_withdrawReason === OTHER_REASON}
              variant='outlined'
              value={_withdrawNotes}
              onChange={(e) => setWithdrawNotes(e.target.value)}
              margin='dense'
              multiline
              minRows={4}
            />
          </FormControl>
        </div>
        <div style={styles.dividingLine}></div>
        {loadingStates.fetchRefundEligibility && <CircularProgress/>}
        {_withdrawReason !== '' && !(_withdrawReason === CHANGE_IN_REGISTRATION_REASON && !_changeInRegistrationCourse) && !loadingStates.fetchRefundEligibility &&
          <>
            {refundMessage(refundEligibility)}
          </>
        }
        {(((!!admin.perms[ENROLLMENT_OVERRIDE] || !!admin.perms[DEVELOPER]) && _withdrawReason !== '') && !(_withdrawReason === CHANGE_IN_REGISTRATION_REASON && studentCourse.institution_campus_partner_agreement_id !== null)) &&
        <div style={styles.formRow}>
          <FormControlLabel
            control={
              <Checkbox
                checked={_overrideRefundEligibility}
                onChange={() => {
                  if (_overrideRefundEligibility) {
                    resetFields();
                  }
                  setOverrideRefundEligibility(!_overrideRefundEligibility);
                }}
                name='overrideRefundEligibility'
                color='primary'
              />
            }
            label='Override (Out of policy)'
          />
        </div>}
        {_overrideRefundEligibility &&
          <>
            <FormControl style={styles.formControlRootGrade}>
              <FormLabel style={styles.dateLabel} component='legend'>Final Letter Grade</FormLabel>
              <TextField
                select
                style={{...styles.formControlOverride, ...styles.textField}}
                value={_customFinalLetterGrade}
                variant='outlined'
                label='Final Letter Grade'
                onChange={(e) => setCustomFinalLetterGrade(e.target.value)}
                input={<Input id='finalLetterGrade' name='finalLetterGrade' style={styles.TODO} />}
              >
                <MenuItem key='placeholder' value={SELECT_INITIAL_VALUE} disabled={true}>Grades</MenuItem>
                {FINAL_LETTER_GRADES.map((grade) => {
                  return <MenuItem key={grade} value={grade}>{grade}</MenuItem>;
                })}
              </TextField>
            </FormControl>
            <br/>
            <br/>
            {studentCourse.transactions_enrollments.transactions[0].amount > 0 &&
            <FormControl style={styles.formControlRootGrade}>
              <FormLabel style={styles.dateLabel} component='legend'>Custom Refund Amount</FormLabel>
              <TextField
                style={{...styles.formControlOverride, ...styles.textField}}
                value={_customRefundAmount}
                variant='outlined'
                label='Refund Amount'
                placeholder='$0.00'
                onChange={(e) => {
                  if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {
                    setCustomRefundAmount(e.target.value);
                  }
                }}
                onBlur={() => setCustomRefundAmount(parseFloat(_customRefundAmount).toFixed(2))}
                input={<Input id='customRefundAmount' name='customRefundAmount' style={styles.TODO} />}
              >
              </TextField>
              <p>Max refund amount: <strong>${refundEligibility.max_refund_amount}</strong></p>
            </FormControl>
            }
          </>
        }
      </DialogContent>
      <div style={styles.confirmButtonWrapper}>
        {!(_withdrawReason === CHANGE_IN_REGISTRATION_REASON && studentCourse.institution_campus_partner_agreement_id !== null) &&
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            disabled={(_withdrawReason === '') ||
              (_withdrawReason === CHANGE_IN_REGISTRATION_REASON && !_changeInRegistrationCourse) ||
              (_overrideRefundEligibility && (_customFinalLetterGrade === '' && _customRefundAmount === '')) ||
              (_withdrawReason === OTHER_REASON && _withdrawNotes === '') ||
              (refundEligibility.manual_enrollment === 1 && _customFinalLetterGrade === '') ||
              (refundEligibility.change_in_registration_ineligible_message !== null && _customFinalLetterGrade === '')
            }
            onClick={() => {
              if (_customRefundAmount > parseInt(refundEligibility.max_refund_amount)) {
                setRefundAmountError(true);
              } else {
                _withdrawStudent(
                  studentCourse.id,
                  _changeInRegistrationCourse,
                  studentCourse.course_id,
                  _customFinalLetterGrade !== '' ? _customFinalLetterGrade : refundEligibility.withdrawal_grade,
                  _withdrawNotes,
                  _withdrawReason,
                  parseInt(refundEligibility.discount_amount),
                  refundEligibility.discount_code,
                  () => {
                    if (_customRefundAmount > 0 || refundEligibility.refund_amount > 0) {
                      dispatch(requestRefund(
                        studentCourse.id,
                        _customRefundAmount !== '' ? parseFloat(_customRefundAmount) : refundEligibility.refund_amount,
                        _withdrawReason,
                        _withdrawNotes,
                        () => {
                          dispatch(fetchStudentCourses(student.id));
                        }
                      ));
                    }
                    dispatch(fetchStudentCourses(student.id));
                  }
                );
                _confirmWithdrawDialogClose();
              }
            }}
          >
        Confirm withdraw
          </Button>
          {loadingStates.withdrawStudent && <CircularProgress size={30} />}
          <Button
            color='secondary'
            onClick={() => {
              _confirmWithdrawDialogClose();
            }}
          >
        Close
          </Button>
          {_refundAmountError && <span style={{color: Globals.colors.error, width: 300}}>**You cannot refund more than the original cost the student paid for their course.</span>}

        </DialogActions>
        }
      </div>
    </Dialog>
  );
};

WithdrawDialog.propTypes = {
  createdAt: PropTypes.any,
  code: PropTypes.any,
  studentCourse: PropTypes.object,
  student: PropTypes.object,
  setWithdrawDialog: PropTypes.func,
  isWithdrawOpen: PropTypes.bool,
};

export default WithdrawDialog;
