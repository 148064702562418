/*
 * Ryan O'Dowd
 * 2020-04-29
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import React,
{
  useEffect,
  useState,
} from 'react';
import {
  fetchCourses,
  fetchSyllabiLinks,
  uploadSyllabus,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  DEVELOPER,
} from '../../Globals';
import styles from './styles';

const Syllabi = () => {
  const [_newCourseDialogOpen, setNewCourseDialogOpen] = useState(false);
  const [_newCourseUploadCode, setNewCourseUploadCode] = useState('');
  const [_uploadCourseDialogIsOpen, setUploadCourseDialogIsOpen] = useState(false);
  const [_courseCodeForUpload, setCourseCodeForUpload] = useState('');
  const [_pdfToUpload, setPdfToUpload] = useState(null);
  const [_fileName, setFileName] = useState('');
  const _courseCodeRegex = /\/([a-zA-z0-9-?]+)\.pdf/;
  const courses = useSelector((state) => state.courses);
  const syllabiLinks = useSelector((state) => state.syllabiLinks);
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSyllabiLinks());
    dispatch(fetchCourses());
  }, [dispatch]);

  const cancelUpload = () => {
    setUploadCourseDialogIsOpen(false);
    setNewCourseDialogOpen(false);
    setNewCourseUploadCode('');
    setCourseCodeForUpload('');
    setPdfToUpload(null);
    setFileName('');
  };
  const _getCourseCodeFromLink = (link) => {
    const courseCode = _courseCodeRegex.exec(link);
    if (courseCode) {
      return courseCode[1];
    }
  };

  const renderUploadDialog = (syllabusLink) => {
    const courseCode = _courseCodeRegex.exec(syllabusLink)[1];
    return (
      <Grid key={syllabusLink} item xs={12} sm={6} md={4} lg={3}>
        <Card>
          <CardContent style={styles.cardContent}>
            <h3>{courseCode.replace(/([0-9])/, ' $1').toUpperCase()}</h3>
          </CardContent>
          <CardActions>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                setUploadCourseDialogIsOpen(true);
                setCourseCodeForUpload(courseCode);
              }}
            >
                Upload new
            </Button>
            <span style={{flex: 1, flexGrow: 1}}></span>
            <Button
              color='secondary'
              href={syllabusLink}
            >
                View current
            </Button>
          </CardActions>
        </Card>
      </Grid>
    );
  }
  return (
    <div style={styles.container}>
      <h3>Syllabi</h3>
      {!!admin.perms[DEVELOPER] &&
              <div style={styles.newCourseButton}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    setNewCourseDialogOpen(true);
                    setUploadCourseDialogIsOpen(true);
                  }}
                >
                  Add New Course
                </Button>
              </div>
      }
      <h5>Portage Learning</h5>
      <Grid
        container={true}
        spacing={2}
      >
        {syllabiLinks.filter((link) => Object.values(courses).find((c) => c.code === _getCourseCodeFromLink(link))).map((link) => renderUploadDialog(link))}
      </Grid>
      <Dialog
        open={_uploadCourseDialogIsOpen}
        onClose={() => cancelUpload()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{_newCourseDialogOpen ? 'Add New Course Syllabus' : `Update ${_newCourseUploadCode.toUpperCase()} syllabus`}</DialogTitle>
        <DialogContent>
          {_newCourseDialogOpen
            ? (
              <TextField
                id='Course Code'
                label='New Course Code'
                required
                inputProps={{maxLength: 12}}
                onChange={(e) => setNewCourseUploadCode(e.target.value)}
                value={_newCourseUploadCode.toLowerCase()}
              />
            )
            : null}
          <p>Please select the PDF to upload.</p>
          <Button
            variant='contained'
            component='label'
            color='primary'
          >
            Choose File
            <input
              type='file'
              id='upload-button'
              accept='application/pdf'
              style={styles.inputField}
              onChange={(e) => {
                const fileReader = new FileReader();
                const fileName = e.target.files[0].name;
                setFileName(fileName);
                fileReader.readAsDataURL(e.target.files[0]);
                fileReader.onload = (e) => {
                  setPdfToUpload(e.target.result, fileName);
                };
              }}
            />
          </Button>
          <p>
            {_fileName}
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            disabled={!_pdfToUpload}
            onClick={() => dispatch(uploadSyllabus(_newCourseDialogOpen ? _newCourseUploadCode : _courseCodeForUpload, _pdfToUpload,
              () => {
                setUploadCourseDialogIsOpen(false);
                setFileName('');
                setPdfToUpload('');
                setNewCourseUploadCode('');
                dispatch(fetchSyllabiLinks());
              }))}
          >
                  Upload
          </Button>
          <Button
            color='secondary'
            onClick={() => cancelUpload()}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Syllabi;
