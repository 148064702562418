/*
 * Nathaniel Padgett
 * 2021-01-08
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {
  createNewConfirmedSchool,
  fixUnknownSchool,
} from '../../../actions';
import InfinityIcon from 'mdi-react/InfinityIcon';
import KnownSchoolsTextField from '../KnownSchoolsTextField';
import {
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import provinces from 'provinces';
import styles from './styles';

const PROVINCE_COUNTRY_US = 'US';
const US_STATES = provinces.filter((value) => value.country === PROVINCE_COUNTRY_US);
const UnknownSchoolCard = (props) => {
  const [_otherSchools, setOtherSchools] = React.useState([]);
  const [_schoolName, setSchoolName] = React.useState('');
  const [_addNewSchoolDialog, setAddNewSchoolDialog] = React.useState(false);
  const [_newSchoolName, setNewSchoolName] = React.useState(props.school.name);
  const [_newCityName, setNewCityName] = React.useState(props.school.city);
  const [_newStateName, setNewStateName] = React.useState(props.school.state);
  const [_makeAllUnknownSchoolNewSchool, setMakeAllUnknownSchoolNewSchool] = React.useState(true);

  const getSchoolColor = (jaroWinklerScore) => {
    if (jaroWinklerScore === null) {
      return `hsl(56, 2%, 50%)`;
    }

    const normalizedScore = (jaroWinklerScore - 0.8) / (1 - 0.8); // @TODO: magic
    const hue = (normalizedScore * 120).toString(10);

    return `hsl(${hue},100%,50%)`;
  };

  return (
    <>
      <Grid key={props.school.id} item xs={12} sm={6} md={4} lg={3}>
        <div style={styles.cardWrapper}>
          <Card>
            <CardContent style={styles.cardContent}>
              {/* @TODO: suggestions with options for "accept" and "always accept" */}
              {/* @TODO: pull into new _render function...if not sure about automatic, have buttons/edit fields with select dropdown */}
              <div>
                <span style={styles.schoolName}>{props.school.name}</span>
                <span style={styles.schoolCityAndState}>{`(${props.school.city}, ${props.school.state})`}</span>
                <span style={styles.studentLinks}>
                  {props.school.student_ids.map((studentId) => {
                    return <Link key={studentId} style={styles.studentLink} to={`/students/${studentId}`}>{studentId}</Link>;
                  })}
                </span>
                {[...props.school.suggestions, ..._otherSchools].map((suggestion) => {
                  // @TODO: key should be suggestion.id
                  // @TODO: say when it's a match
                  const jwColor = getSchoolColor(suggestion.jw_score);
                  return (
                    <Chip
                      key={`${props.school.id}_${suggestion.known_school_id}`}
                      style={{
                        ...styles.chip, color: jwColor, borderColor: jwColor,
                      }}
                      classes={{label: 'label_text'}}
                      avatar={<Avatar title={'Match confidence score'} style={{...styles.jwScore, backgroundColor: jwColor}}>{suggestion.jw_score === null ? '-' : Math.floor(suggestion.jw_score * 100)}</Avatar>}
                      variant='outlined'
                      label={<span style={styles.chipTitle}>{suggestion.known_school_name}<br />{`(${suggestion.known_school_city}, ${suggestion.known_school_state})`}</span>}
                      clickable
                      onClick={() => props.fixUnknownSchool(props.school.id, suggestion.known_school_id)}
                      deleteIcon={<InfinityIcon title={'Always update this input to match this school.'} color={jwColor} />}
                      onDelete={() => props.fixUnknownSchool(props.school.id, suggestion.known_school_id, true)}
                    />
                  );
                })}
                <KnownSchoolsTextField
                  knownSchools={props.knownSchools}
                  schoolNameInput={_schoolName}
                  noOptionsText='No Schools but use button to add as new school'
                  onInputChange={(e) => {
                    if (e) {
                      setSchoolName(e.target.value);
                    }
                  }}
                  onChange={(e, value, reason) => {
                    if (value) {
                      setOtherSchools([..._otherSchools, {
                        known_school_name: value.name,
                        known_school_city: value.city,
                        known_school_state: value.state,
                        known_school_id: value.id,
                        jw_score: null,
                      }]);
                      setSchoolName('');
                    }
                  }}
                />
              </div>
            </CardContent>
            <CardActions>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  setAddNewSchoolDialog(true);
                }}
              >
                Add as New School
              </Button>
            </CardActions>
          </Card>
        </div>
      </Grid>
      {_addNewSchoolDialog &&
        <Dialog
          open={true}
          onClose={() => {
            setAddNewSchoolDialog(false);
          }}
          maxWidth='md'
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle>Add New School</DialogTitle>
          <DialogContent>
            <p style={styles.maxWidth}>Please review new school information below and make sure that it is not in the current school dropdown before submitting.</p>
            <div style={styles.formRow}>
              <TextField
                style={styles.textField}
                label='New School Name'
                value={_newSchoolName}
                onChange={(e) => setNewSchoolName(e.target.value)}
                margin='dense'
              />
              <TextField
                style={styles.textField}
                label='City'
                value={_newCityName}
                onChange={(e) => setNewCityName(e.target.value)}
                margin='dense'
              />
              <FormControl>
                <InputLabel htmlFor='state'></InputLabel>
                <Select
                  style={styles.stateSelectField}
                  value={_newStateName}
                  onChange={(e) => setNewStateName(e.target.value)}
                  input={<Input id='state' name='state' style={styles.registerInputField} />}
                >
                  <MenuItem key='placeholder' value='' disabled={true}>State*</MenuItem>
                  {US_STATES.map((stateOrProvince) => <MenuItem key={stateOrProvince.name} value={stateOrProvince.short}>{stateOrProvince.short}</MenuItem>)}
                  <MenuItem key='PAW' value='PAW'>PAW</MenuItem>
                  <MenuItem key='PAE' value='PAE'>PAE</MenuItem>
                  <MenuItem key='NYC' value='NYC'>NYC</MenuItem>
                  <MenuItem key='NYS' value='NYS'>NYS</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={styles.formRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={_makeAllUnknownSchoolNewSchool}
                    onChange={() => {
                      setMakeAllUnknownSchoolNewSchool(!_makeAllUnknownSchoolNewSchool);
                    }}
                    name=''
                    color='primary'
                  />
                }
                label='Automatically link all schools spelled this way in the future to this new school'
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                props.createNewConfirmedSchool(_newSchoolName, _newCityName, _newStateName, _makeAllUnknownSchoolNewSchool, props.school.id, false, () => {
                  setAddNewSchoolDialog(false);
                });
              }}
              variant='contained'
              color='primary'
            >
              Confirm New School
            </Button>
            <Button
              onClick={() => {
                setAddNewSchoolDialog(false);
              }}
              color='secondary'
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
};

UnknownSchoolCard.propTypes = {
  school: PropTypes.object.isRequired,
  knownSchools: PropTypes.array.isRequired,
  createNewConfirmedSchool: PropTypes.func.isRequired,
  fixUnknownSchool: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    createNewConfirmedSchool,
    fixUnknownSchool,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UnknownSchoolCard);
