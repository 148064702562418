/*
 * Ally Zernick
 * 2024-07-26
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from "../../Globals";

const styles = {
  schoolContainer: {
    display: 'flex',
    gap: '10px',
  },
  campusInput: {
    minWidth: '300px',
    width: 'fit-content',
    marginBottom: '10px',
  },
  campusContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
  },
  bottomSpacing: {
    marginBottom: '10px',
  },
  verticalSpacing: {
    margin: '50px 0px',
  },
  errorText: {
    color: Globals.colors.error,
    fontWeight: 'bold',
  },
  createCampusButton: {
    width: 'max-content',
    marginLeft: 'auto'
  },
  largeBottomSpacing: {
    marginBottom: '100px',
  },
  quarterWidth: {
    width: '25%',
  }
};

export default styles;
