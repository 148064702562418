/*
 * Stevie Michalik
 * 2024-06-13
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */

const styles = {
  searchAndSelectContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: '20px',
    marginBottom: '20px',
    height: '80px',
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    padding: 20,
  },
  buttonsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    gap: 6,
  },
};

export default styles;
