/*
 * Nathaniel Padgett
 * 2021-07-28
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */
const DEV_NOTICE_PADDING = 4;
const DEV_NOTICE_FONT_SIZE = 12;

const styles = {
  devIndicator: {
    backgroundColor: '#f07',
    color: '#fff',
    padding: DEV_NOTICE_PADDING,
    fontSize: DEV_NOTICE_FONT_SIZE,
    fontWeight: '600',
    textAlign: 'center',
    width: '100vw',
    position: 'fixed',
    zIndex: 1111,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  devSpacer: {
    height: 2 * DEV_NOTICE_PADDING + DEV_NOTICE_FONT_SIZE + 2, // @TODO: 2 is magic
  },
  stopMasqueradingButton: {
    position: 'absolute',
    right: 0,
    margin: 'auto',
  },
};

export default styles;
