/**
 * Ryan O'Dowd
 * 2018-12-17
 * © Copyright 2018 NursingABC, Inc. All Rights Reserved.
 */
import {
  applyMiddleware,
  createStore,
} from 'redux';
import {
  persistReducer,
  persistStore,
} from 'redux-persist';
import apiAuthorizationMiddleware from './middleware/apiAuthorizationMiddleware';
import {
  apiMiddleware,
} from 'redux-api-middleware';
import rootReducer from './reducers';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'adminPanelRoot',
  storage,
  blacklist: [
    'apiGetS3ShareLink',
    's3Actions',
    'students',
    'unassignedStudentIds',
    'grades', // @TODO: restore this once it's stable
    'registrations',
    'canvasCourses',
    'inFlightCalls',
    'knownSchools',
    'unknownSchools',
    'studentCourses', // @TODO: rename to enrollments
    'loadingStates',
    'transcriptRequests',
    'displayStudentProgressData',
    'fetchStudent',
    'displayStudentsWithLastName',
    'partnerStudents',
    'lowGradeWarnings',
  ], // @NOTE: these reducers are too big or shouldn't be included in persistent storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  const store = createStore(persistedReducer, {}, applyMiddleware(thunk, apiAuthorizationMiddleware, apiMiddleware));
  const persistor = persistStore(store);

  // @NOTE: uncomment when store needs reset
  // persistor.purge();

  return {
    store,
    persistor,
  };
};

export default configureStore;
