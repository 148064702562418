/*
 * Nathaniel Padgett
 * 2021-02-10
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */

const styles = {
  textField: {
    width: '100%',
    marginRight: 8,
  },
  addCourseWrapper: {
    display: 'flex',
    marginTop: 16,
    marginBottom: 16,
  },
  addCourseSelectField: {
    display: 'flex',
    flexGrow: 1,
  },
};

export default styles;
