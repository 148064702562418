/*
 * Ally Zernick
 * 2022-08-08
 * © Copyright 2023 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Tooltip,
} from '@material-ui/core';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  createPartnerStudents,
  fetchObservers,
  fetchPartnerAgreements,
  fetchTermsByPartnerAgreement,
  importPartnerStudents,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import AlertIcon from 'mdi-react/AlertIcon';
import {
  Link,
} from 'react-router-dom';
import NewBox from 'mdi-react/NewBoxIcon';
import PropTypes from 'prop-types';
import RefreshIcon from 'mdi-react/RefreshIcon';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import moment from 'moment';
import styles from './styles';

const PartnerStudentImport = (props) => {
  const [_instructor, setInstructor] = useState([]);
  const [_observer, setObserver] = useState('');
  const [_duplicateAccountDialogOpen, setDuplicateAccountDialogOpen] = useState(false);
  const [_institutionCampusId, setInstitutionCampusId] = useState('');
  const [_institutionCampusPartnerAgreementId, setInstitutionCampusPartnerAgreementId] = useState('');
  const [_termId, setTermId] = useState('');
  const [_studentDuplicate, setStudentDuplicate] = useState();
  const terms = useSelector((state) => state.terms);
  const schools = useSelector((state) => state.partnerAgreements);
  const observers = useSelector((state) => state.observers);
  const studentsToImport = useSelector((state) => state.partnerStudents);
  const [_studentsToImport, setStudentsToImport] = useState(studentsToImport);
  const dispatch = useDispatch();
  const [_emailsToBeSent, setEmailsToBeSent] = useState([]);

  useEffect(() => {
    dispatch(fetchPartnerAgreements());
  }, [dispatch]);

  useEffect(() => {
    setStudentsToImport(studentsToImport);
  }, [studentsToImport]);

  const _removeStudentFromImport = (event, studentEmail, studentCourse) => {
    setStudentsToImport([..._studentsToImport.filter((e) => e !== _studentsToImport.find((i) => i.email === studentEmail && i.course === studentCourse))]);
    setInstructor([..._instructor.filter((e) => e !== _instructor.find((i) => i.email === studentEmail && i.course === studentCourse))]);
  };

  const handleSelectedSchoolChange = (event) => {
    setInstitutionCampusPartnerAgreementId(event.partner_agreement_id);
    setInstitutionCampusId(event.institution_campus_id);
  };

  const handleDuplicateIdRadioButton = (event, student) => {
    _studentsToImport.map((s) => {
      if (s.possible_duplicates) {
        const studentEmail = s.possible_duplicates.filter((d) => d.id.toString() === event.target.value);
        if (studentEmail.length > 0) {
          const email = [..._emailsToBeSent, {studentId: studentEmail[0].id, email: studentEmail[0].email}];
          setEmailsToBeSent(email);
        }
      }
    });

    const obj = ({...student, current_student_id: event.target.value});
    setStudentsToImport([..._studentsToImport.filter((s) => s !== _studentsToImport.find((i) => i.email === student.email && i.course === student.course)), obj]);
  };

  const handleSelectedTermChange = (event) => {
    setTermId(event.id);
  };

  const openDuplicateAccountDialog = () => {
    return (
      <Dialog
        open={_duplicateAccountDialogOpen}
        onClose={() => setDuplicateAccountDialogOpen(false)}
      >
        <DialogTitle>Duplicate Account</DialogTitle>
        <DialogContent>
          <div>
            <span>This student may have a duplicate account with the following Student ID(s): </span>
            {_studentDuplicate.possible_duplicates.map((s) => {
              return (
                <Link to={`/students/${s.id}`} target='_blank' key={s.id} style={{cursor: 'pointer'}}> <u> {s.id}, </u></Link>
              );
            })}
            <span>If this student already has an account with any of the below IDs please choose the ID you would like to add this enrollment to.</span><br/><br/>
            <span>If this recognized duplicate is a new student please choose <i>New Student</i></span><br/><br/>
            <FormControl component='fieldset'>
              <RadioGroup
                row
                aria-label='position'
                name='position'
                defaultValue={0}
                onChange={(e) => handleDuplicateIdRadioButton(e, _studentDuplicate)}
              >
                {_studentDuplicate.possible_duplicates.map((d) => {
                  return (
                    <FormControlLabel
                      key={d.id}
                      control={<Radio color='primary' />}
                      value={d.id.toString()}
                      label={d.id.toString()}
                    />
                  );
                })}
                <FormControlLabel
                  value={'0'}
                  control={<Radio color='primary' />}
                  label={'New Student'}
                />
              </RadioGroup>
            </FormControl>
          </div>

          <Button
            style={{marginTop: 10}}
            variant='contained'
            color='primary'
            onClick={() => setDuplicateAccountDialogOpen(false)}
          >
                      Return
          </Button>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div>
      <Button
        style={{marginBottom: 5}}
        variant='contained'
        color='primary'
        component='label'
      >
        Import Students
        <input
          type='file'
          id='upload-button'
          accept='.xlsx'
          hidden={true}
          onChange={(i) => {
            const file = i.target.files[0];
            dispatch(importPartnerStudents(file));
          }}
        />
      </Button>
      <div style={styles.partnerFormContainer}>
        <div style={styles.partnerFormContent}>
          <span style={styles.partnerFormLabel}>SCHOOL/INSTITUTION</span>
          <Select
            style={styles.selectSchool}
            variant='outlined'
            defaultValue={_institutionCampusPartnerAgreementId}
            onChange={(e) => {
              handleSelectedSchoolChange(e.target.value);
              dispatch(fetchObservers(e.target.value.partner_agreement_id));
              dispatch(fetchTermsByPartnerAgreement(e.target.value.partner_agreement_id));
            }}
          >
            {schools.length > 0 && schools.map((s) => {
              return <MenuItem key={s.id} value={s}>{s.school_name}</MenuItem>;
            })}
          </Select>
        </div>
        <div style={styles.partnerFormContent}>
          <span style={styles.partnerFormLabel}>TERM</span>
          <Select
            style={styles.selects}
            disabled={_institutionCampusPartnerAgreementId === ''}
            variant='outlined'
            onChange={(e) => handleSelectedTermChange(e.target.value)}
          >
            {terms.length > 0 && terms.map((t) => {
              return <MenuItem key={t.id} value={t}>{t.term_name}</MenuItem>;
            })}
          </Select>
        </div>
        <div style={styles.partnerFormContent}>
          <span style={styles.partnerFormLabel}>OBSERVER</span>
          <Select
            style={styles.selects}
            defaultValue={_observer}
            disabled={_institutionCampusPartnerAgreementId === ''}
            variant='outlined'
            onChange={(e) => setObserver(e.target.value)}
          >
            {observers.length > 0 && observers.map((o) => {
              return <MenuItem key={o.id} value={o.id}>{o.first_name} {o.last_name}</MenuItem>;
            })}
          </Select>
        </div>
      </div>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Program</TableCell>
              <TableCell>Instructor</TableCell>
            </TableRow>
          </TableHead>
          {_studentsToImport.length > 0 && _studentsToImport.map((student) => {
            return (
              <TableBody style={{backgroundColor: student.possible_duplicates !== null && student.current_student_id === null ? '#FFE6E6' : student.current_student_id !== null ? '#E6FFE6' : ''}} key={`${student.email} ${student.course}`}>
                {_duplicateAccountDialogOpen && openDuplicateAccountDialog()}
                <TableCell>
                  {student.first_name}
                  {student.possible_duplicates &&
                  <span
                    style={styles.duplicateAlert}
                    onClick={() => {
                      setDuplicateAccountDialogOpen(true);
                      setStudentDuplicate(student);
                    }}
                  ><AlertIcon/>
                  </span>}
                  {student.current_student_id === '0' ? <NewBox style={styles.duplicateCheck}/> : student.current_student_id > 0 ? <Tooltip placement='top' title={'Student ID: ' + student.current_student_id}><span><RefreshIcon style={styles.duplicateCheck}/></span></Tooltip> : ''}
                </TableCell>
                <Tooltip
                  placement='top'
                  title= {
                    'Birthday: ' + student.date_of_birth +
                ' | ' +
                'Address: ' +
                student.address_line_1 +
                ', ' +
                student.city +
                ', ' +
                student.state +
                ' ' +
                student.zip_code
                  }
                ><TableCell>{student.last_name}</TableCell>
                </Tooltip>
                <TableCell>{student.email}</TableCell>
                <TableCell>{student.phone_number}</TableCell>
                <TableCell>{student.course}</TableCell>
                <TableCell>{student.start_date}</TableCell>
                <TableCell>{student.end_date}</TableCell>
                <TableCell>{student.program}</TableCell>
                <TableCell>
                  <Select
                    style={styles.selectInstructor}
                    defaultValue={_instructor}
                    variant='outlined'
                    onChange={(e) => {
                      setInstructor([..._instructor.filter((e) => e !== _instructor.find((i) => i.email === student.email && i.course === student.course)), {
                        email: student.email,
                        course: student.course,
                        instructor: e.target.value,
                      }]);
                    }}
                  >
                    {(student.instructors).map((i) => {
                      return <MenuItem key={i.id} value={i.id}>{i.first_name} {i.last_name}</MenuItem>;
                    })}
                  </Select>
                </TableCell>
                <TableCell
                  onClick={((e) => { _removeStudentFromImport(e, student.email, student.course); })}
                >
                  <span style={{cursor: 'pointer'}}><RemoveIcon/></span>
                </TableCell>
              </TableBody>
            );
          })}
        </Table>
      </Paper>
      <div style={styles.submitContainer}>
        <Button
          style={styles.submitButton}
          disabled={!_institutionCampusPartnerAgreementId || !_termId || _instructor.length === 0 || _instructor.length !== _studentsToImport.length}
          onClick={() => {
            const studentList = _studentsToImport.map((student) => {
              let studentEmailThatNeedsToBeSent = '';
              // test
              if (student.current_student_id && student.current_student_id !== '0') {
                const se = _emailsToBeSent.find((e) => e.studentId.toString() === student.current_student_id.toString());
                studentEmailThatNeedsToBeSent = se.email;
              } else { studentEmailThatNeedsToBeSent = student.email; }
              return {
                current_student_id: student.current_student_id,
                first_name: student.first_name,
                middle_initial: student.middle_initial,
                last_name: student.last_name,
                date_of_birth: student.date_of_birth,
                email: studentEmailThatNeedsToBeSent,
                phone_number: student.phone_number,
                address_line_1: student.address_line_1,
                address_line_2: student.address_line_2,
                city: student.city,
                state: student.state,
                zip_code: student.zip_code,
                course: student.course,
                program: student.program,
                term_id: _termId,
                start_date: student.start_date,
                end_date: student.end_date,
                observer: _observer,
                institution_campus_id: _institutionCampusId,
                institution_campus_partner_agreement_id: _institutionCampusPartnerAgreementId,
                instructor: _instructor.find((i) => i.email === student.email && i.course === student.course).instructor,
              };
            });
            dispatch(createPartnerStudents(studentList));
            setStudentsToImport('');
          }
          }
          variant='contained'
          color='primary'
        >
          Submit
        </Button>
        <Button
          onClick={() => {
            setStudentsToImport('');
          }}
          style={styles.exportButton}
          variant='contained'
          color='disabled'
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

PartnerStudentImport.propTypes = {
  admin: PropTypes.object,
  history: PropTypes.object,
};

export default (PartnerStudentImport);
