/*
 * Ryan O'Dowd
 * 2019-01-08
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
// @TODO: hard cap bars aren't filled in...just an outline?
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Globals, {
  DEVELOPER,
} from '../../Globals';
import {
  fetchInstructorAssignmentRules,
  setTemporaryOverrideForInstuctor,
  simulateRegistrations,
} from '../../actions';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';
import {
  ResponsiveBar,
} from '@nivo/bar';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import moment from 'moment';
import styles from './styles';
import {
  withRouter,
} from 'react-router-dom';

const AUTO_ASSIGNED_DEPARTMENTS = ['English', 'Psychology']; // @TODO: remove when they're all auto-assigned

class InstructorAssignment extends React.Component {
  static propTypes = {
    fetchInstructorAssignmentRules: PropTypes.func.isRequired,
    instructorAssignmentRules: PropTypes.object.isRequired,
    setTemporaryOverrideForInstuctor: PropTypes.func.isRequired,
    simulateRegistrations: PropTypes.func.isRequired,
    admin: PropTypes.shape({
      perms: PropTypes.object.isRequired,
    }),
  }

  constructor(props) {
    super(props);

    const [startMonth, startYear] = this._getCurrentPayPeriodStartMonthAndYear();
    this.state = {
      overrideDialogOpen: false,
      instructorsForOverrideDialog: [],
      rangeStartYear: startYear,
      rangeStartMonth: startMonth,
      simulationOn: false,
    };
  }

  componentDidMount() {
    this.props.fetchInstructorAssignmentRules(this.state.rangeStartMonth, this.state.rangeStartYear);
  }

  _getCurrentPayPeriodStartMonthAndYear() {
    let startMonth;
    let startYear = moment().year();

    const thisSixteenth = moment().day(16);
    if (moment().valueOf() >= thisSixteenth.valueOf()) {
      // we're in the month of the start of the current pay period
      startMonth = thisSixteenth.month() + 1; // @NOTE: +1 because moment months are zero-indexed while datetime months are 1-indexed
    } else {
      // we're in the month after the start of the current pay period
      startMonth = thisSixteenth.month() || 12; // @NOTE: don't forget that month is zero-indexed
      if (startMonth === 12) {
        startYear = moment().year() - 1;
      }
    }

    return [startMonth, startYear];
  }

  _getStateKeyForSimulatedCounts(division, departmentId) {
    return `totalSimulatedRegistrationCounts${division}${departmentId}`;
  }

  _renderExpandableGraphContainer(title, instructorAssignmentRules, departmentId, currentPayPeriodSelected) {
    return (
      <Accordion key={title} defaultExpanded={AUTO_ASSIGNED_DEPARTMENTS.includes(title)} style={{...styles.panel, ...(this.state.simulationOn ? styles.simulationGraphContainer : {})}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <h2 style={styles.graphTitle}>{title}</h2>
        </AccordionSummary>
        <AccordionDetails style={styles.panelDetails}>
          <div style={styles.actionsWrapper}>
            <Button
              style={styles.button}
              variant='contained'
              color='primary'
              onClick={() => this.setState({
                overrideDialogOpen: true,
                instructorsForOverrideDialog: instructorAssignmentRules.map((i) => { return {...i}; }),
              })}
            >
              {this._inCurrentPayPeriod() && this.props.admin.perms[DEVELOPER] ? 'Instructor Overrides' : 'Table View'}
            </Button>
          </div>
          {this.state.simulationOn &&
            <div style={styles.simulation}>
              {[1, 10, 25, 100, 500].map((numToAddToSimulation) => {
                return (
                  <Button
                    key={numToAddToSimulation}
                    style={styles.button}
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      // if title has an integer in it, then it's a NABC course
                      const division = /\d/.test(title) ? 'nabc' : 'portage';
                      const department = title.toLowerCase();
                      const stateKey = this._getStateKeyForSimulatedCounts(division, department);
                      const newTotalRegistraionCount = (this.state[this._getStateKeyForSimulatedCounts(division, department)] || 0) + numToAddToSimulation;
                      // @TODO: this won't work for nabc because division names are different
                      this.props.simulateRegistrations(departmentId, newTotalRegistraionCount, this.state.rangeStartMonth, this.state.rangeStartYear);
                      this.setState({[stateKey]: newTotalRegistraionCount});
                    }}
                  >
                    {`+${numToAddToSimulation} Registration`}
                  </Button>
                );
              })}
            </div>
          }
          <div style={{...styles.graphContainer, ...(this.state.simulationOn ? styles.simulationGraphContainer : {})}}>
            <AssignmentGraph instructorAssignmentRules={instructorAssignmentRules} currentPayPeriodSelected={currentPayPeriodSelected} />
            <Legend />
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  _inCurrentPayPeriod() {
    const [startMonth, startYear] = this._getCurrentPayPeriodStartMonthAndYear();
    return startMonth === this.state.rangeStartMonth && startYear === this.state.rangeStartYear;
  }

  render() {
    const currentPayPeriodSelected = this._inCurrentPayPeriod();

    let year = 2010;
    const startYearOptions = [];
    while (year <= moment().year()) {
      startYearOptions.push(year);
      year++;
    }
    const startMonthOptions = [
      {number: 1, name: 'January'},
      {number: 2, name: 'February'},
      {number: 3, name: 'March'},
      {number: 4, name: 'April'},
      {number: 5, name: 'May'},
      {number: 6, name: 'June'},
      {number: 7, name: 'July'},
      {number: 8, name: 'August'},
      {number: 9, name: 'September'},
      {number: 10, name: 'October'},
      {number: 11, name: 'November'},
      {number: 12, name: 'December'},
    ];

    const instructorsByDivisionAndDepartment = {};
    Object.values(this.props.instructorAssignmentRules).forEach((rule) => {
      if (!instructorsByDivisionAndDepartment[rule.division]) {
        instructorsByDivisionAndDepartment[rule.division] = {};
      }
      if (!instructorsByDivisionAndDepartment[rule.division][rule.department_name]) {
        instructorsByDivisionAndDepartment[rule.division][rule.department_name] = [];
      }
      instructorsByDivisionAndDepartment[rule.division][rule.department_name].push(rule);
    });
    return (
      <div style={{...styles.container, ...(this.state.simulationOn ? styles.simulationContainer : {})}}>
        <div style={styles.rangeSelection}>
          <span style={styles.rangeText}>Pay period start date:</span>
          <Select
            value={this.state.rangeStartMonth}
            onChange={(e) => {
              this.setState({rangeStartMonth: e.target.value});
              if (moment().valueOf() < moment().month(e.target.value - 1).day(16).year(this.state.rangeStartYear).valueOf()) { // @NOTE: -1 because moment months are zero-indexed
                // this date is in the past...go to last year automatically
                this.setState((prevState) => {
                  return {
                    rangeStartYear: prevState.rangeStartYear - 1,
                  };
                });
              }
            }}
          >
            {startMonthOptions.map(({name, number}) => <MenuItem key={name} value={number}>{name}</MenuItem>)}
          </Select>
          <span style={styles.rangeText}>16,</span>
          <Select
            value={this.state.rangeStartYear}
            onChange={(e) => this.setState({rangeStartYear: e.target.value})}
          >
            {startYearOptions.map((year) => <MenuItem key={year} value={year}>{year}</MenuItem>)}
          </Select>
          <Button
            style={styles.fetchButton}
            color='primary'
            variant='contained'
            size='small'
            onClick={() => this.props.fetchInstructorAssignmentRules(this.state.rangeStartMonth, this.state.rangeStartYear)}
          >
            Go <ChevronRightIcon />
          </Button>
        </div>
        {this.props.admin.perms[DEVELOPER] &&
          <Button
            color='secondary'
            variant='contained'
            onClick={() => {
              if (this.state.simulationOn) {
                this.props.fetchInstructorAssignmentRules(this.state.rangeStartMonth, this.state.rangeStartYear);
                this.setState({simulationOn: false});
              } else {
                this.setState({simulationOn: true});
              }
            }}
          >
            Toggle Simulation
          </Button>
        }
        {Object.keys(instructorsByDivisionAndDepartment).reverse().map((division) => {
          return (
            <React.Fragment key={division}>
              {Object.keys(instructorsByDivisionAndDepartment[division]).map((department) => {
                const departmentId = instructorsByDivisionAndDepartment[division][department][0].department_id;
                const graphTitle = division === 'nabc' ? department.toUpperCase() : `${department[0].toUpperCase()}${department.slice(1)}`;
                if (this.props.admin.perms[DEVELOPER]) { // @TODO: can be another department (probably finance) eventually
                  return this._renderExpandableGraphContainer(graphTitle, instructorsByDivisionAndDepartment[division][department], departmentId, currentPayPeriodSelected);
                }

                return null;
              })}
              <br />{/* @TODO: shouldn't have this for the last section */}
            </React.Fragment>
          );
        })}
        <Dialog
          maxWidth='lg'
          open={this.state.overrideDialogOpen}
          onClose={() => this.setState({overrideDialogOpen: false})}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{currentPayPeriodSelected ? 'Instructor Overrides' : 'Table View'}</DialogTitle>
          <DialogContent>
            {/* @TODO: form */}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Instructor</TableCell>
                  {currentPayPeriodSelected
                    ? (
                      <>
                        <TableCell align='right'>Full-time</TableCell>
                        <TableCell align='right'>Min</TableCell>
                        <TableCell align='right'>Current</TableCell>
                        <TableCell align='right'>Max</TableCell>
                        <TableCell align='right'>Hard cap</TableCell>
                        <TableCell align='right'>Temp max</TableCell>{/* @TODO: tooltip to indicate this month only...will reset on ${next16th} */}
                      </>
                    ) : (
                      <>
                        <TableCell align='right'>Total Students</TableCell>
                        {/* @TODO: pay info for steve only (rate and total owed) */}
                      </>
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {/* @TODO: add tooltips to all these rows */}
                {/* @TODO: make pretty */}
                {/* @TODO: add ability to modify this info (make sure tmp_max_students_override can be zeroed out instead of just set to an integer value too) */}
                {/* @TODO: probably don't need all this info...it's on the graph */}
                {/* @TODO: cron job to run each month to reset tmp_ */}
                {this.state.instructorsForOverrideDialog.map((rule) => (
                  <TableRow key={rule.id}>
                    <TableCell component='th' scope='row'>{rule.instructor_name}</TableCell>
                    {currentPayPeriodSelected
                      ? (
                        <>
                          <TableCell align='right'>{rule.is_full_time ? 'Yes' : 'No'}</TableCell>
                          <TableCell align='right'>{rule.min_students}</TableCell>
                          <TableCell align='right'>{rule.current_students_in_pay_period}</TableCell>
                          <TableCell align='right'>{rule.max_students}</TableCell>
                          <TableCell align='right'>{rule.has_hard_cap ? 'Yes' : 'No'}</TableCell>
                          <TableCell align='right'>
                            {/* @TODO: how to keep these as controlled inputs? */}
                            <TextField
                              style={styles.textField}
                              value={rule.tmp_max_students_override || ''}
                              type='number'
                              onChange={(e) => {
                                const updatedInstructors = this.state.instructorsForOverrideDialog.map((si) => {
                                  if (si.id === rule.id) {
                                    si.tmp_max_students_override = e.target.value;
                                  }
                                  return si;
                                });
                                this.setState({instructorsForOverrideDialog: updatedInstructors});
                              }}
                              margin='normal'
                            />
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell align='right'>{rule.current_students_in_pay_period}</TableCell>
                          {/* @TODO: pay info for steve only (rate and total owed) */}
                        </>
                      )
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            {currentPayPeriodSelected &&
            <Button
              onClick={() => {
                Object.values(this.state.instructorsForOverrideDialog).forEach((rule) => {
                  if (rule.tmp_max_students_override !== this.props.instructorAssignmentRules[rule.id].tmp_max_students_override) {
                    this.props.setTemporaryOverrideForInstuctor(rule.department_id, rule.instructor_id, rule.tmp_max_students_override);
                  }
                });
                this.setState({overrideDialogOpen: false});
              }}
              color='primary'
              variant='contained'
            >
              Save
            </Button>
            }
            <Button
              onClick={() => this.setState({overrideDialogOpen: false})}
              color='secondary'
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.admin,
    instructorAssignmentRules: state.instructorAssignmentRules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchInstructorAssignmentRules,
    setTemporaryOverrideForInstuctor,
    simulateRegistrations,
  }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InstructorAssignment));

const Legend = (props) => {
  const keys = [
    {
      fillColor: 'transparent',
      text: 'full-time',
      fillContent: '*',
      description: 'This instructor is full-time',
    },
    {
      fillColor: Globals.colors.instructorAssignment.min,
      text: 'min',
      description: 'Requested minimumn number of students to be assigned each month',
    },
    {
      fillColor: Globals.colors.accent,
      text: 'current',
      description: 'Number of students currently requested this month',
    },
    {
      fillColor: Globals.colors.instructorAssignment.max,
      text: 'max',
      description: 'Requested maximum number of students to be assigned each month',
    },
    {
      fillColor: Globals.colors.instructorAssignment.max,
      borderColor: Globals.colors.instructorAssignment.hardCap,
      text: 'hard cap',
      description: 'Once this maximum is hit, no additional students will be assigned',
    },
    {
      fillColor: Globals.colors.instructorAssignment.max,
      borderColor: Globals.colors.instructorAssignment.tmpCap,
      text: 'temp cap',
      description: 'Override max cap for this month--will be reset at the end of the billing period',
    },
  ];
  return (
    <div style={styles.legend}>
      {keys.map((key) => {
        const backgroundObject = {};
        if (key.borderColor) {
          backgroundObject.background = `repeating-linear-gradient(
            -45deg,
            ${key.borderColor},
            ${key.borderColor} 1px,
            ${key.fillColor} 1px,
            ${key.fillColor} 9px
          )`;
        }
        return (
          <Tooltip key={key.text} title={key.description}>
            <div style={styles.legendOptionWrapper}>
              <span
                style={{
                  ...styles.legendSwatch,
                  backgroundColor: key.fillColor,
                  borderColor: key.borderColor || key.fillColor,
                  ...backgroundObject,
                }}
              >
                {key.fillContent}
              </span>
              <span
                style={styles.legendText}
              >
                {key.text}
              </span>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

// @NOTE:
// make sure parent container have a defined height when using responsive component, otherwise height will be 0 and no chart will be rendered
const AssignmentGraph = (props) => {
  const instructorData = props.instructorAssignmentRules.map((rule) => {
    return {
      ...rule,
      min: rule.min_students,
      current: rule.current_students_in_pay_period,
      max: (props.currentPayPeriodSelected && rule.tmp_max_students_override) || rule.max_students,
      name: rule.is_full_time ? `${rule.instructor_name}*` : rule.instructor_name,
    };
  });

  return (
    <ResponsiveBar
      data={instructorData}
      keys={[
        'min',
        'current',
        'max',
      ]}
      indexBy='name'
      margin={{
        top: 40,
        right: 20,
        bottom: 60,
        left: 60,
      }}
      padding={0.3}
      colorBy={(node) => {
        switch (node.id) {
        case 'min': return Globals.colors.instructorAssignment.min;
        case 'current': return Globals.colors.accent;
        case 'max': return Globals.colors.instructorAssignment.max;
        default: return Globals.colors.light;
        }
      }}
      groupMode='grouped'
      borderWidth={2}
      defs={[
        {
          id: 'hard_cap_lines',
          type: 'patternLines',
          background: 'inherit',
          color: Globals.colors.instructorAssignment.hardCap,
          rotation: -45,
          lineWidth: 1,
          spacing: 8,
        },
        {
          id: 'temp_cap_lines',
          type: 'patternLines',
          background: 'inherit',
          color: Globals.colors.instructorAssignment.tmpCap,
          rotation: -45,
          lineWidth: 1,
          spacing: 8,
        },
      ]}
      fill={[
        {
          match: (node) => node.data.id === 'max' && node.data.data.has_hard_cap && !(props.currentPayPeriodSelected && node.data.data.tmp_max_students_override),
          id: 'hard_cap_lines', // @TODO: this only displays if biology expansionpanel is open
        },
        {
          match: (node) => node.data.id === 'max' && props.currentPayPeriodSelected && node.data.data.tmp_max_students_override,
          id: 'temp_cap_lines',
        },
      ]}
      borderColor={(node) => {
        // @TODO: add this to key
        if (node.data.id === 'max' && (props.currentPayPeriodSelected && node.data.data.tmp_max_students_override)) {
          return Globals.colors.instructorAssignment.tmpCap;
        } else if (node.data.id === 'max' && node.data.data.has_hard_cap) {
          return Globals.colors.instructorAssignment.hardCap;
        }
        return 'transparent';
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'INSTRUCTORS',
        legendPosition: 'middle',
        legendOffset: 40,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'ASSIGNMENTS',
        legendPosition: 'middle',
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={1000}
      labelTextColor={(node) => {
        // @TODO: currently conflicts with scss...remove labelSkipHeight (or set to 12) when fixed
        return node.data.id === 'current' ? '#000' : '#fff';
      }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      legends={[]}
    />
  );
};

AssignmentGraph.propTypes = {
  instructorAssignmentRules: PropTypes.object.isRequired,
  currentPayPeriodSelected: PropTypes.bool.isRequired,
};
