/*
 * Nathaniel Padgett
 * 2021-07-27
 * © Copyright 2019 NursingABC, Inc.  All Rights Reserved.
 */
import {
  FormControl,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import authStyles from '../authStyles';

const TextInput = (props) => {
  return (
    <FormControl style={authStyles.registerInputField} error={props.errorMessage}>
      <TextField
        id={props.name}
        name={props.name}
        label={props.label}
        onChange={props.handleInputChange}
        required={props.isRequired}
        error={!!props.errorMessage}
        {...props.otherProps}
      />
      <FormHelperText>{props.errorMessage}</FormHelperText>
    </FormControl>
  );
};

TextInput.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  errorMessage: PropTypes.string,
  otherProps: PropTypes.object,
};
TextInput.defaultProps = {
  otherProps: {},
};

export default TextInput;
