/*
 * Ryan O'Dowd
 * 2021-03-15
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
  },
};

export default styles;
