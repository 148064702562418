/*
 * Ryan O'Dowd
 * 2019-01-21
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 16,
    borderTopStyle: 'solid',
    borderTopWidth: '4',
    borderTopColor: Globals.colors.mediumGray,
    backgroundColor: Globals.colors.darkGray,
    color: Globals.colors.light,
  },
  innerLeft: {
    display: 'flex',
    textAlign: 'left',
  },
  footerParagraph: {
    marginRight: 16,
  },
  innerRight: {
    textAlign: 'right',
  },
};

export default styles;
