/*
 * Ryan O'Dowd
 * 2019-01-22
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  tableToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rejectionText: {
    width: 500,
  },
};

export default styles;
